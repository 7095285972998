import React from 'react';

import { useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';
import CentreModal from '../../modal/CentreModal';

const ConfirmAddAdministrator = ({
    title,
    subtitle,
    show,
    close,
    list,
    confirmAction,
    role,
    btnText,
    name,
    altLoading,
}) => {
    const { isLoading } = useSelector((state) => state.administrators);

    return (
        <>
            <CentreModal isOpen={show} onClose={() => close?.()}>
                <div className="bg-white rounded-[8px] p-[2.4rem] w-[50.8rem] max-h-[60rem]">
                    <FlexRowSpaceBetween>
                        <Text as="h3" size="1.9rem" weight="600">
                            {title || 'Add Administrator'}
                        </Text>
                        <CloseIcon onClick={close} />
                    </FlexRowSpaceBetween>
                    <Text align="start" size="1.6rem" color="#374151" top="1.6rem" lineHeight="2.4rem" bottom="3.2rem">
                        {subtitle || 'Are you sure you want to add this person(s) as'}
                        {Array.isArray(role) ? (
                            role?.map((item) => (
                                <Text align="left" weight="600">
                                    {item.role}
                                </Text>
                            ))
                        ) : (
                            <Text align="left" weight="600">
                                {role}
                            </Text>
                        )}
                    </Text>

                    <ul className="overflow-y-auto border-b pb-2">
                        {Array.isArray(list) ? (
                            <>
                                {list?.map((item) => (
                                    <Text key={item._id} align="left">{`${item?.fullName}`}</Text>
                                ))}
                            </>
                        ) : (
                            <Text align="left">{name}</Text>
                        )}
                    </ul>
                    <Text color="#6B7280" size="1.2rem" align="left">
                        {list?.length} name(s) selected
                    </Text>

                    <FlexRowEnd className="gap-x-[16px]">
                        <Button onClick={close} border="0.5px solid #D1D5DB">
                            Cancel
                        </Button>
                        <LoadingButton onClick={confirmAction} loading={isLoading || altLoading}>
                            {btnText || 'Add'}
                        </LoadingButton>
                    </FlexRowEnd>
                </div>
            </CentreModal>
        </>
    );
};

export default ConfirmAddAdministrator;
