import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { ReactComponent as Dropdown } from '../../assets/icons/dropdown.svg';
import { Button } from '../../components/buttons';
import NavTabs, { Tab } from '../../components/nav-tabs/NavTabs';
import { PageTitle } from '../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../containers/ScreenContainers';
import { getSavedAcademicStructure } from '../../redux/academic-structure/actions';
import { getAdmissionSessions } from '../../redux/admissions/actions';
import { storeUnit } from '../../utils/originUnit';

import Faculty from './tabs/Faculty';

const tabs = [{ label: 'Faculty' }];

const ExamsAndRecords = ({ unitName }) => {
    const dispatch = useDispatch();

    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    return (
        <div>
            <FlexRowSpaceBetween className="mb-6">
                <PageTitle>Exams & Records</PageTitle>

                <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                    Actions
                    <Dropdown />
                </Button>
            </FlexRowSpaceBetween>

            <NavTabs tabs={tabs}>
                <Tab.Panel>
                    <Faculty />
                </Tab.Panel>
            </NavTabs>
        </div>
    );
};

export default ExamsAndRecords;
