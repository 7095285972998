import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as PublishIcon } from '../../../assets/icons/circular-mark-sm-black.svg';
import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-blue.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import { Button } from '../../../components/buttons';
import { AllFilter } from '../../../components/filter-select';
import FilterPopup from '../../../components/filter-select/filter-popup';
import ListEmptyContent from '../../../components/list-empty';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import ConfirmActionDialogueWithInput from '../../../components/popups/confirm-action-with-input';
import { StatusContainer, getStatusStyles } from '../../../components/shared';
import { CenteredContainer, RelativeContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import usePersistedState from '../../../hooks/usePersistedState';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAdmissionSessions } from '../../../redux/admissions/actions';
import { deleteHandbook, getHandbook, publishHandbook } from '../../../redux/handbook/action';
import { capitalizeFirstLetter } from '../../../utils';
import { localStoreKeys } from '../../../utils/localStore';
import { storeUnit } from '../../../utils/originUnit';

const FilterTag = tw.div`
    flex
    px-[8px]
    py-[4px]
    h-[25px]
    min-w-max
    bg-[#EEF2FF]
    text-[#4F46E5]
    text-[12px]
    font-semibold
`;

const StyledTag = styled(FilterTag)`
    border-top-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-bottom-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-top-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
    border-bottom-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
`;

const STATUS_FILTER_OPTIONS = ['Published', 'Unpublished'];
const TYPE_FILTER_OPTIONS = ['Students', 'Academic', 'Non-Academic', 'All Staff'];
const FILTER_OPTIONS = ['Type', 'Title', 'Status', 'Date Created'];

const Handbook = ({ unitName }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    const { isLoading } = useSelector((state) => state.handbook);
    const { sessions, activeSession } = useSelector((state) => state.admission);

    const [handbooks, setHandbooks] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [openModal, setOpenModal] = useState(null);
    const [openDropdown, setOpenDropdown] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [toggleRefetch, setToggleRefetch] = useState(true);

    const [session, setSession] = usePersistedState(
        state?.session || activeSession || '',
        localStoreKeys.SelectedSession,
    );
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const [filterCategories, setFilterCategories] = useState([]);
    const [currentFilterCategory, setCurrentFilterCategory] = useState('');
    const [titleFilter, setTitleFilter] = useState('');
    const [dateCreatedFilter, setDateCreatedFilter] = useState('');
    const [selectTypeFilter, setSelectTypeFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');

    const fields = [
        {
            header: 'Title',
            accessorKey: 'handbookTitle',
            cell: ({ row }) => (
                <Link
                    state={{ session, handbook: row.original }}
                    to={row.original?._id}
                    style={{ color: '#2563EB' }}
                >{`${capitalizeFirstLetter(row.original?.title)}`}</Link>
            ),
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Type',
            accessorKey: 'selectType',
        },
        {
            header: 'Date Created',
            accessorKey: 'date',
            cell: ({ row }) => {
                const dateTimeString = row.original?.createdAt;
                const [datePart, timePart] = dateTimeString.split('T');
                const formattedDateTime = `${datePart} ${timePart.split('.')[0]}`;

                return <p>{formattedDateTime}</p>;
            },
        },
        {
            header: 'Status',
            accessorKey: 'status',
            cell: (props) => (
                <StatusContainer style={getStatusStyles(props.getValue()?.toLowerCase())}>
                    <span>{props.getValue()}</span>
                </StatusContainer>
            ),
        },
    ];

    const sessionItems = sessions?.map((item) => ({
        name: item?.admissionYear,
        click: () => {
            setSession(item?.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const titleList = handbooks?.map((item) => item?.title);

    const DateCreatedList = handbooks?.map(
        (item) => item?.createdAt,
        //  {
        //     const dateTimeString = item?.createdAt;
        //     const [datePart, timePart] = dateTimeString.split('T');
        //     const formattedDateTime = `${datePart} ${timePart.split('.')[0]}`;
        //     return formattedDateTime;
        // }
    );

    const generateFilterOptions = (data = [], setStateAction, closeAction, objKey) => {
        const filterOptions = data?.map((option) => {
            let item;

            if (typeof option === 'object') {
                item = option[objKey];
            } else {
                item = option;
            }

            return {
                name: item,
                click: () => {
                    setStateAction(item);
                    closeAction(null);
                },
            };
        });

        return filterOptions;
    };

    const handleReset = () => {
        setFilterCategories([]);
        setCurrentFilterCategory('');
        setStatusFilter('');
        setSearchValue('');
        setTitleFilter('');
        setSelectTypeFilter('');
        setDateCreatedFilter('');
    };

    const FILTER_POPUP_OPTIONS = {
        Title: generateFilterOptions(titleList, setTitleFilter, setOpenModal),
        Type: generateFilterOptions(TYPE_FILTER_OPTIONS, setSelectTypeFilter, setOpenModal),
        Status: generateFilterOptions(STATUS_FILTER_OPTIONS, setStatusFilter, setOpenModal),
        'Date Created': generateFilterOptions(DateCreatedList, setDateCreatedFilter, setOpenModal),
    };

    const FILTER_VALUES_LOOKUP = {
        Title: {
            value: titleFilter,
            stateAction: setTitleFilter,
        },
        Type: {
            value: selectTypeFilter,
            stateAction: setSelectTypeFilter,
        },

        Status: {
            value: statusFilter,
            stateAction: setStatusFilter,
        },
        'Date Created': {
            value: dateCreatedFilter,
            stateAction: setDateCreatedFilter,
        },
    };

    const handleSelectCategories = (category) => {
        const isSelected = filterCategories.includes(category);
        if (isSelected) return;
        setFilterCategories((prevState) => [...prevState, category]);
    };

    const handleRemoveFilterCategory = (category, stateAction) => {
        const newItems = filterCategories.filter((item) => item !== category);
        setFilterCategories(newItems);
        if (newItems.length === 0) setCurrentFilterCategory('');
        stateAction('');
    };

    const query = useMemo(() => {
        let queryParams = {};

        if (selectTypeFilter) {
            queryParams.selectType = selectTypeFilter;
        }

        if (titleFilter) {
            queryParams.title = titleFilter;
        }
        if (debouncedSearchValue) {
            queryParams.title = debouncedSearchValue;
        }

        if (statusFilter) {
            queryParams.status = statusFilter;
        }
        if (dateCreatedFilter) {
            queryParams.createdAt = dateCreatedFilter;
        }

        return queryParams;
    }, [selectTypeFilter, titleFilter, statusFilter, dateCreatedFilter, debouncedSearchValue]);

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const handleCloseModal = (modal) => {
        if (openModal === modal) {
            setOpenModal(null);
        } else {
            setOpenModal(modal);
        }
    };

    const publish = async () => {
        const res = await dispatch(publishHandbook(selectedItems?.[0]._id));
        if (res) {
            setSelectedItems([]);
            setOpenModal(null);
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const handleDelete = async () => {
        if (selectedItems) {
            const selectedIds = selectedItems?.map((item) => item._id);

            if (selectedIds && selectedIds.length > 0) {
                const payload = { ids: selectedIds };

                const res = await dispatch(deleteHandbook(payload));
                if (res) {
                    setSelectedItems([]);
                    setOpenModal('');
                    setToggleRefetch((prevState) => !prevState);
                }
            }
        }
    };

    useEffect(() => {
        if (!session) return;

        const getHandbooks = async () => {
            const data = await dispatch(getHandbook(session, query));
            if (data) return setHandbooks(data);
            setHandbooks([]);
        };

        getHandbooks();
    }, [dispatch, toggleRefetch, session, query]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <>
            <PageLayout
                pageTitle="Handbook"
                secondaryButtonText="Create Handbook"
                onSecondaryButtonClick={() => navigate('/registry/handbook/create-handbook')}
                data={handbooks || []}
                fields={fields}
                loading={isLoading}
                isActionable
                actionType="checkbox"
                onSelectedItemsChange={(items) => setSelectedItems(items)}
                onActionBtnClick={() => handleCloseModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <PublishIcon />,
                        name: 'Publish Handbook',
                        disabled:
                            !selectedItems.length ||
                            selectedItems.length > 1 ||
                            (selectedItems?.[0]?.status ?? '') === 'Published',

                        click: () => {
                            setOpenModal('publish');
                        },
                    },
                    {
                        icon: <EditIcon />,
                        name: 'Edit HandBook',
                        disabled: !selectedItems.length || selectedItems.length > 1,
                        click: () => {
                            navigate('/registry/handbook/edit-handbook', {
                                state: {
                                    id: selectedItems?.[0]?._id,
                                    handbook: selectedItems?.[0],
                                    session: selectedItems?.[0]?.session,
                                },
                            });
                        },
                    },
                    // {
                    //     icon: <ImportIcon />,
                    //     name: 'Import Handbook',
                    //     disabled: !selectedItem,
                    //     click: () => handleEdit(),
                    // },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete HandBook',
                        disabled: !selectedItems.length,
                        click: () => {
                            setOpenModal('delete');
                        },
                    },
                ]}
                onSessionFilterClick={() => handleOpenDropdown('sessions')}
                sessionPlaceHolder={session}
                openSessionFilter={openDropdown === 'sessions'}
                closeSessionFilter={() => setOpenDropdown(null)}
                sessionItems={sessionItems}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                searchValue={searchValue}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                showFilter
                width={'100%'}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Handbook " subtitle="Create Handbook">
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Button
                                    onClick={() => navigate('/registry/handbook/create-handbook')}
                                    bgColor="#10B981"
                                    color="#fff"
                                >
                                    <Circularplus className="mr-[1.15rem]" />
                                    Create HandBook
                                </Button>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
                filterItems={FILTER_OPTIONS.map((item) => ({
                    name: item,
                    click: () => {
                        handleSelectCategories(item);
                        setCurrentFilterCategory(item);
                        setOpenModal('options-popup-main');
                    },
                }))}
                handleFilterReset={handleReset}
                showClear={currentFilterCategory}
                openFilterPop={openModal === 'options-popup-main'}
                isFilterPopLoading={isLoading}
                closeFilterPop={() => setOpenModal(null)}
                filterPopItems={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                filterCategories={
                    <div>
                        {filterCategories?.length > 0 && (
                            <div className="flex flex-wrap items-center gap-4 p-[18px] border-t">
                                {filterCategories.map((category) => (
                                    <div accessorKey={category} className="flex gap-[1px]">
                                        <StyledTag withLeftRadius>{category}</StyledTag>

                                        {FILTER_VALUES_LOOKUP[category].value && (
                                            <StyledTag withRightRadius>
                                                {FILTER_VALUES_LOOKUP[category].value}
                                                <CloseIcon
                                                    className="ml-[4px] hover:cursor-pointer"
                                                    onClick={() =>
                                                        handleRemoveFilterCategory(
                                                            category,
                                                            FILTER_VALUES_LOOKUP[category].stateAction,
                                                        )
                                                    }
                                                />
                                            </StyledTag>
                                        )}
                                    </div>
                                ))}

                                <RelativeContainer>
                                    <AllFilter
                                        useSecondaryBtn
                                        showClear={currentFilterCategory}
                                        items={FILTER_OPTIONS.map((item) => ({
                                            name: item,
                                            click: () => {
                                                handleSelectCategories(item);
                                                setCurrentFilterCategory(item);
                                                setOpenModal('options-popup-sub');
                                            },
                                        }))}
                                        handleReset={handleReset}
                                    />
                                    <FilterPopup
                                        open={openModal === 'options-popup-sub'}
                                        isLoading={isLoading}
                                        close={() => setOpenModal(null)}
                                        items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                                    />
                                </RelativeContainer>
                            </div>
                        )}
                    </div>
                }
            />

            <ConfirmActionDialogueWithInput
                show={openModal === 'delete'}
                title={`Delete ${selectedItems?.map((item) => item?.title)}`}
                subtitle="Are you sure you want to delete this handbook?."
                subtitle2="To confirm, enter delete in the text box"
                close={() => setOpenModal(null)}
                setInputValue={setInputValue}
                value={inputValue}
                confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                btnDisabled={inputValue?.toLowerCase() !== 'delete'}
                placeholder="Input delete"
            />

            <ConfirmActionDialogue
                show={openModal === 'publish'}
                title="Publish Handbook?"
                subtitle="Are you sure you want to publish this handbook?."
                close={() => setOpenModal(null)}
                confirmAction={() => {
                    publish();
                    setOpenModal(null);
                }}
                btn2Text="Publish"
                bgColor="#6366F1"
            />
        </>
    );
};

export default Handbook;
