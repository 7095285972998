import { axiosInstance, currentAPI } from '../../config';
import handleApiError from '../handleApiError';

import { GET_ALL_ACADEMIC_STRUCTURE, LOADING, STOP_LOADING, SAVED_ACADEMIC_STRUCTURE } from './slice';

export const getAllAvailableStructure = () => async (dispatch, getState) => {
    dispatch(LOADING());
    dispatch(GET_ALL_ACADEMIC_STRUCTURE([]));
    try {
        const response = await axiosInstance.get(`${currentAPI}structure/:campusId/:unitId/get_all_structure`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            dispatch(GET_ALL_ACADEMIC_STRUCTURE(data));
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const setAcademicStructure = (payload) => async (dispatch, getState) => {
    // dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}structure/:campusId/:unitId/set_structure`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSavedAcademicStructure = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}structure/:campusId/:unitId/valid_structure`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            dispatch(SAVED_ACADEMIC_STRUCTURE(data));
            return data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteSecondDegree = (structureId, secondDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(
            `${currentAPI}structure/:campusId/:unitId/delete_second_degree/${structureId}/${secondDegreeId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteThirdDegree = (structureId, thirdDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(
            `${currentAPI}structure/:campusId/:unitId/delete_last_degree/${structureId}/${thirdDegreeId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
