/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { formatDistanceToNow } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import UploadFile from '../../../../components/upload-file';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredRow,
    FlexRowSpaceBetween,
    RelativeContainer,
    ItemsPerPageCont,
} from '../../../../containers/ScreenContainers';
import { SAVE_CURRENT_DRAFT } from '../../../../redux/communications/slice';
import { getOfficeDraft } from '../../../../redux/staff-arena/actions';
import { addLabelsToData } from '../../../../utils';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Subject',
        key: 'subject',
    },
    {
        label: 'Message',
        key: 'message',
    },
    {
        label: 'Type',
        key: 'status',
    },
    {
        label: 'Time Stamp',
        key: 'time',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;
const StatusContainer = styled.div`
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
    width: fit-content;
`;

const Drafts = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const { isLoading } = useSelector((store) => store.staffArena);
    const [allDraft, setAllDraft] = useState({});
    const combinedArray = addLabelsToData(allDraft, 'Staff-Arena');
    const totalPages = Math.ceil(combinedArray?.length / itemsPerPage);
    const sortedData = combinedArray?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const dispatch = useDispatch();

    const AllData = sortedData?.map((item) => {
        return {
            id: item._id,
            subject: item.title,
            message: item.content.length > 50 ? item.content.slice(0, 50) + '...' : item.content,
            label: item.label,
            time: formatDistanceToNow(new Date(item.createdAt), { addSuffix: true }),
            distributionListName: item?.distributionListName,
        };
    });

    useEffect(() => {
        const getDraft = async () => {
            const res = await dispatch(getOfficeDraft());
            setAllDraft(res);
        };

        getDraft();
    }, [dispatch]);

    const handleClick = (item) => {
        if (item.label === 'Letter') {
            navigate('/staff-arena/office/letter/draft');
        } else if (item.status === 'Memo') {
            navigate('/staff-arena/office/memo/draft');
        } else if (item.label === 'Notice') {
            navigate('/staff-arena/office/notice/draft');
        }
    };

    const ComposeList = [
        {
            name: 'Letters',
            click: () => {
                navigate('/staff-arena/office/letter');
                setDropdownOpen(false);
            },
        },

        {
            name: ' Memo ',
            click: () => {
                navigate('/staff-arena/office/memo');
                setDropdownOpen(false);
            },
        },
        {
            name: 'Notices ',
            click: () => {
                // navigate('/staff-arena/office/notice');
                setDropdownOpen(false);
            },
        },
    ];

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Draft</PageTitle>

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Compose
                        <Dropdown />
                    </Button>
                    <ActionsPopup open={dropdownOpen} close={() => setDropdownOpen(false)} items={ComposeList} />
                </RelativeContainer>
            </FlexRowSpaceBetween>

            <div className="mt-[3.2rem] rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>
                {/* <Table
                    items={AllData || []}
                    fields={fields}
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    onRowClick={(item) => {
                        dispatch(SAVE_CURRENT_DRAFT(item));
                        handleClick(item);
                    }}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),

                        subject: (item) => <td style={{ fontWeight: '600' }}>{item.subject}</td>,
                        status: (item) => (
                            <td>
                                {item.label === 'Memo' ? (
                                    <StatusContainer bgColor="#FDF2F8">
                                        <Text color="#EB4898" size="1.2rem">
                                            {item.label}
                                        </Text>
                                    </StatusContainer>
                                ) : item.label === 'Notice' ? (
                                    <StatusContainer bgColor="#ECFDF5">
                                        <Text color="#10B981" size="1.2rem">
                                            {item.label}
                                        </Text>
                                    </StatusContainer>
                                ) : item.label === 'Letter' ? (
                                    <StatusContainer bgColor="#EFF6FF">
                                        <Text color="#3B82F6" size="1.2rem">
                                            {item.label}
                                        </Text>
                                    </StatusContainer>
                                ) : (
                                    <StatusContainer bgColor="#F5F3FF">
                                        <Text color="#8B5CF6" size="1.2rem">
                                            {item.label}
                                        </Text>
                                    </StatusContainer>
                                )}
                            </td>
                        ),
                    }}
                />
                 */}
            </div>

            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </div>
    );
};

export default Drafts;
