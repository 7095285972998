// type Props = {
//     query: string,
//     data: Data[],
//     criteria: string[],
// };

const useSearch = (query = '', data = [], criteria) => {
    let result;
    if (query.length > 0) {
        result = data?.filter((x) =>
            criteria?.some((criterion) => x[criterion]?.toString().toLowerCase()?.includes(query.toLowerCase())),
        );
    } else {
        result = data;
    }
    return result;
};

export default useSearch;
