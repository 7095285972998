import React from 'react';

import { ReactComponent as Closeicon } from '../../../assets/images/close.svg';
import CentreModal from '../../modal/CentreModal';

const CardPopup = ({ show, close, children, noClose }) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white w-[50rem] rounded-[16px] px-[3.1rem] pt-[6rem] pb-[3.6rem]">
                {!noClose && (
                    <Closeicon className="absolute top-[2.9rem] right-[3.73rem] cursor-pointer" onClick={close} />
                )}
                <div className="">{children}</div>
            </div>
        </CentreModal>
    );
};

export default CardPopup;
