import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CustomTable from '../../../../../../components/CustomTable';
import PageLayout from '../../../../../../layout/page-layout/PageLayout';
import { getStaffSalaryChangeLog } from '../../../../../../redux/bursary/actions';

const fields = [
    {
        headerName: 'Salary Amount',
        field: 'salaryAmount',
        cellRenderer: ({ data }) => <p>{data?.newValue?.salaryAmount}</p>,
    },
    {
        headerName: 'Last Updated At',
        field: 'updatedAt',
        cellRenderer: ({ data }) => (
            <p>
                {' '}
                {data?.newValue?.updatedAt
                    ? `${new Date(data?.newValue?.updatedAt).toLocaleDateString()}, ${new Date(
                          data?.newValue?.updatedAt,
                      ).toLocaleTimeString()}`
                    : ''}
            </p>
        ),
    },
    {
        headerName: 'Amount Type',
        field: 'salaryType',
        cellRenderer: ({ data }) => <p>{data?.newValue?.salaryType}</p>,
    },
    {
        headerName: 'Currency',
        field: 'currency',
        cellRenderer: ({ data }) => <p>{data?.newValue?.currency}</p>,
    },
];

const SalaryChangeLog = ({ searchValue, setSearchValue, isLoading, refetch, staffType}) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [salaryHistory, setSalaryHistory] = useState([]);

    const fetchSalaryHistory = async () => {
        const query = {
            staffType: staffType,
    ...(staffType === "platform" ? { staffId: id } : { manualStaffId: id }),
        };
        const res = await dispatch(getStaffSalaryChangeLog(query));
        setSalaryHistory(res);
    };

    useEffect(() => {
        fetchSalaryHistory();
        //eslint-disable-next-line
    }, [refetch]);

    return (
        <div className="mt-3">
            <PageLayout
                noMargin
                // fields={fields}
                // data={salaryHistory || []}
                customTable={<CustomTable colDefs={fields} data={salaryHistory || []} />}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                // loading={isLoading}
            />
        </div>
    );
};

export default SalaryChangeLog;
