import axios from 'axios';
import toast from 'react-hot-toast';

import { parseJSON } from '../utils/useJSON';

const ERROR_MESSAGES = {
    401: 'Session expired!',
    500: 'Internal server error',
};

const showErrorToast = (status, defaultMessage, customMessage) => {
    const toastId = `${status}-error-toast-Id`;
    toast.error(customMessage || defaultMessage, { id: toastId });
};

let triggerSessionTimeoutModalCallback = () => {};

export const setUnauthorizedHandler = (callback) => {
    triggerSessionTimeoutModalCallback = callback;
};

const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
       'ngrok-skip-browser-warning':1,
    },
};

const axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(
    (config) => {
        const token = parseJSON('token', '');
        const originUnit = parseJSON('originUnit', '');
        const campusId = sessionStorage.getItem('campusId');
        const unitId = sessionStorage.getItem('unitId');

        if (config && config.headers) {
            config.url = config.url.replace(':campusId', campusId);
            config.url = config.url.replace(':unitId', unitId);

            config.headers.Authorization = `Bearer ${token}`;
            config.headers.originunit = originUnit;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        // const originalRequest = error.config;
        // if (error.response && error.response.status === 401 && !originalRequest._retry) {
        //     originalRequest._retry = true;
        //     return axios(originalRequest);
        // }
        if (!error.response) return Promise.reject(error); // No response means a network error

        const { status, data } = error.response;
        const token = parseJSON('token', '');

        if (status === 401) {
            if (token) {
                // Only show modal if user is within the app
                triggerSessionTimeoutModalCallback();
            } else {
                showErrorToast(401, ERROR_MESSAGES[401], data?.message);
            }
            return Promise.reject(error);
        }

        if (status === 500) {
            showErrorToast(500, ERROR_MESSAGES[500], data.message);
            return Promise.reject(error);
        }

        return Promise.reject(error);
    },
);

export default axiosInstance;
