import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-blue.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import { getSearchStaff, shareFile, shareFolder } from '../../../redux/staff-arena/actions';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../../forms/sharedStyles';
import SearchInput from '../../inputs/search-input';
import CentreModal from '../../modal/CentreModal';

const Share = ({ show, close, setOpen, fileName, id, type }) => {
    const { searchStaff, isLoading } = useSelector((state) => state.staffArena);
    const [staffList, setStaffList] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState([]);
    const [dropdown, setDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const debouncedSearchValue = useDebounce(searchValue, 700);

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchValue(query);
    };

    const handleAddStaff = (staff, setFieldValue) => {
        if (!selectedStaff.some((s) => s?._id === staff?._id)) {
            const newSelectedStaff = [...selectedStaff, staff];
            setSelectedStaff(newSelectedStaff);
            setFieldValue('selectedStaff', newSelectedStaff);
        }
        setSearchValue('');
        setStaffList([]);
    };

    const handleRemoveStaff = (staffId, setFieldValue) => {
        const updatedSelectedStaff = selectedStaff.filter((s) => s?._id !== staffId);
        setSelectedStaff(updatedSelectedStaff);
        setFieldValue('selectedStaff', updatedSelectedStaff);
    };

    useEffect(() => {
        if (!debouncedSearchValue) {
            setStaffList([]);
            return;
        }

        dispatch(getSearchStaff(debouncedSearchValue));
        setDropdown(true);
    }, [debouncedSearchValue, dispatch]);

    useEffect(() => {
        if (searchStaff?.data?.staffs) {
            const filteredStaff = searchStaff?.data?.staffs?.filter(
                (staff) =>
                    staff?.surname.toLowerCase().includes(debouncedSearchValue) ||
                    staff?.staffEmail.includes(debouncedSearchValue),
            );
            setStaffList(filteredStaff);
        }
    }, [searchStaff, debouncedSearchValue]);

    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[59.2rem]">
                <FlexCentredRow className=" pb-[1rem]">
                    <Text as="h3" size="1.9rem" weight="600">
                        Share '{fileName}'
                    </Text>
                </FlexCentredRow>

                <Formik
                    initialValues={{
                        search: [],
                    }}
                    onSubmit={async (values, actions) => {
                        const payload = {
                            search: values?.selectedStaff?.map((staff) => staff?.staffEmail),
                        };

                        if (type === 'file') {
                            const res = await dispatch(shareFile(id, payload));
                            if (res) {
                                setOpen();
                            }
                        } else if (type === 'folder') {
                            const res = await dispatch(shareFolder(id, payload));
                            if (res) {
                                setOpen();
                            }
                        }
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className="mb-[3rem] relative">
                                <Text className="mb-[2rem]" weight="500" align="left" size="1.4rem">
                                    Add People
                                </Text>
                                <SearchInput
                                    width="45rem"
                                    onKeyDown={setDropdown(true)}
                                    onClose={() => setSearchValue('')}
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                    placeholder="Input name"
                                    className="border mt-[1rem] p-2 rounded"
                                />
                                {staffList?.length > 0 && dropdown && (
                                    <div className="absolute w-[45rem]  border mt-2 bg-white rounded shadow-lg">
                                        {staffList?.map((staff) => (
                                            <FlexRowSpaceBetween
                                                key={staff?._id}
                                                onClick={() => handleAddStaff(staff, setFieldValue)}
                                                className="hover:bg-gray-200 mx-[1rem]"
                                            >
                                                <FlexCentredRow className="gap-[1rem]">
                                                    {staff?.profile?.url ? (
                                                        <img
                                                            alt="profile"
                                                            src={staff?.profile?.url}
                                                            className="rounded-full w-[3rem] h-[3rem]"
                                                        />
                                                    ) : (
                                                        <Avatar
                                                            name={`${staff?.firstName} ${staff?.surname}`}
                                                            size="30"
                                                            textSizeRatio={1.75}
                                                            className="rounded-full"
                                                        />
                                                    )}
                                                    <FlexCentredCol className="gap-[0.1rem]">
                                                        <Text
                                                            align="left"
                                                            weight="500"
                                                            size="1.4rem"
                                                            lineHeight="2.4rem"
                                                        >
                                                            {staff?.firstName} {staff?.surname}
                                                        </Text>
                                                        <Text
                                                            align="left"
                                                            color="#6B7280"
                                                            weight="400"
                                                            size="1.2rem"
                                                            lineHeight="2.4rem"
                                                        >
                                                            {staff?.staffEmail}
                                                        </Text>
                                                    </FlexCentredCol>
                                                </FlexCentredRow>
                                                <Text align="left" weight="400" size="1.2rem" lineHeight="2.4rem">
                                                    {staff?.staffId}
                                                </Text>
                                            </FlexRowSpaceBetween>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {selectedStaff?.map((staff) => (
                                <div
                                    key={staff?._id}
                                    className="inline-flex items-center mr-2 mb-2 px-2 py-1 text-sm font-medium gap-[1.5rem] text-indigo-700 bg-indigo-100 rounded-md"
                                >
                                    <Text color="">
                                        {staff?.firstName} {staff?.surname}
                                    </Text>
                                    <CloseIcon onClick={() => handleRemoveStaff(staff?._id, setFieldValue)} />
                                </div>
                            ))}
                            <Text align="left" size="1.4rem" lineHeight="2.4rem" weight="500" top="1.6rem">
                                People with access
                            </Text>

                            {selectedStaff?.map((staff) => (
                                <FlexCentredRow className="gap-[1rem]">
                                    {staff?.profilePicture ? (
                                        <img
                                            alt="profile"
                                            src={staff?.profilePicture}
                                            className="rounded-full w-[5rem] h-[5rem]"
                                        />
                                    ) : (
                                        <Avatar
                                            name={`${staff?.surname}`}
                                            size="60"
                                            textSizeRatio={1.75}
                                            className="rounded-full"
                                        />
                                    )}
                                    <Text align="left" weight="500" size="1.4rem" lineHeight="2.4rem">
                                        {staff?.firstName} {staff?.surname}
                                    </Text>
                                </FlexCentredRow>
                            ))}
                            <FlexRowEnd className="gap-[1.5rem] mt-[1rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} type="submit">
                                    Share
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
            </div>
        </CentreModal>
    );
};

export default Share;
