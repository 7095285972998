import React, { useRef, useState, useEffect } from 'react';

const DatePicker = ({ onDateChange, initialStartDate = '', initialEndDate = '' }) => {
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [showCalendar, setShowCalendar] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectingStart, setSelectingStart] = useState(true);

    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowCalendar(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleSelect = (day) => {
        const selectedDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
        if (selectingStart) {
            setStartDate(formatDate(selectedDate));
            setEndDate('');
            setSelectingStart(false);
        } else {
            if (selectedDate >= new Date(startDate)) {
                setEndDate(formatDate(selectedDate));
                setShowCalendar(false);
                setSelectingStart(true);
                if (onDateChange) onDateChange(startDate, formatDate(selectedDate));
            } else {
                setStartDate(formatDate(selectedDate));
                setEndDate('');
            }
        }
    };

    const prevMonth = () => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
    const nextMonth = () => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));

    const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
    const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

    const renderCalendar = () => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();
        const monthName = currentMonth.toLocaleString('default', { month: 'long' });
        const daysInMonth = getDaysInMonth(year, month);
        const firstDayOfMonth = getFirstDayOfMonth(year, month);

        const days = [];
        for (let i = 0; i < firstDayOfMonth; i++) {
            days.push(<div key={`empty-${i}`} className="cursor-default"></div>);
        }
        for (let day = 1; day <= daysInMonth; day++) {
            const dateStr = formatDate(new Date(year, month, day));
            const isSelected = dateStr === startDate || dateStr === endDate;
            const isInRange = startDate && endDate && dateStr > startDate && dateStr < endDate;

            days.push(
                <div
                    key={day}
                    className={`text-center w-[36px] h-[30px] text-[12px] font-bold flex items-center justify-center cursor-pointer hover:bg-gray-200 
                    ${isSelected ? 'bg-blue-500 text-white rounded' : isInRange ? 'bg-gray-300' : ''}`}
                    onClick={() => handleSelect(day)}
                >
                    {day}
                </div>,
            );
        }

        return (
            <div className="absolute w-[268px] p-4 bg-white border border-gray-300 rounded-md shadow-lg z-50">
                <div className="flex justify-between">
                    <button className="text-gray-700" onClick={prevMonth}>
                        &lt;
                    </button>
                    <div className="font-bold text-[12px]">
                        {monthName} {year}
                    </div>
                    <button className="text-gray-700" onClick={nextMonth}>
                        &gt;
                    </button>
                </div>
                <div className="grid grid-cols-7 gap-2 text-center mt-2">
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                        <div key={day} className="text-gray-600 text-[12px] font-semibold">
                            {day}
                        </div>
                    ))}
                    {days}
                </div>
            </div>
        );
    };

    return (
        <div ref={ref} className="relative w-[268px]">
            <input
                type="text"
                className="w-full p-2 text-[12px] border border-gray-300 rounded-lg cursor-pointer"
                value={startDate ? `${startDate} - ${endDate || 'Select end date'}` : 'Click to select a date range...'}
                onClick={() => setShowCalendar(!showCalendar)}
                readOnly
            />
            {showCalendar && renderCalendar()}
        </div>
    );
};

export default DatePicker;
