import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../assets/icons/arrow-down2.svg';
import { ActionsPopup, Checkbox, SearchInput } from '../../../../components';
import { Button } from '../../../../components/buttons';
import { GoBack } from '../../../../components/go-back';
import { PageTitle } from '../../../../containers/MesssageContainers';
import {
    FilterContainer,
    FlexCentredRow,
    FlexDiv,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import usePersistedState from '../../../../hooks/usePersistedState';
import { getProgramIncompleteResults } from '../../../../redux/exam-magt/actions';
import { localStoreKeys } from '../../../../utils/localStore';

const Th = tw.th`
    overflow-auto
    border
    p-2
`;

const Td = tw.td`
    border
    border-gray-100
`;

const IncompleteResult = () => {
    const dispatch = useDispatch();
    const { level } = useParams();

    const { sessions, activeSession, activeSemester } = useSelector((state) => state.admission);
    const { currentProgramme } = useSelector((state) => state.programmes);

    const { _id: programId } = currentProgramme || {};

    const [session, setSession] = usePersistedState(activeSession || '', localStoreKeys.SelectedSession);
    const [semester, setSemester] = usePersistedState(
        activeSemester?.split(' ')?.[0] || 'First',
        localStoreKeys.ShortSemester,
    );
    const [searchValue, setSearchValue] = useState('');
    const [resultData, setResultData] = useState([]);
    const [openModal, setOpenModal] = useState(null);

    const handleOpenDropdown = (dropdown) => {
        if (openModal === dropdown) {
            setOpenModal(null);
        } else {
            setOpenModal(dropdown);
        }
    };

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenModal(null);
        },
    }));

    const actionItemSemester = ['First', 'Second'].map((item) => ({
        name: item,
        click: () => {
            setSemester(item);
            setOpenModal(null);
        },
    }));

    const getData = async () => {
        const data = await dispatch(
            getProgramIncompleteResults({
                session,
                programId,
                level,
                semester: semester.toLowerCase(),
            }),
        );
        if (data) {
            setResultData(data);
        } else {
            setResultData([]);
        }
    };

    useEffect(() => {
        if (!session || !semester) return;
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, programId, session, semester]);

    return (
        <>
            <FlexRowSpaceBetween>
                <GoBack title="Incomplete Result" subTitle={`${level} level`} />
            </FlexRowSpaceBetween>

            <FlexDiv className="mt-[3.2rem] mb-[3.2rem]">
                <PageTitle>Incomplete Result</PageTitle>
            </FlexDiv>

            <div className="mt-[2.4rem] min-h-[50vh] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem] w-full overflow-x-auto">
                <FilterContainer>
                    <FlexCentredRow className="mr-[2.065rem] gap-5">
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('sessions')}
                            >
                                {session || 'Session'}
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openModal === 'sessions'}
                                close={() => setOpenModal(null)}
                                items={actionItemSessions}
                            />
                        </RelativeContainer>
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('semester')}
                            >
                                {`${semester} Semester` || 'Semester'}
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openModal === 'semester'}
                                close={() => setOpenModal(null)}
                                items={actionItemSemester}
                            />
                        </RelativeContainer>
                        <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>

                <div className=" text-center text-[#4B5563]">
                    <table className="min-w-full border border-gray-400 overflow-auto">
                        {/* Table Headers */}
                        <thead className="overflow-auto">
                            {/* Header Row 1 */}
                            <tr>
                                <Th colSpan="3"></Th>
                                <Th colSpan="4">Previous CGPA</Th>
                                <Th colSpan="4">Current CGPA</Th>
                                <Th colSpan="4"></Th>
                            </tr>

                            {/* Header Row 2*/}

                            <tr>
                                <Th></Th>
                                <Th>Matric No.</Th>
                                <Th>Full Name</Th>
                                <Th>Total Unit Registered</Th>
                                <Th>Total Unit Passed</Th>
                                <Th>Total Weight Passed</Th>
                                <Th>Cumulative Grade Point Average</Th>
                                <Th>Total Unit Registered</Th>
                                <Th>Total Unit Passed</Th>
                                <Th>Total Weight Passed</Th>
                                <Th>Cumulative Grade Point Average</Th>
                                <Th>Remark</Th>
                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody className="">
                            {resultData?.map((student) => {
                                const resultArrayLength = student?.results?.length;
                                const prevResult = student?.results?.[resultArrayLength - 2];
                                const currentResult = student?.results?.[resultArrayLength - 1];

                                return (
                                    <tr key={student.studentId} className="">
                                        <Td className="">
                                            <Checkbox />{' '}
                                        </Td>
                                        <Td className="min-w-max">{student?.matricNumber}</Td>
                                        <Td className="min-w-[150px]">{student?.name}</Td>

                                        <Td>{prevResult?.totalUnitsRegistered}</Td>
                                        <Td>{prevResult?.totalUnitsPassed}</Td>
                                        <Td>{prevResult?.totalWeightPassed}</Td>
                                        <Td>{prevResult?.cumulativeGradePointAverage}</Td>
                                        <Td>{currentResult?.totalUnitsRegistered}</Td>
                                        <Td>{currentResult?.totalUnitsPassed}</Td>
                                        <Td>{currentResult?.totalWeightPassed}</Td>
                                        <Td>{currentResult?.cumulativeGradePointAverage}</Td>

                                        <Td className="flex gap-2 gap-pt-2 items-center border-0">
                                            {currentResult?.remark}
                                        </Td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default IncompleteResult;
