import React from 'react';

import { SelectInput } from '@belrald_hq/belrald-ui';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-blue2.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';
import CentreModal from '../../modal/CentreModal';

const ValidationSchema = Yup.object().shape({
    sourceSession: Yup.string().required('Source session is required'),
    destinationSessions: Yup.array()
        .of(Yup.string().required('Each destination session must be a valid string'))
        .min(1, 'At least one destination session is required'),
});

const ImportAcademicSettings = ({ show, close, sessions, title, onSubmitAction }) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[59.2rem]">
                <FlexRowEnd>
                    <CloseIcon onClick={close} className="cursor-pointer" />
                </FlexRowEnd>

                <FlexCentredRow className="gap-[1rem] mb-[3rem]">
                    <DownloadIcon />
                    <Text align="left" size="1.6rem" weight="600">
                        {title}
                    </Text>
                </FlexCentredRow>

                <Formik
                    initialValues={{
                        sourceSession: '',
                        destinationSessions: [],
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values) => {
                        onSubmitAction(values);
                    }}
                >
                    {({ errors, values, setFieldValue, handleChange, isSubmitting }) => (
                        <Form>
                            <FlexCentredCol className="gap-[3.2rem]">
                                <SelectInput
                                    name="sourceSession"
                                    objProp="admissionYear"
                                    label="Source Session"
                                    placeholder="Choose Session"
                                    data={sessions}
                                    onChange={(selected) => {
                                        setFieldValue('sourceSession', selected?.[0]?.admissionYear);
                                    }}
                                    error={errors.sourceSession}
                                />
                                <SelectInput
                                    name="destinationSessions"
                                    objProp="admissionYear"
                                    multiSelect
                                    label="Destination Session"
                                    placeholder="Choose Session"
                                    data={sessions?.filter((item) => item?.admissionYear !== values?.sourceSession)}
                                    onChange={(selected) => {
                                        setFieldValue(
                                            'destinationSessions',
                                            selected?.map((item) => item?.admissionYear),
                                        );
                                    }}
                                    error={errors.destinationSessions}
                                />
                            </FlexCentredCol>
                            <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                                <Button
                                    type="button"
                                    onClick={close}
                                    disabled={isSubmitting}
                                    border="0.5px solid #D1D5DB"
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    loading={isSubmitting}
                                    type="submit"
                                    color="#fff"
                                    disabled={isSubmitting}
                                >
                                    Confirm
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
            </div>
        </CentreModal>
    );
};

export default ImportAcademicSettings;
