import React, { useEffect, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { ReactComponent as CircularPlus } from '../../../../assets/icons/circularplus-black.svg';
import { ReactComponent as Plus } from '../../../../assets/icons/plus.svg';
import { ReactComponent as Upload } from '../../../../assets/icons/upload3.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import { GoBack } from '../../../../components/go-back';
import NavTabs, { Tab } from '../../../../components/nav-tabs/NavTabs';
import ConfirmActionDialogueWithInput from '../../../../components/popups/confirm-action-with-input';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, RelativeContainer } from '../../../../containers/ScreenContainers';
import { createFolder, getNestedFile, getNestedFolder, uploadFile } from '../../../../redux/staff-arena/actions';
import { capitalizeFirstLetter } from '../../../../utils';

import InnerFiles from './tabs/files';
import InnerFolders from './tabs/folders';

const tabs = [{ label: 'Files' }, { label: 'Folders' }];

const NestedFolders = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const location = useLocation();
    const { previousFolders, folderName } = location.state;

    const { isLoading, folderDetails, fileDetails } = useSelector((state) => state.staffArena);

    const [inputValue, setInputValue] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showCreateFolder, setShowCreateFolder] = useState(false);
    const [toggleRefetch, setToggleRefetch] = useState(false);
    const [currentFolderId, setCurrentFolderId] = useState(id);

    useEffect(() => {
        dispatch(getNestedFolder(currentFolderId));
    }, [dispatch, toggleRefetch, currentFolderId]);

    useEffect(() => {
        dispatch(getNestedFile(currentFolderId));
    }, [dispatch, toggleRefetch, currentFolderId]);

    const handleCreateFolder = async () => {
        const payload = {
            folderName: inputValue,
        };
        const res = await dispatch(createFolder(payload));
        if (res) {
            setDropdownOpen(false);
            setInputValue('');
            toast.success('Folder created successfully!');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const handleUploadClick = () => {
        const allowedFileExtensions = [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/pdf',
            'image/jpeg',
            'image/png',
            'image/jpg',
        ];
        const fileInput = document.createElement('input');

        fileInput.type = 'file';
        fileInput.accept = '.doc,.docx,.xls,.xlsx,.pdf,.jpeg,.png,.jpg';
        fileInput.style.display = 'none';

        fileInput.onchange = async (e) => {
            const selectedFile = e.target.files[0];

            if (selectedFile) {
                if (allowedFileExtensions.includes(selectedFile?.type)) {
                    const formData = new FormData();
                    formData.append('content', selectedFile);

                    try {
                        const res = await dispatch(uploadFile(formData));
                        if (res) {
                            setDropdownOpen(false);
                            toast.success('File uploaded successfully!');
                            setToggleRefetch((prevState) => !prevState);
                        } else {
                            toast.error('Failed to upload file.');
                        }
                    } catch (error) {
                        console.error('File upload error:', error);
                        toast.error('Failed to upload file.');
                    }
                } else {
                    toast.error('Invalid file type. Please upload a valid file.');
                }
            }
        };

        document.body.appendChild(fileInput);
        fileInput.click();
        document.body.removeChild(fileInput);
    };

    return (
        <div>
            <div className="mb-[2.4rem]">
                <GoBack title="Documents" subTitle={capitalizeFirstLetter(previousFolders)} />
            </div>

            <FlexRowSpaceBetween className="flex items-center justify-between">
                <Text size="2.3rem" weight="600" align="left">
                    {capitalizeFirstLetter(folderName)}
                </Text>

                <RelativeContainer>
                    <Button bgColor={'#fff'} color={'#1f2937'} onClick={() => setDropdownOpen(!dropdownOpen)}>
                        New
                        <Plus />
                    </Button>
                    <ActionsPopup
                        open={dropdownOpen}
                        close={() => setDropdownOpen(null)}
                        items={[
                            {
                                icon: <CircularPlus />,
                                name: 'Create Folder',
                                click: () => {
                                    setShowCreateFolder(true);
                                    setDropdownOpen('create folder');
                                },
                            },
                            {
                                icon: <Upload />,
                                name: 'Upload File',
                                click: () => {
                                    handleUploadClick();
                                    setDropdownOpen(false);
                                },
                            },
                        ]}
                    />
                </RelativeContainer>
            </FlexRowSpaceBetween>

            <div className="pt-[2.4rem]">
                <NavTabs tabs={tabs} className="flex items-center gap-[2.4rem]">
                    <Tab.Panel>
                        <InnerFiles
                            files={fileDetails}
                            toggleRefetch={toggleRefetch}
                            setToggleRefetch={setToggleRefetch}
                        />
                    </Tab.Panel>
                    <Tab.Panel>
                        <InnerFolders
                            folders={folderDetails}
                            setCurrentFolderId={setCurrentFolderId}
                            toggleRefetch={toggleRefetch}
                            setToggleRefetch={setToggleRefetch}
                        />
                    </Tab.Panel>
                </NavTabs>
            </div>

            <ConfirmActionDialogueWithInput
                show={showCreateFolder}
                borderLine={false}
                svgIcon={<CircularPlus />}
                title="New Folder"
                isLoading={isLoading}
                subtitle="Name"
                setInputValue={setInputValue}
                value={inputValue}
                close={() => setShowCreateFolder(false)}
                confirmAction={() => {
                    handleCreateFolder();
                    setShowCreateFolder(false);
                }}
                btn2Text="Create"
                placeholder="Input Name"
                bgColor="#6366F1"
            />
        </div>
    );
};

export default NestedFolders;
