import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';



import {ReactComponent as Plus} from '../../../assets/icons/circularplus-blue.svg'
import { Text } from '../../../containers/MesssageContainers';
import {   FlexCentredCol, FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { addPayrollSchedule, getAllActivePaymentGateways, getManualStaffs, getPaymentGroups, getPlatformStaffs } from '../../../redux/bursary/actions';
import {  LoadingButton } from '../../buttons';
import { GoBack } from '../../go-back';
import DatePicker from '../../inputs/date-picker';
import StepDropdown from '../../inputs/nested-multi-select';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input';
import TimeInput from '../../inputs/time-input';
import { CancelButton } from '../sharedStyles';



const Container = tw.div`
    lg:p-[3rem]
    bg-white
    rounded-lg
    p-[1.6rem]
    max-w-[79.3rem]
    mt-[3.6rem]
`;

const staffTypes = ['Platform', 'Manual'];
const payrollTypes = ['group', 'individual'];
const disbursementModes = ['automatic', 'manual' ];


const CreatePayrollSchedule = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [paymentGroups, setPaymentGroups] = useState()

    const { isLoading,   platformStaffs, manualStaffs } = useSelector((store) => store.bursary);
    const { data: allpaymentGroups } = paymentGroups || {};

    const groupOptions = allpaymentGroups?.map(group => ({
        value: group?._id, 
        label: group?.groupName,
        groupType: group?.groupType,
    })) || [];

    

    const [selectedGroupType, setSelectedGroupType] = useState('');
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);
    const [selectedSubGroupIds, setSelectedSubGroupIds] = useState([]);
    const [selectedStaffType, setSelectedStaffType] = useState('');
    const [activePaymentGateways, setActivePaymentGateways] = useState([]);
    
  useEffect(() => {
        const getActiveGateways = async () => {
            const data = await dispatch(getAllActivePaymentGateways());
            setActivePaymentGateways(data);
        };

        getActiveGateways();
    }, [dispatch]);
    useEffect(() => {
            dispatch(getManualStaffs());
            dispatch(getPlatformStaffs());
        }, [dispatch]);

        const platformStaffOptions = platformStaffs?.map((staff) => ({
            value: staff?._id,
            label: `${staff?.staffName} (${staff?.staffEmail})`,
        })) || [];
    
        const manualStaffOptions = manualStaffs?.map((staff) => ({
            value: staff?._id,
            label: `${staff?.staffName} (${staff?.staffEmail})`,
        })) || [];
        const paymentGateways = activePaymentGateways?.map((active) => ({
            value: active?._id,
            label: `${active?.provider} ${active?.businessName ? ` (${active.businessName})` : ''}`

        })) || [];
      
        const fetchPaymentGroups = async () => {
            
                const res = await dispatch(getPaymentGroups({ paginate: false }));
                setPaymentGroups(res);
            };
        
            useEffect(() => {
               
                fetchPaymentGroups();
                //eslint-disable-next-line
            }, []);
        

       



    return (
        <div className="max-w-[79.3rem]">
            <div className="mb-12">
                <GoBack title="Payment Schedule" subTitle="Create Payroll" />
            </div>

          
            <Formik
                initialValues={{
                    payrollName: '',
                    payrollType: '',
                    groupIds: [],
                    subGroupIds: [],
                    staffType: '',
                    staffIds: [],
                    manualStaffIds: [],
                    disbursementMode: '',
                    disbursementDate: '',
                    disbursementTime: '',
                    paymentGatewayId: '',
                    monthAndYear: '',
                    

                }}
                onSubmit={async (values) => {
                    
                    const payload = {
                        ...values,
                        groupIds: values.payrollType === 'group' ? values.groupIds : undefined,
                        subGroupIds: values.payrollType === 'group' ? selectedSubGroupIds : undefined,
                        disbursementDate: values.disbursementMode === 'automatic' ? values.disbursementDate : undefined,
                        disbursementTime:  values.disbursementMode === 'automatic' ? values.disbursementTime : undefined,
                  
                        staffIds:  values.payrollType === 'individual' ? values.staffIds : undefined,
                        manualStaffIds:  values.payrollType === 'individual' ? values.manualStaffIds : undefined,
                    };
                    delete payload.staffType;
                    const res = await dispatch(addPayrollSchedule(payload));
                    if (res) {
                        navigate(-1);
                    }
                }}
            >
                {({ handleChange, values, errors, setFieldValue, isValid, touched }) => 
                 (
                    
                    <Form>
                        <Container>
                            <FlexCentredRow className='gap-[1rem] mb-[3.2rem]'>
                                <Plus />
                            <Text align="left" size="1.6rem" weight="600" lineHeight="2.4rem" >
                                Create Payroll
                            </Text>
                            </FlexCentredRow>
                        
                            <div className="flex flex-col gap-[3.2rem]">
                            <TextInput
                                    label="Payroll Name"
                                    name="payrollName"
                                    type="text"
                                    placeholder="Enter payroll Name"
                                    errorMessage={errors.payrollName}
                                    onChange={handleChange}
                                    value={values?.payrollName}
                                />
                           
                           <Select
                                    name="payrollType"
                                    label="Payroll Type"
                                    objProp="payrollType"
                                    data={payrollTypes?.map((item) => ({ payrollType: item }))}
                                    placeholder="Select payroll type "
                                    onChange={(selected) => {
                                        setFieldValue('payrollType', String(selected[0].payrollType));
                                    }}
                                    error={errors.payrollType}
                                    passedSelectedItems={values.payrollType}
                                />
                            {values.payrollType === 'group'&& 
                            <Select
                                    name="groupIds"
                                    label="Groups"
                                    objProp="label"
                                    data={groupOptions}
                                    placeholder="Select a Payment Group"
                                    onChange={(selected) => {
                                        setFieldValue('groupIds', selected?.map(s => s.value));
                                        setSelectedGroupIds(selected?.map(s => s.value))
                                        setSelectedGroupType(selected[0].groupType)
                                    }}
                                    error={errors.groupIds}
                                    passedSelectedItems={values.groupIds}
                                    multiSelect={true}
                                /> }

                            {values.payrollType === 'group' && selectedGroupType === "Group And SubGroups" ? 
                            
                            <StepDropdown
                            groupIds= {selectedGroupIds}
                            searchable 
                            searchPlaceholder= "Search steps" 
                            steps={allpaymentGroups} 
                          
                            onSelect={(selected) => {
                                const formattedSubGroupIds = Object.entries(selected).reduce((acc, [groupId, steps]) => {
                                    acc[groupId] = [...new Set(steps?.map(step => step?.stepId))]; 
                                    return acc;
                                  }, {});


                            setSelectedSubGroupIds([formattedSubGroupIds]);
                            setFieldValue('subGroupIds', [formattedSubGroupIds]);
                        }}
/>
                             : '' }

                            {values.payrollType === 'individual' &&

                              <Select
                                  name="staffType"
                                  label="Staff Type"
                                  objProp="staffType"
                                  data={staffTypes.map((item) => ({ staffType: item }))}
                                  placeholder="Select a Staff Type"
                                  onChange={(selected) => {
                                      setSelectedStaffType(String(selected[0].staffType)); 
                                      setFieldValue('staffType', String(selected[0].staffType)); 
                                  }}
                                  error={errors.staffType}
                                  passedSelectedItems={selectedStaffType}
                              />
                            }
                           {values.staffType && (
                        <Select
                            name={values.staffType === 'Platform' ? 'staffIds' : 'manualStaffIds'}
                            label="Staff"
                            objProp= 'label' 
                            data={values.staffType === 'Platform' ? platformStaffOptions : manualStaffOptions}
                            placeholder="Search Staff"
                            onChange={(selected) => {
                                if (values.staffType === 'Platform') {
                                    setFieldValue('staffIds', selected.map((s) => s.value));
                                } else if (values.staffType === 'Manual') {
                                    setFieldValue('manualStaffIds', selected.map((s) => s.value));
                                }
                            }}
                            multiSelect={true}
                            searchable={true}
                            error={errors.staffType}
                            passedSelectedItems={values.staffType === 'Platform' ? values.staffIds : values.manualStaffIds}
                    />
                    )}
                            <FlexCentredCol className='gap-2'>   
                                <Select
                                    name="disbursementMode"
                                    objProp="disbursementMode"
                                    label="Disbursement Mode"
                                    data={disbursementModes?.map((item) => ({ disbursementMode: item }))}
                                    placeholder="Select Type"
                                    onChange={(selected) => {
                                        setFieldValue('disbursementMode', String(selected[0].disbursementMode));
                                    }}
                                    error={errors.disbursementMode}
                                    passedSelectedItems={values.disbursementMode}
                                />
                                <Text weight="400" size="1.2rem" lineHeight="1.7rem" color="#9CA3AF" align="left" >Disburse Automatically or click a button every month to disburse</Text>
                            </FlexCentredCol>
                            {values.disbursementMode === 'automatic' &&                             
                               <> 
                            <DatePicker label="Disbursement Date" name="disbursementDate" touched={touched} />
                            <TimeInput 
                                label="Disbursement Time"
                                name="disbursementTime"
                                type="time"
                                onChange={handleChange}
                                value={values.disbursementTime}
                                error={errors.disbursementTime}
                                />
                                </>}
                                 <Select
                                    name="paymentGatewayId"
                                    objProp="label"
                                    label="Payment Gateway"
                                    data={paymentGateways}
                                    placeholder="Select Type"
                                    onChange={(selected) => {
                                        setFieldValue('paymentGatewayId', selected[0].value);
                                    }}
                                    error={errors.paymentGatewayId}
                                    passedSelectedItems={values.paymentGatewayId}
                                />  
                            
               
                        <DatePicker label="Payroll Month & Year" name="monthAndYear" touched={touched} />
                            </div>
                        </Container>
                        <FlexRowEnd className="gap-[2rem] my-[3.2rem]">
                            <CancelButton type="button" onClick={() => navigate(-1)}>
                                Cancel
                            </CancelButton>
                            <LoadingButton
                                loading={isLoading}
                                disabled={!isValid || isLoading}
                                bgColor="#6366F1"
                                color="#fff"
                                type="submit"
                            >
                                Create Payroll
                            </LoadingButton>
                        </FlexRowEnd>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CreatePayrollSchedule;
