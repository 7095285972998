import { toast } from 'react-hot-toast';

import { axiosInstance, commsAPI, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';
import handleApiError from '../handleApiError';

import {
    GET_ALL_COLLEGES,
    GET_ALL_DISTRIBUTION_LIST,
    GET_ALL_FACULTIES,
    GET_SINGLE_DISTRIBUTIONLIST,
    LOADING,
    STOP_LOADING,
} from './slice';

export const addDistributionList = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());

    try {
        const response = await axiosInstance.post(`${commsAPI}:campusId/:unitId/distributions`, payload);
        if (response.status === 201) {
            toast.success('Distribution List Created Successfully!');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getDistributionListById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    const {
        organisationUnits: { currentUnit },
        campus: { organizationProfile },
    } = getState();
    try {
        const response = await axiosInstance.get(
            `${commsAPI}:campusId/${currentUnit._id}/distributions/${organizationProfile?._id}/${id}`,
        );
        if (response.status === 200) {
            dispatch(GET_SINGLE_DISTRIBUTIONLIST(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllDistributionList = (paramName, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    const {
        organisationUnits: { currentUnit },
    } = getState();
    try {
        const response = await axiosInstance.get(`${commsAPI}:campusId/${currentUnit?._id}/distributions`);
        if (response.status === 200) {
            dispatch(GET_ALL_DISTRIBUTION_LIST(response.data.payload));
            return response.data.payload;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.dismiss(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getDistributionListByUnitname = () => async (dispatch, getState) => {
    dispatch(LOADING());

    const unitName = sessionStorage.getItem('unitName');

    try {
        const response = await axiosInstance.get(
            `${commsAPI}:unitId/distributions/:campusId/:unitId?unitName=${unitName}`,
        );
        if (response.status === 200) {
            dispatch(GET_ALL_DISTRIBUTION_LIST(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.dismiss(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editDistributionList = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());

    try {
        const response = await axiosInstance.put(`${commsAPI}:campusId/:unitId/distribution-list/${id}`, payload);
        const successMessage = response?.data?.message || 'Distribution List Updated Successfully!';
        if (response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteDistributionList = (id) => async (dispatch, getState) => {
    dispatch(LOADING());

    try {
        const response = await axiosInstance.delete(`${commsAPI}:campusId/:unitId/distributions/${id}`);
        if (response.status === 200) {
            const successMessage = response?.data?.message || 'Distribution List Deleted Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllColleges = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}college/:campusId/:unitId/get_all_colleges`);
        if (response.status === 200) {
            const { data = [] } = response?.data || {};

            const transformedData = data?.map((item) => {
                const { collegeAdmin, collegeCode, collegeName, collegeNumberingCode, ...otherFields } = item;

                return {
                    facultyAdmin: collegeAdmin,
                    facultyCode: collegeCode,
                    facultyName: collegeName,
                    facultyNumberingCode: collegeNumberingCode,
                    ...otherFields,
                };
            });
            dispatch(GET_ALL_COLLEGES(transformedData));
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllFaculties = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}faculty/:campusId/:unitId/all_faculties?${queries}`);
        if (response.status === 200) {
            const { data = [] } = response?.data || {};
            dispatch(GET_ALL_FACULTIES(data));
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
