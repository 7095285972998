import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as PlusIcon } from '../../../assets/icons/circularplus-blue.svg';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import {
    addCourseToCurriculumInCollege,
    addCourseToCurriculumInFaculty,
    changeMinimumPassScore,
} from '../../../redux/curriculum/actions';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import ToggleSwitch from '../../inputs/switch';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const AddCourseToCurriculum = ({
    actionType = 'add',
    show,
    setShow,
    course,
    onFinish,
    level,
    session,
    semester,
    lastDegreeId,
    curriculumId,
}) => {
    const dispatch = useDispatch();
    const onClose = () => setShow('');
    const { isLoading } = useSelector((state) => state.curriculum);
    const { currentStructure } = useSelector((state) => state.academicStructure);
    const { currentDepartment } = useSelector((state) => state.department);
    const { academicStructureId } = currentDepartment || {};

    const [isChangeCourseName, setIsChangeCourseName] = useState(false);

    return (
        <>
            <FormContainer
                close={onClose}
                headerIcon={<PlusIcon />}
                show={show}
                title={`Set Curriculum Specifications for ${course?.courseTitle || ''}(${course?.courseCode || ''})`}
            >
                <Formik
                    initialValues={{
                        courseClass: course?.courseClass || '',
                        courseUnit: course?.courseUnit || '',
                        minimumPassScore: course?.minimumPassScore || '',
                        courseTitle: course?.courseTitle || '',
                    }}
                    validationSchema={ValidationSchema}
                    enableReinitialize
                    onSubmit={async (values, actions) => {
                        let res;

                        const updateCourseDetails = async (response) => {
                            const updatePayload = {
                                minimumPassScore: values.minimumPassScore,
                                courseUnit: values.courseUnit,
                                courseTitle: isChangeCourseName ? values.courseTitle : course?.courseTitle,
                                courseClass: values.courseClass,
                            };

                            const curriculumIdToUse = actionType === 'edit' ? curriculumId : response?._id;
                            const courseIdToUse = actionType === 'edit' ? course?.courseId : course?._id;

                            const updateParams = {
                                structureId: academicStructureId,
                                lastDegreeId: lastDegreeId,
                                curriculumId: curriculumIdToUse,
                                courseId: courseIdToUse,
                                payload: updatePayload,
                            };
                            const updateRes = await dispatch(changeMinimumPassScore(updateParams));

                            if (updateRes) {
                                toast.success(`Curriculum updated successfully!`);
                                onClose();
                                actions.resetForm();
                                onFinish?.();
                                return;
                            }
                        };

                        if (actionType === 'edit') {
                            updateCourseDetails();
                            return;
                        }

                        const createPayload = {
                            session,
                            semester,
                            courseCode: course?.courseCode,
                            courseClass: values.courseClass,
                        };

                        if (currentStructure?.name?.toLowerCase() === 'faculty') {
                            res = await dispatch(
                                addCourseToCurriculumInFaculty(academicStructureId, lastDegreeId, level, createPayload),
                            );
                        }

                        if (currentStructure?.name?.toLowerCase() === 'college') {
                            res = await dispatch(
                                addCourseToCurriculumInCollege(academicStructureId, lastDegreeId, level, createPayload),
                            );
                        }

                        if (res) {
                            updateCourseDetails(res);
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <div className="gap-5 flex flex-col">
                                <Select
                                    name="courseClass"
                                    objProp="name"
                                    placeholder="Course Status"
                                    label="Course Status"
                                    useComponentState={false}
                                    data={['Compulsory', 'Required', 'Elective'].map((item) => ({ name: item }))}
                                    onChange={(selected) => {
                                        setFieldValue('courseClass', String(selected[0].name));
                                    }}
                                    passedSelectedItems={values?.courseClass ? [{ name: values?.courseClass }] : null}
                                    error={errors.courseClass}
                                />

                                <TextInput
                                    name="courseUnit"
                                    label="Course Unit"
                                    type="number"
                                    placeholder="Course Unit"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.courseUnit}
                                />
                                <TextInput
                                    label="Pass Mark"
                                    name="minimumPassScore"
                                    type="text"
                                    placeholder="Pass Mark"
                                    value={values.minimumPassScore}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                                <FlexRowSpaceBetween>
                                    <label className="text-[#1F2937] font-medium text-[1.4rem]" htmlFor="courseTitle">
                                        Change Course Name
                                    </label>
                                    <ToggleSwitch checked={isChangeCourseName} setChecked={setIsChangeCourseName} />
                                </FlexRowSpaceBetween>
                                {isChangeCourseName && (
                                    <TextInput
                                        name="courseTitle"
                                        id={'courseTitle'}
                                        type="text"
                                        placeholder="Course Name"
                                        value={values.courseTitle}
                                        onChange={handleChange}
                                        errors={errors}
                                    />
                                )}
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} type="submit">
                                    {actionType === 'edit' ? 'Update' : 'Save'}
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default AddCourseToCurriculum;
