/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import Checkbox from '../../../../../components/inputs/checkbox';
import SearchInput from '../../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../../components/items-per-page';
import UploadFile from '../../../../../components/upload-file';
import { Text } from '../../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween, ItemsPerPageCont } from '../../../../../containers/ScreenContainers';
import { addLabelsToData, formatDate } from '../../../../../utils';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Subject',
        key: 'subject',
    },
    {
        label: 'Message',
        key: 'message',
    },
    {
        label: 'Type',
        key: 'label',
    },
    {
        label: 'Time Stamp',
        key: 'time',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const StatusContainer = styled.div`
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
    width: fit-content;
`;

const InboxAll = ({ allInbox }) => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const { isLoading } = useSelector((store) => store.staffArena);
    const combinedArray = addLabelsToData(allInbox, 'Staff-Arena');

    const sortedData = combinedArray?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const AllData = sortedData?.map((item) => {
        const formattedDate = formatDate(item.createdAt);
        return {
            id: item._id,
            subject: item.title,
            message: item.content.length > 50 ? item.content.slice(0, 50) + '...' : item.content,
            label: item.label,
            time: formattedDate,
        };
    });

    const totalPages = Math.ceil(AllData?.length / itemsPerPage);

    const handleClick = (item) => {
        if (item.label === 'Memo') {
            navigate(`memo/${item.id}`);
        } else if (item.label === 'Notice') {
            navigate(`notice/${item.id}`);
        } else if (item.label === 'Letter') {
            navigate(`letter/${item.id}`);
        }
    };

    return (
        <>
            <div className="mt-[3.2rem] rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>
                {/* <Table
                    items={AllData || []}
                    fields={fields}
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    onRowClick={(item) => handleClick(item)}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),

                        subject: (item) => <td style={{ fontWeight: '600' }}>{item.subject}</td>,
                        label: (item) => (
                            <td>
                                {item.label === 'Memo' ? (
                                    <StatusContainer bgColor="#FDF2F8">
                                        <Text color="#EB4898" size="1.2rem">
                                            {item.label}
                                        </Text>
                                    </StatusContainer>
                                ) : item.label === 'Notice' ? (
                                    <StatusContainer bgColor="#ECFDF5">
                                        <Text color="#10B981" size="1.2rem">
                                            {item.label}
                                        </Text>
                                    </StatusContainer>
                                ) : item.label === 'Letter' ? (
                                    <StatusContainer bgColor="#EFF6FF">
                                        <Text color="#3B82F6" size="1.2rem">
                                            {item.label}
                                        </Text>
                                    </StatusContainer>
                                ) : (
                                    <StatusContainer bgColor="#F5F3FF">
                                        <Text color="#8B5CF6" size="1.2rem">
                                            {item.label}
                                        </Text>
                                    </StatusContainer>
                                )}
                            </td>
                        ),
                    }}
                /> */}
            </div>

            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </>
    );
};

export default InboxAll;
