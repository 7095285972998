import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';
import handleApiError from '../handleApiError';

import { GET_HANDBOOK_BY_ID, LOADING, STOP_LOADING } from './slice';

export const createHandbook = (payload, headers) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}handbook/:campusId/:unitId/handbooks`,
            payload,
            headers,
        );
        if (response.status === 201) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editHandbook = (payload, headers, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}handbook/:campusId/:unitId/handbooks/${id}`,
            payload,
            headers,
        );
        if (response.status === 201) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const publishHandbook = (selectedId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}handbook/:campusId/:unitId/handbooks/publish/${selectedId}`,
        );
        const successMessage = response?.data?.message || 'Handbook published Successfully';
        if (response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getHandbook = (session, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}handbook/:campusId/:unitId/handbooks/${session}?${queries}`,
        );

        const { data } = response?.data || {};
        return data || [];
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        return errorMessage;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getHandbookById = (id, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}handbook/:campusId/:unitId/handbooks/${id}/${session}`);
        dispatch(GET_HANDBOOK_BY_ID(response.data.data));
        return true;
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteHandbook = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}handbook/:campusId/:unitId/handbooks`, {
            data: payload,
        });
        const successMessage = response?.data?.message || 'Handbook deleted Successfully';
        if (response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
