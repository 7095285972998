import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as ChevronGrey } from '../../../assets/icons/arrow-down2.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus-blue.svg';
import { ActionsPopup, CalenderDoc, CalenderToggle, Timetable } from '../../../components';
import { Button } from '../../../components/buttons';
import DeleteItem from '../../../components/delete';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import {
    FlexRowSpaceBetween,
    FlexCentredRow,
    FlexRowEnd,
    RelativeContainer,
} from '../../../containers/ScreenContainers';
import usePersistedState from '../../../hooks/usePersistedState';
import { getActiveSemester, getActiveSession, getAdmissionSessions } from '../../../redux/admissions/actions';
import {
    getAllExamTimetable,
    getFridayExam,
    getMondayExam,
    getSaturdayExam,
    getSundayExam,
    getThursdayExam,
    getTuesdayExam,
    getWednesdayExam,
} from '../../../redux/timetable/actions';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { localStoreKeys } from '../../../utils/localStore';

const Box = tw.div`

`;

const MainContainer = tw.div`
    h-auto
    bg-white
    pl-[3.5rem]
    pr-[2.3rem]
    py-[2.5rem]
    rounded-lg
`;

const ViewBox = tw.div`
    cursor-pointer
    flex
    items-center
    gap-[1rem]
    border-[1px]
    rounded-[4px]
    border-[#6366F1]
    px-[2rem]
    py-[.5rem]
`;

const semesters = ['First Semester', 'Second Semester'];

const ExamTimetable = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        allExamTimetable,
        mondayExamTimetable,
        tuesdayExamTimetable,
        wednesdayExamTimetable,
        thursdayExamTimetable,
        fridayExamTimetable,
        saturdayExamTimetable,
        sundayExamTimetable,
    } = useSelector((store) => store.timetable);
    const { sessions: admissionsSessions, activeSession, activeSemester } = useSelector((state) => state.admission);

    const [showDelete, setShowDelete] = useState(false);
    const [viewActions, setViewActions] = useState(false);
    const [documentView, setDocumentView] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [session, setSession] = usePersistedState(
        activeSession?.split('-')[0] || '',
        localStoreKeys.ExamTimetableSession,
    );
    const [semester, setSemester] = usePersistedState(activeSemester || 'First Semester', localStoreKeys.LongSemester);

    const handleDropdown = (action) => {
        if (action === showDropdown) {
            return setShowDropdown(null);
        }
        setShowDropdown(action);
    };

    useEffect(() => {
        dispatch(getAllExamTimetable());
        dispatch(getMondayExam());
        dispatch(getTuesdayExam());
        dispatch(getWednesdayExam());
        dispatch(getThursdayExam());
        dispatch(getFridayExam());
        dispatch(getSaturdayExam());
        dispatch(getSundayExam());
        dispatch(getAdmissionSessions());
        dispatch(getActiveSession());
        dispatch(getActiveSemester());
    }, [dispatch]);

    const data = allExamTimetable?.map((t) => {
        const examDate = new Date(t.examDate);
        const examStartTime = new Date(t.startTime);

        const newExamDate = new Date(t.examDate);
        const examEndTime = new Date(t.endTime);

        examDate.setHours(examStartTime.getUTCHours());
        examDate.setMinutes(examStartTime.getUTCMinutes());
        examDate.setSeconds(examStartTime.getUTCSeconds());

        newExamDate.setHours(examEndTime.getUTCHours());
        newExamDate.setMinutes(examEndTime.getUTCMinutes());
        newExamDate.setSeconds(examEndTime.getUTCSeconds());
        return {
            id: t._id,
            title: t.course,
            allDay: false,
            start: examDate,
            end: newExamDate,
        };
    });

    const documentItems = [
        { dayOfTheWeek: 'Sunday', data: sundayExamTimetable },
        { dayOfTheWeek: 'Monday', data: mondayExamTimetable },
        { dayOfTheWeek: 'Tuesday', data: tuesdayExamTimetable },
        { dayOfTheWeek: 'Wednesday', data: wednesdayExamTimetable },
        { dayOfTheWeek: 'Thursday', data: thursdayExamTimetable },
        { dayOfTheWeek: 'Friday', data: fridayExamTimetable },
        { dayOfTheWeek: 'Saturday', data: saturdayExamTimetable },
    ];

    return (
        <Box onClick={viewActions ? () => setViewActions(false) : null}>
            <FlexRowSpaceBetween className="mb-[2.4rem]">
                <PageTitle align="left">Exam Time-table</PageTitle>
                <FlexRowEnd className="gap-[2rem] relative">
                    <Button onClick={() => navigate('create')} bgColor="#FFF" color="#6366F1">
                        <Plus className="mr-2" />
                        Create New Schedule
                    </Button>
                    <ViewBox onClick={() => setViewActions(!viewActions)}>
                        <Text weight="500" size="1.4rem" color="#6366F1">
                            View
                        </Text>
                        <ArrowDown />
                    </ViewBox>
                    {viewActions && (
                        <CalenderToggle
                            onDocumentClick={() => setDocumentView(true)}
                            onCalenderClick={() => setDocumentView(false)}
                        />
                    )}
                </FlexRowEnd>
            </FlexRowSpaceBetween>

            <MainContainer>
                <FlexRowSpaceBetween>
                    <FlexCentredRow className="gap-[2rem]">
                        <RelativeContainer>
                            <Button
                                bgColor="#FFF"
                                color="#6B7280"
                                border="0.5px solid #9CA3AF"
                                onClick={() => handleDropdown('session')}
                            >
                                {session || 'Select Session'}
                                <ChevronGrey />
                            </Button>
                            <ActionsPopup
                                open={showDropdown === 'session'}
                                close={() => setShowDropdown(null)}
                                items={admissionsSessions?.map((item) => ({
                                    name: item.admissionYear,
                                    click: () => {
                                        setSession(item.admissionYear);
                                        setShowDropdown(null);
                                    },
                                }))}
                            />
                        </RelativeContainer>

                        <RelativeContainer>
                            <Button
                                bgColor="#FFF"
                                color="#6B7280"
                                border="0.5px solid #9CA3AF"
                                onClick={() => handleDropdown('semester')}
                            >
                                {semester || 'Select Semester'}
                                <ChevronGrey />
                            </Button>
                            <ActionsPopup
                                open={showDropdown === 'semester'}
                                close={() => setShowDropdown(null)}
                                items={semesters.map((item) => ({
                                    name: item,
                                    click: () => {
                                        setShowDropdown(null);
                                        setSemester(item);
                                    },
                                }))}
                            />
                        </RelativeContainer>
                    </FlexCentredRow>
                </FlexRowSpaceBetween>

                {!documentView ? (
                    <Timetable eventsList={data} />
                ) : (
                    <>
                        {documentItems.map((item) => (
                            <CalenderDoc
                                key={item.dayOfTheWeek}
                                dayOfTheWeek={item.dayOfTheWeek}
                                data={item.data}
                                session={session}
                                semester={semester}
                            />
                        ))}
                    </>
                )}
            </MainContainer>
            <DeleteItem show={showDelete} setShow={setShowDelete} />
        </Box>
    );
};

export default ExamTimetable;
