import React, { useEffect, useState } from 'react';

import NavTabs, { Tab } from '../../components/nav-tabs/NavTabs';
import { PageTitle } from '../../containers/MesssageContainers';
import { parseJSON, stringifyJSON } from '../../utils/useJSON';

const tabs = [{ label: 'Staff' }, { label: 'Administrators' }, { label: 'Roles' }];

const Access = ({ staffs, administrators, roles }) => {
    const savedActiveTab = parseJSON('activeTab');
    const [pageTitle, setPageTitle] = useState(savedActiveTab || 'Staff');
    const [activeTab, setActiveTab] = useState(savedActiveTab || '');

    const handleTabClick = (tab) => {
        stringifyJSON('activeTab', tab);
        setPageTitle(tab);
        setActiveTab(tab);
    };

    useEffect(() => {
        if (savedActiveTab) {
            setActiveTab(savedActiveTab);
        } else {
            setActiveTab('Staff');
        }
    }, [activeTab, savedActiveTab, pageTitle]);

    return (
        <div>
            <PageTitle align="left" bottom="2rem">
                {pageTitle}
            </PageTitle>

            <NavTabs tabs={tabs} onClick={handleTabClick}>
                <Tab.Panel>{staffs}</Tab.Panel>
                <Tab.Panel>{administrators}</Tab.Panel>
                <Tab.Panel>{roles}</Tab.Panel>
            </NavTabs>
        </div>
    );
};

export default Access;
