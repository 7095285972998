import React from 'react';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import CentreModal from '../../../../components/modal/CentreModal';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';

const DemoteStudents = ({ show, close, list, onSend, subtitle, title, idField = 'id', isLoading }) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[56rem] max-h-[70vh] mx-auto">
                <FlexRowSpaceBetween>
                    <Text as="h3" size="1.9rem" weight="600" align="left">
                        {title || 'Are you sure you want to demote the student(s)?'}
                    </Text>
                    <CloseIcon className="hover:cursor-pointer" onClick={close} />
                </FlexRowSpaceBetween>

                <Text align="start" size="1.6rem" color="#374151" top="1.6rem" lineHeight="2.4rem" bottom="1rem">
                    The selected student(s) will be demoted. Please check the list below to review the selections.
                </Text>

                <ul className="overflow-y-auto border-b pb-2 mt-5">
                    {list?.map((item, index) => (
                        <Text align="left" key={item?.[idField] || index}>{`${item?.lastName} ${item?.firstName} ${
                            item?.middleName || ''
                        }`}</Text>
                    ))}
                </ul>

                <Text color="#6B7280" size="1.2rem" align="left">
                    {list?.length} name(s) selected
                </Text>

                <FlexRowEnd className="gap-x-[16px]">
                    <Button type="button" onClick={close} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} onClick={onSend}>
                        Demote
                    </LoadingButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default DemoteStudents;
