import React, { useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

// import { ReactComponent as InfoIcon } from '../../../../assets/icons/info.svg';
import { ReactComponent as RefreshIcon } from '../../../../assets/icons/refresh.svg';
import { SearchInput } from '../../../../components';
import RadioInput from '../../../../components/inputs/radio';
import { Loader } from '../../../../components/loader';
import { FilterContainer } from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import useSearch from '../../../../hooks/useSearch';

const Th = styled.th`
    overflow: auto;
    border-width: 1px;
    padding: 8px;

    & > svg {
        display: inline-block;
    }
`;

const Td = tw.td`
    border
    border-gray-100
`;

const Results = ({
    selectedStudent,
    handleSelectStudent,
    resultsData,
    level,
    session,
    curriculumId,
    firstDegree,
    secondDegree,
    semester,
    toggleRefetch,
    setToggleRefetch,
}) => {
    // const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.examMagt);

    const [cocLength, setCocLength] = useState(1);
    // const [resultsData, setResultsData] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    // const [toggleRefetch, setToggleRefetch] = useState(false);
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const filteredStudents = useSearch(debouncedSearchValue, resultsData?.data, ['name']);

    const curriculumCourses = useMemo(() => resultsData?.curriculum?.map((course) => course) || [], [resultsData]);
    const sortedCurriculumCourses = useMemo(
        // changed to courseId because student course object sometimes doesn't have courseCode field
        // () => curriculumCourses?.sort((a, b) => a?.courseCode?.localeCompare(b?.courseCode)),
        () => curriculumCourses?.sort((a, b) => a?.courseId?.localeCompare(b?.courseId)),
        [curriculumCourses],
    );

    const handleChangeCocLength = (items = []) => {
        if (items?.length > cocLength) {
            setCocLength(items?.length);
        }
        return;
    };

    // useEffect(() => {
    //     if (semester && session && firstDegree && secondDegree && level && curriculumId) {
    //         const getData = async () => {
    //             const params = {
    //                 level,
    //                 session,
    //                 curriculumId,
    //                 academicStructureId: firstDegree.academicStructureId,
    //                 lastDegreeId: secondDegree._id,
    //                 semester: semester.toLowerCase(),
    //             };

    //             const res = await dispatch(getResultsBacklog(params));
    //             if (res) {
    //                 return setResultsData(res);
    //             }
    //             return setResultsData({});
    //         };

    //         getData();
    //     }
    // }, [curriculumId, dispatch, firstDegree, level, secondDegree, semester, session, toggleRefetch]);

    if (isLoading) return <Loader />;

    return (
        <div className="bg-white mt-4 rounded-lg">
            <FilterContainer>
                <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />

                <RefreshIcon className="cursor-pointer" onClick={() => setToggleRefetch(!toggleRefetch)} />
            </FilterContainer>

            <div className="text-center text-[12px] shadow-md text-[#4B5563] bg-white min-h-[40vh] max-h-[60vh] overflow-auto">
                <table className="border border-gray-400">
                    {/* Table Headers */}
                    <thead className="sticky top-0 z-10 bg-white">
                        {/* Header Row 1 */}
                        <tr className="font-semibold">
                            <Th colSpan=""></Th>
                            <Th></Th>
                            <Th colSpan={curriculumCourses?.length} className="border p-2">
                                Main Course
                            </Th>
                            <Th colSpan={cocLength}>
                                COC
                                {/* <InfoIcon className="h-4" /> */}
                            </Th>
                            <Th colSpan="6"></Th>
                            {/* <Th colSpan="4"></Th> */}
                            <Th></Th>
                            <Th></Th>
                            <Th></Th>
                        </tr>

                        {/* Header Row 2 */}
                        <tr>
                            <Th colSpan=""></Th>
                            <Th>Course Code</Th>
                            {sortedCurriculumCourses.length > 0 ? (
                                sortedCurriculumCourses?.map((course) => (
                                    <Th key={course?.courseCode}>{course?.courseCode}</Th>
                                ))
                            ) : (
                                <Th></Th>
                            )}
                            <Th colSpan={cocLength}></Th>

                            <Th colSpan="6">CGPA</Th>
                            {/* <Th colSpan="4">Previous CGPA</Th> */}
                            <Th>Academic Standing</Th>
                            <Th>Honour List</Th>
                            <Th>Promotion Status</Th>
                        </tr>

                        {/* Header Row 3*/}
                        <tr>
                            <Th colSpan=""></Th>
                            <Th>Course Unit</Th>
                            {sortedCurriculumCourses?.length > 0 ? (
                                sortedCurriculumCourses?.map((course) => (
                                    <Th key={course?.courseCode}>{course?.courseUnit}</Th>
                                ))
                            ) : (
                                <Th></Th>
                            )}
                            <Th colSpan={cocLength}></Th>
                            <Th>Total Unit Registered</Th>
                            <Th>Total Unit Passed</Th>
                            <Th>Total Unit Failed</Th>
                            <Th>Total Weight Passed</Th>
                            <Th>Grade Point Average</Th>
                            <Th>Cumulative Grade Point Average</Th>
                            {/* <Th>Total Unit Registered</Th>
                            <Th>Total Unit Passed</Th>
                            <Th>Total Weight Passed</Th>
                            <Th>Cumulative Grade Point Average</Th> */}
                            <Th></Th>
                            <Th></Th>
                            <Th></Th>
                        </tr>
                        {/* Header Row 4 */}
                        <tr>
                            <Th colSpan="">Name</Th>
                            <Th>Matric No.</Th>
                            {sortedCurriculumCourses?.length > 0 ? (
                                sortedCurriculumCourses?.map((course) => <Th key={course?.courseCode} />)
                            ) : (
                                <Th></Th>
                            )}
                            <Th colSpan={cocLength}></Th>
                            <Th />
                            <Th />
                            <Th />
                            <Th />
                            <Th />
                            <Th />
                            <Th />
                            <Th />
                            <Th />
                        </tr>
                    </thead>

                    {/* Table Body */}

                    <tbody>
                        {filteredStudents
                            // remove null items
                            ?.filter((item) => item)
                            ?.map((student) => {
                                const studentCourses = student?.courses;
                                const curriculumCourseCodes = new Set(
                                    sortedCurriculumCourses.map(
                                        (curriculumCourse) =>
                                            // curriculumCourse?.courseCode?.trim(),
                                            curriculumCourse?.courseId,
                                    ),
                                );

                                const studentCoursesInCurriculum = studentCourses?.filter((studentCourse) =>
                                    // curriculumCourseCodes.has(studentCourse?.courseCode?.trim()),
                                    curriculumCourseCodes.has(studentCourse?.courseId),
                                );

                                const sortedStudentCourses = studentCoursesInCurriculum?.sort((a, b) =>
                                    // a?.courseCode?.trim()?.localeCompare(b?.courseCode?.trim()),
                                    a?.courseId?.localeCompare(b?.courseId),
                                );
                                const carryOvers = sortedStudentCourses?.filter((course) => course?.carriedOver);
                                handleChangeCocLength(carryOvers);

                                const resultArrayLength = student?.result?.length;
                                // Backend inspired
                                // const prevResult = student?.result?.[resultArrayLength - 2];
                                const currentResult = student?.result?.[resultArrayLength - 1];

                                return (
                                    <tr key={student.studentId} className="">
                                        <Td className="min-w-[150px] flex h-full gap-2 flex-row items-center p-4">
                                            <RadioInput
                                                checked={selectedStudent?.studentId === student?.studentId}
                                                onClick={() => handleSelectStudent(student)}
                                            />
                                            {student?.name}
                                        </Td>
                                        <Td>{student?.matricNumber || '-'}</Td>
                                        {sortedStudentCourses?.map((course) => (
                                            <Td key={course.courseId}>
                                                {course?.status === 'Registered'
                                                    ? (course?.totalScore || 0) + course.grade
                                                    : 'NR'}
                                            </Td>
                                        ))}
                                        <Td className="flex gap-2 items-center border-0">
                                            {carryOvers?.map((course) => (
                                                <p className="grid" key={course.courseCode}>
                                                    <span>{course?.courseCode}</span>
                                                    <span>{course?.totalScore}</span>
                                                </p>
                                            ))}
                                        </Td>
                                        {/* Current */}
                                        <Td>{currentResult?.totalUnitsRegistered}</Td>
                                        <Td>{currentResult?.totalUnitsPassed}</Td>
                                        <Td>{currentResult?.totalUnitsFailed}</Td>
                                        <Td>{currentResult?.totalWeightPassed}</Td>
                                        <Td>{currentResult?.gradePointAverage}</Td>
                                        <Td>{student?.cumulativeGradePointAverage}</Td>
                                        {/* // Previous */}
                                        {/* <Td>{prevResult?.totalUnitsRegistered}</Td>
                                        <Td>{prevResult?.totalUnitsPassed}</Td>
                                        <Td>{prevResult?.totalWeightPassed}</Td> */}
                                        {/* <Td>{student?.cumulativeGradePointAverage}</Td> */}
                                        <Td>{student?.academicStanding ? 'GS' : 'NGS'}</Td>
                                        <Td>{student?.honourDivision}</Td>
                                        <Td>{student?.promotionStatus}</Td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Results;
