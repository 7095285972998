import React from 'react';

// import { Table } from '@belrald_hq/belrald-ui';

import CustomTable from '../../../../../components/CustomTable';
import { Loader } from '../../../../../components/loader';
import { Text } from '../../../../../containers/MesssageContainers';
import { FlexColumn } from '../../../../../containers/ScreenContainers';

const colDefs = [
    {
        field: 'name',
    },
    {
        field: 'amount',
    },
    {
        field: 'feeType',
    },
];

const AttachedFees = ({ attachedFees, isLoading }) => {
    return (
        <div className="bg-white w-full py-[1.4rem] px-[1.6rem] rounded-lg h-[28rem] overflow-y-auto">
            {isLoading ? (
                <Loader containerStyle="h-[20rem] items-center" size="small" />
            ) : (
                <FlexColumn className="gap-[2.4rem]">
                    <Text align="left" size="1.4rem" color="#6B7280" weight="500">
                        Attached Fees
                    </Text>
                    <CustomTable colDefs={colDefs} data={attachedFees || []} />
                </FlexColumn>
            )}
        </div>
    );
};

export default AttachedFees;
