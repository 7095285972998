export const stringifyJSON = (key, value) => {
    if (!key || !value) {
        return false;
    }

    sessionStorage.setItem(key, JSON.stringify(value));

    return true; 
};

export const parseJSON = (key, defaultValue = null) => {
    try {
        let foundKeyValue = sessionStorage.getItem(key);

        if (foundKeyValue) {
            const parsedString = JSON.parse(foundKeyValue);
            return parsedString;
        }
    } catch (e) {
        console.log(e);
    }

    return defaultValue;
};

export const removeJSON = (key) => {
    if (!key) {
        return false;
    }

    sessionStorage.removeItem(key);

    return true;
};
