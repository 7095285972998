import React, { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { GoBack } from '../../../../components/go-back';
import { Loader } from '../../../../components/loader';
import NavTabs, { Tab } from '../../../../components/nav-tabs/NavTabs.js';
import { getStudentDetails } from '../../../../redux/students/actions';

import Medical from './tabs/medical.js';
import Overview from './tabs/overview.js';
import Personal from './tabs/personalInfo.js';
import Sports from './tabs/sports.js';
import Uploads from './tabs/uploads.js';

const StudentById = () => {
    const {
        state: { academicStruct, studentDet, currentUnit },
    } = useLocation();
    const dispatch = useDispatch();

    const { studentDetails, isLoading } = useSelector((store) => store.student);
    const { _id: studentId } = studentDet;

    const tabs = useMemo(
        () => [
            { label: 'Overview' },
            { label: 'Personal' },
            { label: 'Medical' },
            ...(currentUnit === 'Admissions' ? [{ label: 'Uploads' }] : []),
            ...(currentUnit === 'Students Affairs' ? [{ label: 'Sports' }] : []),
        ],
        [currentUnit],
    );

    useEffect(() => {
        dispatch(getStudentDetails(studentId));
    }, [dispatch, studentId]);

    if (isLoading) return <Loader />;

    return (
        <div>
            <GoBack title="Students" subTitle={`${studentDet?.firstName} ${studentDet?.lastName}`} />

            <div className="mt-[3.2rem]">
                <NavTabs tabs={tabs}>
                    <Tab.Panel>
                        <Overview
                            details={studentDetails}
                            academicStruct={academicStruct}
                            studentDet={studentDet}
                            currentUnit={currentUnit}
                        />
                    </Tab.Panel>
                    <Tab.Panel>
                        <Personal details={studentDetails} />
                    </Tab.Panel>
                    <Tab.Panel>
                        <Medical details={studentDetails} />
                    </Tab.Panel>
                    {currentUnit === 'Admissions' && (
                        <Tab.Panel>
                            <Uploads details={studentDetails} />
                        </Tab.Panel>
                    )}
                    {currentUnit === 'Students Affairs' && (
                        <Tab.Panel>
                            <Sports details={studentDetails} />
                        </Tab.Panel>
                    )}
                </NavTabs>
            </div>
        </div>
    );
};

export default StudentById;
