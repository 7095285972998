import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import handleApiError from '../handleApiError';

import {
    GET_PROBATION_SETTINGS,
    LOADING,
    STOP_LOADING,
    GET_ACADEMIC_STANDING_SETTINGS,
    GET_WITHDRAWAL_LIST_SETTINGS,
    GET_CUMULATIVE_UNITS_SETTINGS,
    GET_DEGREE_SETTINGS,
    GET_GRADE_SETTINGS,
    GET_MATRIC_SETTINGS,
    GET_MINIMUM_PROMOTION_CGPA,
    GET_WEIGHTED_GRADE_POINT_SETTINGS,
    GET_MATRIC_NUMBER_FORMATS,
} from './slice';

export const setCumulativeUnits =
    (structureId, departmentId, payload, useLoader = 'true') =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());

        try {
            const response = await axiosInstance.post(
                `${currentAPI}setting/:campusId/:unitId/cumulative_credit_unit/${structureId}/${departmentId}`,
                payload,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getCumulativeUnits = (academicStructureId, departmentId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}setting/:campusId/:unitId/last_degree_cumulative_credit_unit/${academicStructureId}/${departmentId}`,
        );
        if (response.status === 200) {
            dispatch(GET_CUMULATIVE_UNITS_SETTINGS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_CUMULATIVE_UNITS_SETTINGS({}));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createProbationListSetting =
    ({ session, academicStructureId, lastDegreeId, payload, useLoader = true }) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const response = await axiosInstance.post(
                `${currentAPI}exam/:campusId/:unitId/probation-list/${academicStructureId}/${lastDegreeId}/${session}`,
                payload,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getProbationListSetting = (academicStructureId, lastDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}exam/:campusId/:unitId/probation-list/${academicStructureId}/${lastDegreeId}/${session}`,
        );
        if (response.status === 200) {
            dispatch(GET_PROBATION_SETTINGS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_PROBATION_SETTINGS({}));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getMinimumPromotionCGPA = (academicStructureId, lastDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}exam/:campusId/:unitId/get-minimum-promotion-settings/${academicStructureId}/${lastDegreeId}/${session}`,
        );
        if (response.status === 200) {
            dispatch(GET_MINIMUM_PROMOTION_CGPA(response.data.data));
        }
    } catch (error) {
        dispatch(GET_MINIMUM_PROMOTION_CGPA({}));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createMinimumPromotionCGPA =
    ({ session, academicStructureId, lastDegreeId, payload, useLoader = true }) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const response = await axiosInstance.post(
                `${currentAPI}exam/:campusId/:unitId/create-minimum-promotion-settings/${academicStructureId}/${lastDegreeId}/${session}`,
                payload,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const createAcademicStanding =
    ({ academicStructureId, lastDegreeId, session, payload, useLoader = true }) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const response = await axiosInstance.post(
                `${currentAPI}exam/:campusId/:unitId/academic-standing/${academicStructureId}/${lastDegreeId}/${session}`,
                payload,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };
export const getAcademicStandingSettings =
    (academicStructureId, lastDegreeId, session) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.get(
                `${currentAPI}exam/:campusId/:unitId/academic-standing/${academicStructureId}/${lastDegreeId}/${session}`,
            );
            if (response.status === 200) {
                dispatch(GET_ACADEMIC_STANDING_SETTINGS(response.data.data));
            }
        } catch (error) {
            dispatch(GET_ACADEMIC_STANDING_SETTINGS({}));
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const createWithdrawalList =
    ({ academicStructureId, lastDegreeId, session, payload, useLoader = true }) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const response = await axiosInstance.post(
                `${currentAPI}exam/:campusId/:unitId/withdrawal-list/${academicStructureId}/${lastDegreeId}/${session}`,
                payload,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getWithdrawalListSettings = (academicStructureId, lastDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}exam/:campusId/:unitId/withdrawal-list/${academicStructureId}/${lastDegreeId}/${session}`,
        );
        if (response.status === 200) {
            dispatch(GET_WITHDRAWAL_LIST_SETTINGS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_WITHDRAWAL_LIST_SETTINGS({}));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createDegreeSetting = (params) => async (dispatch, getState) => {
    const { structureId, firstDegreeId, session, payload, useLoader = true } = params;

    if (useLoader) dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}exam/:campusId/:unitId/degree-setting/${structureId}/${firstDegreeId}/${session}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getDegreeSetting = (structureId, firstDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}exam/:campusId/:unitId/degree-setting/${structureId}/${firstDegreeId}/${session}`,
        );
        if (response.status === 200) {
            dispatch(GET_DEGREE_SETTINGS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_DEGREE_SETTINGS({}));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const createGradeSetting = (params) => async (dispatch, getState) => {
    const { structureId, firstDegreeId, session, payload, useLoader = true } = params;
    if (useLoader) dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}exam/:campusId/:unitId/grade-setting/${structureId}/${firstDegreeId}/${session}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getGradeSetting = (structureId, firstDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}exam/:campusId/:unitId/grade-setting/${structureId}/${firstDegreeId}/${session}`,
        );
        if (response.status === 200) {
            dispatch(GET_GRADE_SETTINGS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_GRADE_SETTINGS({}));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createWeightedGradePointSetting = (params) => async (dispatch, getState) => {
    const { structureId, firstDegreeId, session, payload, useLoader = true } = params;
    if (useLoader) dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}exam/:campusId/:unitId/weighted-grade-point/${structureId}/${firstDegreeId}/${session}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getWeightedGradePointSetting = (structureId, firstDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}exam/:campusId/:unitId/get-weighted-grade-point/${structureId}/${firstDegreeId}/${session}`,
        );
        if (response.status === 200) {
            dispatch(GET_WEIGHTED_GRADE_POINT_SETTINGS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_WEIGHTED_GRADE_POINT_SETTINGS({}));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const importGradeSettings = (structureId, firstDegreeId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}exam/:campusId/:unitId/import-grade-setting/${structureId}/${firstDegreeId}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            toast.success('Grade settings imported');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const importWeightedGradeSettings = (structureId, firstDegreeId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}exam/:campusId/:unitId/import-weighted-grade-point/${structureId}/${firstDegreeId}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            toast.success('Weighted Grade settings imported');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const importDegreeSettings = (structureId, firstDegreeId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}exam/:campusId/:unitId/import-degree-setting/${structureId}/${firstDegreeId}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            toast.success('Degree settings imported');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const importAcademicStanding = (structureId, lastDegreeId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}exam/:campusId/:unitId/import-academic-standing/${structureId}/${lastDegreeId}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            toast.success('Academic Standing settings imported');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const importWithdrawalList = (structureId, lastDegreeId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}exam/:campusId/:unitId/import-withdrawal-list/${structureId}/${lastDegreeId}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            toast.success('Withdrawal List settings imported');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const importProbationList = (structureId, lastDegreeId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}exam/:campusId/:unitId/import-probation-list/${structureId}/${lastDegreeId}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            toast.success('Probation List settings imported');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const importMinimumPromotionSettings = (structureId, lastDegreeId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}exam/:campusId/:unitId/import-minimum-promotion-settings/${structureId}/${lastDegreeId}`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            toast.success('Minimum promotion settings imported');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const setMatricFormat =
    (formatId, useLoader = false) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const response = await axiosInstance.post(
                `${currentAPI}setting/:campusId/:unitId/set_matriculation_number/${formatId}`,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getMatricNumberFormats = () => async (dispatch, getState) => {
    dispatch(LOADING());
    dispatch(GET_MATRIC_NUMBER_FORMATS([]));
    try {
        const response = await axiosInstance.get(`${currentAPI}setting/:campusId/:unitId/get_matric_format`);
        if (response.status === 200) {
            const { data } = response?.data || {};

            dispatch(GET_MATRIC_NUMBER_FORMATS(data || []));
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getSelectedMatricNumberFormat = () => async (dispatch, getState) => {
    dispatch(LOADING());
    dispatch(GET_MATRIC_SETTINGS({}));
    try {
        const response = await axiosInstance.get(`${currentAPI}setting/:campusId/:unitId/get_matriculation_number`);
        if (response.status === 200) {
            const { data } = response?.data || {};

            dispatch(GET_MATRIC_SETTINGS(data));
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
