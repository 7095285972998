import React, { useState, useEffect } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { GoBack } from '../../../../components/go-back';
import ConfirmActionRadio from '../../../../components/popups/confirm-with-radio';
import { getStatusStyles, StatusContainer } from '../../../../components/shared';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowSpaceBetween,
} from '../../../../containers/ScreenContainers';
import { useTableContext } from '../../../../context/tableContext';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import {  getPaymentScheduleById, processPartialPayrollSchedule, processPayrollSchedule } from '../../../../redux/bursary/actions';
import { formatMonthAndYear, formatToDateTime } from '../../../../utils/formatDate';
import { storeUnit } from '../../../../utils/originUnit';

const BasicContent = tw.div`
    grid
    md:grid-cols-4
    grid-cols-2
    my-8
    gap-4
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-[2.4rem]
    rounded-lg
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
`;

const modeOfDisbursement = ['Full Disbursement', 'Partial Disbursement'];


const PayrollScheduleDetails = ({ unitName }) => {
    const dispatch = useDispatch();
    const {
        state: { id, payrollName },
    } = useLocation();

    const { isLoading, payrollScheduleDetails } = useSelector((state) => state.bursary);
    const [searchValue, setSearchValue] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState([]);
    const [toggleRefetch, setToggleRefetch] = useState(null);

    const [inputValue, setInputValue] = useState('');
    const [percentage, setPercentage] = useState('');
    const [selectedModeOfDisbursement, setSelectedModeOfDisbursement] = useState('');

const {payrollSchedule, staffDetails = [], pagination} = payrollScheduleDetails || {}


const { totalItems, totalPages } = pagination || {};

    const [currentPage, setCurrentPage] = useState('1');
    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;
    useEffect(() => {
        if (!id) return;
        if (id) {
            const queries = {
                page: currentPage,
                limit: numOfPages,
            };
            dispatch(getPaymentScheduleById(id, queries));
        }
    }, [id, toggleRefetch,currentPage, numOfPages, dispatch]);
    

  

    const handleChangeStatus = async () => {
        if (!selectedModeOfDisbursement) {
            toast.error('Please select a mode of disbursement');
            return;
        }
        if (inputValue !== "disburse") {
            toast.error('Please enter the correct confirmation text');
            return;
        }

        const payload = {
            confirmationText: inputValue 
        };
    
        const partialPayload = {
            percentage: percentage,
            staffIds: selected.map(staff => staff.staffId) || [],
            confirmationText: inputValue 
        };
    
        let res;
        if (selectedModeOfDisbursement === 'Full Disbursement') {
            res = await dispatch(processPayrollSchedule(id, payload));
        } else if (selectedModeOfDisbursement === 'Partial Disbursement') {
            res = await dispatch(processPartialPayrollSchedule(id, partialPayload));
        }
    
        if (res) {
            toast.success('Payment schedule disbursed successfully!');
            setToggleRefetch((prevState) => !prevState);
            setOpenModal(false);
            setSelectedModeOfDisbursement('');
            setInputValue('');
        }
    };
    

    const fields = [
            {
                header: 'Staff Name',
                accessorKey: 'staffName',
              
            },
            {
                header: 'Payment Group',
                accessorKey: 'paymentGroup',
                cell: ({ row }) => row.original.paymentGroup.name,
            },
            {
                header: 'Staff Number',
                accessorKey: 'staffNumber',
               
            },
            {
                header: 'Month & Year',
                accessorKey: 'monthAndYear',
                cell: ({ row }) => formatMonthAndYear(row.original.monthAndYear),
            },
            {
                header: 'Bank Name',
                accessorKey: 'bankName',
               
            },
            {
                header: 'Amount',
                accessorKey: 'amount',
               
            },
            {
                header: 'Disbursement Status',
                accessorKey: 'disbursementStatus',
                cell: (props) => (
                    <StatusContainer style={getStatusStyles(props.getValue()?.toLowerCase())}>
                        <span>{props.getValue()}</span>
                    </StatusContainer>
                ),
            },
            {
                header: 'Disbursed At',
                accessorKey: 'disbursedAt',
                cell: ({ row }) => {
                    return <p>{formatToDateTime(row.original?.disbursedAt) ||'' }</p>;
                },
            },
           
           
        ];


    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');

        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const amPm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12 || 12; 
        const time = `${hours}:${minutes} ${amPm}`;

        return `${year}-${month}-${day} ${time}`;
    };

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <>
            <div>
                <GoBack title={`Payment Schedule`} subTitle={payrollName}></GoBack>
                <FlexCentredRow className="mt-[4.5rem]">
                    <PageTitle align="left">{payrollName}</PageTitle>
                </FlexCentredRow>
                <Details className="mt-[3rem]">
                    <FlexCentredCol>
                        <PageTitle align="left" size="1.9rem" lineheight="2.8rem">
                            Overview
                        </PageTitle>
                    </FlexCentredCol>

                    <BasicContent>
                        <FlexCol>
                            <GreyText>Payroll Month</GreyText>
                            <DarkText>{payrollSchedule?.payrollName}</DarkText>
                        </FlexCol>

                        <FlexCol>
                            <GreyText>Created At</GreyText>
                            <DarkText>{formatDate(payrollSchedule?.createdAt)}</DarkText>
                        </FlexCol>

                        <FlexCol className="max-w-xs break-words">
                            <GreyText>Overall Disbursement status</GreyText>
                            <DarkText className="line-clamp-2">{payrollSchedule?.overallDisbursementStatus}</DarkText>
                        </FlexCol>

                        <FlexCol>
                            <GreyText>Disbursed At</GreyText>
                            <DarkText>{new Date(payrollSchedule?.disbursedAt).toLocaleString('en-US', { month: 'long' })}</DarkText>
                        </FlexCol>

                        <FlexCol> 
                            <GreyText>Last Update At</GreyText>
                            <DarkText>{formatDate(payrollSchedule?.lastUpdatedAt)}</DarkText>
                        </FlexCol>

                        <FlexCol>
                            <GreyText>Created By</GreyText>
                            <DarkText>{payrollSchedule?.createdBy}</DarkText>
                        </FlexCol>
                        <FlexCol>
                            <GreyText>Last Updated By</GreyText>
                            <DarkText>{payrollSchedule?.createdBy}</DarkText>
                        </FlexCol>
                        <FlexCol>
                            <GreyText>Number of Staff to be paid</GreyText>
                            <DarkText>{payrollSchedule?.numberOfStaffToBePaid}</DarkText>
                        </FlexCol>
                        <FlexCol>
                            <GreyText>Amount to disburse (NGN)</GreyText>
                            <DarkText>{payrollSchedule?.amountToDisburse}</DarkText>
                        </FlexCol>
                        <FlexCol>
                            <GreyText>Payment Gateway</GreyText>
                            <DarkText>{payrollSchedule?.paymentGateway}</DarkText>
                        </FlexCol>
                    </BasicContent>
                </Details>

              
                            <PageLayout
                                onSearchChange={(e) => setSearchValue(e.target.value)}
                                searchValue={searchValue}
                                onSearchClose={() => setSearchValue('')}
                                showTableUtils
                                showFilter
                                data={staffDetails || []}
                                fields={fields}
                                loading={isLoading}
                                width={'100%'}
                                showButton
                                onBtnClick={() => setOpenModal('disburse')}
                                btnText="Disburse Payment"
                                isActionable
                                actionType="checkbox"
                                onSelectedItemsChange={(items) => setSelected(items)}
                                isPaginated={true}
                                paginationComponent={
                                    <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                                        <PaginationStatus
                                            currentPage={currentPage}
                                            itemsPerPage={tableSettings?.numOfPages}
                                            totalItems={totalItems}
                                        />
                                        <Pagination
                                            pageRangeDisplayed={3}
                                            totalPages={totalPages}
                                            onPageChange={(nextPage) => {
                                                setCurrentPage(nextPage);
                                            }}
                                            currentPage={currentPage}
                                        />
                                    </FlexRowSpaceBetween>
                                }
                            />
                       
            </div>

   
                <ConfirmActionRadio
                withIcon= {false}
                show={openModal === 'disburse'}
                title="Disburse Payment"
                placeholder="Enter disburse"
                close={() => setOpenModal(null)}
                statuses={modeOfDisbursement}
                confirmAction={handleChangeStatus}
                btn2Text="Apply"
                setTextInputValue={setInputValue}
                selectedStatus={selectedModeOfDisbursement}
                setSelectedStatus={setSelectedModeOfDisbursement}
                textInputvalue={inputValue}
                textInput
                list
                listName={selected?.map(staff => staff?.staffName)}
                listTitle = "Name of Staff"
                listTitle1 ={`${selectedModeOfDisbursement === "Partial Disbursement" ? "Amount" : ""}`}
                listNumber = {selectedModeOfDisbursement === "Partial Disbursement" ? (selected.reduce((sum, staff) => sum + (staff.amount || 0), 0) * percentage)/100 : ""}
                textValue="To confirm, enter disburse in the text field."
                isLoading={isLoading}
                btnDisabled={!(selectedModeOfDisbursement && inputValue === "disburse")}
                percentage= {selectedModeOfDisbursement === "Partial Disbursement"}
                text= "Specify Percentage"
                percentagePlaceholder= "enter percentage"
                percentageInputValue= {percentage} 
                setPercentageInputValue= {setPercentage} 
                length={`${selected.length} names selected`}

            />
        </>
    );
};
export default PayrollScheduleDetails;
