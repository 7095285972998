import React from 'react';


import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-red.svg';
import { Text } from '../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../containers/ScreenContainers';
import { Button, LoadingButton } from '../buttons';
import CentreModal from '../modal/CentreModal';

const DeleteItem = ({ show, close, assignAction, loading }) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white w-[50rem] rounded-[16px] pt-[1rem] pb-[16rem] px-[1.4rem]">
                <FlexRowSpaceBetween className="mb-[1.6rem]">
                    <FlexCentredRow>
                        <DeleteIcon className="mr-[8.15px]" />
                        <Text as="h3" size="1.9rem" weight="600">
                            Delete Item
                        </Text>
                    </FlexCentredRow>
                    <CloseIcon onClick={close} />
                </FlexRowSpaceBetween>
                <Text bottom="4rem">Are you sure you want to delete this permanently?</Text>
                <FlexRowEnd className="gap-x-[16px]">
                    <Button onClick={close} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton loading={loading} onClick={assignAction} bgColor="#6366F1" color="#fff">
                        Yes, delete
                    </LoadingButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default DeleteItem;
