import React, { useEffect, useMemo, useState } from 'react';

import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit3.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send-green.svg';
import { ConfirmActionDialogue } from '../../../components';
import RadioInput from '../../../components/inputs/radio';
import NavTabs, { Tab } from '../../../components/nav-tabs/NavTabs';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAllBulletins, publishCommunication } from '../../../redux/communications/actions';
import { capitalizeEachWord } from '../../../utils';
import { All, Bulletin, InternalMemo, Mail, Notice } from '../components';

const activeClass = 'bg-[#FEE2E2] text-[#EF4444]';
const inActiveClass = 'bg-[#E5E7EB] text-[#1F2937]';

const getBadgeCount = (count) => {
    if (!count && count !== 0) return '0';
    return count > 10 ? `${count}+` : `${count}`;
};

const ComposeCommunications = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((store) => store.communications);
    const { currentUnit } = useSelector((store) => store.organisationUnits);

    const url = window.location.href;
    const [activeTab, setActiveTab] = useState('all');
    const [openModal, setOpenModal] = useState(null);
    const [value, setValue] = useState('');
    const [selectedItem, setSelectedItem] = useState('');
    const [allCommunications, setAllCommunications] = useState({
        all: [],
        bulletins: [],
        internalMemo: [],
        notice: [],
        mail: [],
    });
    const selectedComms = activeTab === 'all' ? selectedItem?.type : activeTab;
    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        const getAllCommunications = async () => {
            const res = await dispatch(getAllBulletins(''));
            setAllCommunications((prev) => ({ ...prev, all: res }));
        };
        const getInternalCommunications = async () => {
            const res = await dispatch(getAllBulletins('Internal memo'));
            setAllCommunications((prev) => ({ ...prev, internalMemo: res }));
        };
        const getBulletinCommunications = async () => {
            const res = await dispatch(getAllBulletins('Bulletin'));
            setAllCommunications((prev) => ({ ...prev, bulletins: res }));
        };
        const getMailCommunications = async () => {
            const res = await dispatch(getAllBulletins('Mail'));
            setAllCommunications((prev) => ({ ...prev, mail: res }));
        };
        const getNoticeCommunications = async () => {
            const res = await dispatch(getAllBulletins('Notice'));
            setAllCommunications((prev) => ({ ...prev, notice: res }));
        };

        getAllCommunications();
        getInternalCommunications();
        getBulletinCommunications();
        getMailCommunications();
        getNoticeCommunications();
    }, [dispatch, refetch]);

    const TableHeaders = [
        {
            header: <RadioInput />,
            size: 5,
            accessorKey: 'action',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Subject',
            accessorKey: 'subject',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Ref No.',
            accessorKey: 'ref',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Session',
            accessorKey: 'session',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Time Stamp',
            accessorKey: 'time',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        ...(activeTab === 'all'
            ? [
                  {
                      header: 'Type',
                      accessorKey: 'type',
                      cell: (props) => <p>{props?.getValue()}</p>,
                  },
              ]
            : []),
        {
            header: 'Publish Status',
            accessorKey: 'commsStatus',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
    ];

    const tabs = useMemo(
        () => [
            {
                label: 'All',
                badge: {
                    color: activeTab === 'all' ? activeClass : inActiveClass,
                    text: getBadgeCount(allCommunications?.all?.length),
                },
            },
            ...(currentUnit?.title?.toLowerCase() === 'registry'
                ? [
                      {
                          label: 'Bulletin',
                          badge: {
                              color: activeTab === 'bulletin' ? activeClass : inActiveClass,
                              text: getBadgeCount(allCommunications?.bulletins?.length),
                          },
                      },
                  ]
                : []),
            {
                label: 'Internal Memo',
                badge: {
                    color: activeTab === 'internal memo' ? activeClass : inActiveClass,
                    text: getBadgeCount(allCommunications?.internalMemo?.length),
                },
            },
            {
                label: 'Notice',
                badge: {
                    color: activeTab === 'notice' ? activeClass : inActiveClass,
                    text: getBadgeCount(allCommunications?.notice?.length),
                },
            },
            ...(!url.includes('staff-arena')
                ? [
                      {
                          label: 'Mail',
                          badge: {
                              color: activeTab === 'mail' ? activeClass : inActiveClass,
                              text: getBadgeCount(allCommunications?.mail?.length),
                          },
                      },
                  ]
                : []),
        ],
        [activeTab, allCommunications, url, currentUnit?.title],
    );

    const handleTabClick = (tabName) => {
        setActiveTab(tabName.toLowerCase());
    };

    const handleSelect = (item) => {
        setSelectedItem(item);
    };

    const handlePublish = async () => {
        if (value !== 'publish') return toast.error("Enter the word 'publish' to publish communications");
        const res = await dispatch(publishCommunication(selectedItem?._id, selectedItem?.type));
        if (res) {
            setSelectedItem(null);
            setOpenModal(null);
            setValue('');
            setRefetch((prev) => !prev);
        }
    };

    return (
        <div>
            <PageLayout
                secondaryButtonText="Compose"
                onSecondaryButtonClick={() => navigate('create')}
                secondaryButtonIcon={<EditIcon />}
                pageTitle="Compose"
                onActionBtnClick={() => {
                    setOpenModal('actions');
                }}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <SendIcon />,
                        name: `Publish ${capitalizeEachWord(selectedComms)}`,
                        click: () => {
                            setOpenModal('publish');
                        },
                    },
                    {
                        icon: <EditIcon />,
                        name: `Edit ${capitalizeEachWord(selectedComms)}`,
                        // click: () => {
                        //     setShowConfrimActionDialogue(true);
                        //     setDropdownOpen(false);
                        // },
                    },
                    { icon: <DeleteIcon />, name: `Delete ${capitalizeEachWord(selectedComms)}` },
                ]}
            />
            <div className="mt-[3.2rem]">
                <NavTabs tabs={tabs} onClick={handleTabClick}>
                    <Tab.Panel>
                        <All
                            tableData={allCommunications?.all}
                            tableHeader={TableHeaders}
                            selectedItem={selectedItem}
                            handleSelect={handleSelect}
                        />
                    </Tab.Panel>
                    <Tab.Panel>
                        <Bulletin
                            tableData={allCommunications?.bulletins}
                            tableHeader={TableHeaders}
                            selectedItem={selectedItem}
                            handleSelect={handleSelect}
                        />
                    </Tab.Panel>
                    <Tab.Panel>
                        <InternalMemo
                            tableData={allCommunications?.internalMemo}
                            tableHeader={TableHeaders}
                            selectedItem={selectedItem}
                            handleSelect={handleSelect}
                        />
                    </Tab.Panel>
                    <Tab.Panel>
                        <Notice
                            tableData={allCommunications?.notice}
                            tableHeader={TableHeaders}
                            selectedItem={selectedItem}
                            handleSelect={handleSelect}
                        />
                    </Tab.Panel>
                    {!url.includes('staff-arena') && (
                        <Tab.Panel>
                            <Mail tableData={allCommunications?.mail} tableHeader={TableHeaders} />
                        </Tab.Panel>
                    )}
                </NavTabs>

                <ConfirmActionDialogue
                    show={openModal === 'publish'}
                    close={() => setOpenModal(null)}
                    svgIcon={<SendIcon />}
                    title={`Publish ${capitalizeEachWord(activeTab)}`}
                    subtitle={`This ${activeTab.toLocaleLowerCase()} will be published and sent to the distribution list(s).`}
                    bodyText={`To confirm, enter publish in the text field.`}
                    noStyle
                    input
                    placeholder="Publish"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    btn2Text="Yes, Publish"
                    bgColor="#10B981"
                    isLoading={isLoading}
                    confirmAction={handlePublish}
                    btnDisabled={value !== 'publish'}
                />
            </div>
        </div>
    );
};

export default ComposeCommunications;
