import React, { useState } from 'react';

import tw from 'twin.macro';

import { ReactComponent as Schedule } from '../../../assets/icons/schedule.svg';
import ScheduleSend from '../../forms/schedule-send';

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#6366F1]
  mb-[0]
`;

const FormHeader = tw.div`
    flex
    gap-[0.715rem]
    items-center
    cursor-pointer
`;
const Container = tw.div`
    bg-white
    w-[23.3rem]
    px-[1.6rem]
    py-[1.2rem]
    rounded-lg
    relative
    
`;

const ScheduleCard = ({ show }) => {
    const [showScheduleSend, setShowScheduleSend] = useState(false);

    return (
        <>
            <div className="absolute bottom-[15rem] right-[25rem] z-[40] shadow-[0px_25px_50px_-12px_rgba(6,25,56,0.25)]">
                <Container show={show}>
                    <FormHeader onClick={() => setShowScheduleSend((previousState) => !previousState)}>
                        <Schedule />
                        <Title>Schedule Send</Title>
                    </FormHeader>

                    {showScheduleSend && <ScheduleSend show={showScheduleSend} setShow={setShowScheduleSend} />}
                </Container>
            </div>
        </>
    );
};

export default ScheduleCard;
