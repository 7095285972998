import { axiosInstance, currentAPI } from '../../config';
import handleApiError from '../handleApiError';

import { GET_EXEAT, GET_EXEAT_BY_ID, LOADING, STOP_LOADING } from './slice';

export const approveExeat = (exeatId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}exeat/:campusId/:unitId/approve/${exeatId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const rejectExeat = (exeatId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}exeat/:campusId/:unitId/reject/${exeatId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllExeatRequest = (session, level, program) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const unitName = sessionStorage.getItem('unitName');

        const response = await axiosInstance.get(
            `${currentAPI}exeat/:unitId?session=${session}&level=${level}&program=${program}&unitName=${unitName}`,
        );
        dispatch(GET_EXEAT(response.data.data));
        return true;
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        return errorMessage;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getExeatById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const unitName = sessionStorage.getItem('unitName');

        const response = await axiosInstance.get(`${currentAPI}exeat/:campusId/:unitId/${id}?unitName=${unitName}`);
        dispatch(GET_EXEAT_BY_ID(response.data.data));
        return true;
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
