import React from 'react';

import { TextInput } from '@belrald_hq/belrald-ui';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import CentreModal from '../../../../components/modal/CentreModal';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';

const RevokeOffer = ({
    show,
    close,
    list,
    onSend,
    nameField = 'fullName',
    idField = 'id',
    isLoading,
    value,
    setInputValue,
}) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[45.8rem] max-h-[60rem] mx-auto">
                <FlexRowSpaceBetween>
                    <Text weight="600" size="1.9rem" color="#EF4444">
                        Revoke Offer
                    </Text>
                    <CloseIcon className="hover:cursor-pointer" onClick={close} />
                </FlexRowSpaceBetween>

                <Text align="start" size="1.6rem" color="#374151" top="1.6rem" lineHeight="2.4rem" bottom="1rem">
                    Are you sure you want to revoke the invite sent to these person(s)?
                </Text>

                <ul className="overflow-y-auto border-b pb-2 mt-5">
                    {list?.map((item, index) => (
                        <Text align="left" key={index}>{`${item?.[nameField]}`}</Text>
                    ))}
                </ul>
                <hr />
                <Text color="#6B7280" size="1.2rem" align="left">
                    {list?.length} name(s) selected
                </Text>

                <Text align="left" weight="400" size="1.6rem" bottom="1rem">
                    To confirm, enter <strong>revoke</strong> in the text field
                </Text>

                <TextInput
                    type="text"
                    placeholder={'Type Here'}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={value}
                    required
                />
                <FlexRowEnd className="gap-x-[16px] mt-[1rem]">
                    <Button type="button" onClick={close} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton bgColor="#EF4444" disabled={value !== 'revoke'} loading={isLoading} onClick={onSend}>
                        Revoke
                    </LoadingButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default RevokeOffer;
