import { axiosInstance, currentAPI } from '../../config';
import { stringifyJSON } from '../../utils/useJSON';
import { getOrganizationProfile } from '../campus-management/actions';
import handleApiError from '../handleApiError';
import { SAVE_STAFF, SAVE_USER } from '../user/slice';

import { LOADING, STOP_LOADING } from './slice';

export const registerUser = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}admin/admin_onboarding`, payload);

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const loginUser = (payload, navigate) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}admin/login`, payload);
        if (response.status === 200) {
            const { accessToken, data } = response.data;

            const { userType, organizationRoleType, campusId } = data;
            const isAdmin = userType === 'admin';
            const firstCampusId = campusId?.[0] || '';

            const action = isAdmin ? SAVE_USER : SAVE_STAFF;
            await dispatch(action(data));

            await stringifyJSON('token', accessToken);
            dispatch(getOrganizationProfile());

            if (organizationRoleType === 'owner' || (isAdmin && !firstCampusId)) {
                navigate('/campus-management/campuses');
            } else {
                sessionStorage.setItem('campusId', firstCampusId);
                navigate('/dashboard');
            }
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
// export const loginStaff = (payload, navigate) => async (dispatch, getState) => {
//     dispatch(LOADING());
//     try {
//         const response = await axiosInstance.post(`${currentAPI}admin/login`, payload);
//         if (response.status === 200) {
//             const { accessToken, data } = response.data;

//             await stringifyJSON('token', accessToken);
//             await dispatch(SAVE_STAFF(data));
//             sessionStorage.setItem('campusId', data?.campusId[0]);
//             dispatch(getOrganizationProfile());
//             navigate('/dashboard');
//         }
//     } catch (error) {
//         handleApiError(error);
//     } finally {
//         dispatch(STOP_LOADING());
//     }
// };

export const createPassword = (token, payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}admin/create_password?token=${token}`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createStaffPassword = (email, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff/create-password?email=${email}`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const forgetPassword = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}admin/forgot_password`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const resetPassword = (token, payload, userType) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}admin/reset_password/${token}?userType=${userType}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const scheduleDemo = (payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}admin/schedule_demo`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
