import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI, commsAPI } from '../../config';
import handleApiError from '../handleApiError';

import {
    GET_ALL_BULLETINS,
    GET_ALL_CATEGRORIES,
    GET_ALL_DRAFT,
    GET_ALL_EXTERNAL_MEMOS,
    GET_ALL_INTERNAL_MEMOS,
    GET_ALL_MAILS,
    GET_BULLETIN,
    GET_COMMS_SETTINGS,
    GET_EXTERNAL_MEMO,
    GET_INTERNAL_MEMO,
    GET_MAIL,
    GET_NOTICE,
    GET_RECEIVED_NOTICE,
    GET_SENT_BULLETIN,
    GET_SENT_EXTERNAL_MEMOS,
    GET_SENT_INTERNAL_MEMO,
    GET_SENT_MAIL,
    GET_SENT_NOTICE,
    LOADING,
    STOP_LOADING,
} from './slice';

export const getAllReceived = (paramName, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}communication/received?${param}=${paramName}`);
        if (response.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllSent = (paramName, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}communication/sent?${param}=${paramName}`);
        if (response.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllDraft = (unitName, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}communication/draft?${param}=${unitName}`);
        if (response.status === 200) {
            dispatch(GET_ALL_DRAFT(response.data.payload));
            return response.data.payload;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

// EXTERNAL MEMO
export const sendExternalMemo = (deptName, formData, headers, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}external-memo?${param}=${deptName}`, formData, headers);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'External Memo sent Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendDraftExternalMemo = (deptName, formData, headers, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${commsAPI}external-memo/draft?${param}=${deptName}`,
            formData,
            headers,
        );
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'External Memo sent Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllExternalMemo = (deptName, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}external-memo?${param}=${deptName}`);
        if (response.status === 200) {
            dispatch(GET_ALL_EXTERNAL_MEMOS(response.data.payload));
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getSentExternalMemos = (unitName, params) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}external-memo/memos/sent?${params}=${unitName}`);
        dispatch(GET_SENT_EXTERNAL_MEMOS(response.data.payload));
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getExternalMemobyId = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}external-memo/${id}`);
        if (response.status === 200) {
            dispatch(GET_EXTERNAL_MEMO(response.data.payload));
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

// INTERNAL MEMO
export const sendInternalMemo = (paramName, formData, headers, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}internal-memo?${param}=${paramName}`, formData, headers);
        const successMessage = response?.data?.message || 'External Memo sent Successfully!';
        if (response.status === 201) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendDraftInternalMemo = (paramName, formData, headers, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${commsAPI}internal-memo/draft?${param}=${paramName}`,
            formData,
            headers,
        );
        const successMessage = response?.data?.message || 'External Memo sent Successfully!';
        if (response.status === 201) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllInternalMemo = (deptName, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}internal-memo?${param}=${deptName}`);
        if (response.status === 200) {
            dispatch(GET_ALL_INTERNAL_MEMOS(response.data.payload));
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSentInternalMemo = (unitName, params) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}internal-memo/memos/sent?${params}=${unitName}`);
        dispatch(GET_SENT_INTERNAL_MEMO(response.data.payload));
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getInternalMemo = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}internal-memo/${id}`);
        if (response.status === 200) {
            dispatch(GET_INTERNAL_MEMO(response.data.payload));
            return response.data.payload;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

// NOTICE
export const sendDraftNotice = (paramName, formData, headers, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}notice/draft?${param}=${paramName}`, formData, headers);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Notice sent Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendNotice = (paramName, formData, headers, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}notice?${param}=${paramName}`, formData, headers);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Notice sent Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllNotice = (deptName, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}notice?${param}=${deptName}`);
        if (response.status === 200) {
            dispatch(GET_RECEIVED_NOTICE(response.data.payload));
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getNotice = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}notice/${id}`);
        if (response.status === 200) {
            dispatch(GET_NOTICE(response.data.payload));
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSentNotice = (unitName, params) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}notice/notices/sent?${params}=${unitName}`);
        dispatch(GET_SENT_NOTICE(response.data.payload));
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

// MAILS
export const sendMail = (paramName, formData, headers, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}mail?${param}=${paramName}`, formData, headers);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Mail sent Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendDraftMail = (paramName, formData, headers, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}mail/draft?${param}=${paramName}`, formData, headers);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Mail sent Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllMails = (deptName, param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}mail?${param}=${deptName}`);
        if (response.status === 200) {
            dispatch(GET_ALL_MAILS(response.data.payload));
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getMail = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}mail/${id}`);
        if (response.status === 200) {
            dispatch(GET_MAIL(response.data.payload));
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSentMail = (unitName, params) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}mail/mails/sent?${params}=${unitName}`);
        dispatch(GET_SENT_MAIL(response.data.payload));
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendBulletin =
    ({ formData, headers }) =>
    async (dispatch) => {
        dispatch(LOADING());
        const altUnitName = sessionStorage.getItem('unitName');

        try {
            const response = await axiosInstance.post(
                `${commsAPI}bulletins?unitName=${altUnitName}`,
                formData,
                headers,
            );
            const successMessage = response?.data?.message || 'Bulletin sent Successfully!';
            if (response.status === 201) {
                toast.success(successMessage);
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const sendDraftBulletin =
    ({ formData, headers }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.post(`${commsAPI}bulletin/draft`, formData, headers);
            const successMessage = response?.data?.message || 'Draft saved Successfully!';
            if (response.status === 201) {
                toast.success(successMessage);
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getAllBulletins = (type) => async (dispatch, getState) => {
    dispatch(LOADING());
    const unitName = sessionStorage.getItem('unitName');

    try {
        const response = await axiosInstance.get(`${commsAPI}getBulletins?type=${type}&unitName=${unitName}`);
        dispatch(GET_ALL_BULLETINS(response.data.data));
        return response.data.data;
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getBulletinsById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}bulletin/${id}`);
        if (response.status === 200) {
            dispatch(GET_BULLETIN(response.data.payload));
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getSentBulletins = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}bulletin/bulletins/sent?unitName=Registry`);
        dispatch(GET_SENT_BULLETIN(response.data.payload));
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getCategories = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}communication/:campusId/:unitId/category`);
        dispatch(GET_ALL_CATEGRORIES(response.data.data));
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendCommunicationsSettings = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}Setting`, payload);
        const successMessage = response?.data?.message || 'Settings saved successfully!';
        if (response.status === 201) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getCommunicationsSettings = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const unitName = sessionStorage.getItem('unitName');

        const response = await axiosInstance.get(`${commsAPI}getSettings?unitName=${unitName}`);
        if (response.status === 200) {
            GET_COMMS_SETTINGS(response?.data?.data);
            return response?.data?.data;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getReviewers = (id, type) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}getReviewers/${id}?type=${type}`);
        if (response.status === 200) {
            return response?.data?.staffs;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getCommsById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}getCommunicationById/${id}`);
        if (response.status === 200) {
            return response?.data?.data;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const reviewCommunications = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${commsAPI}reviewBulletin/${id}`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getReviewsComments = (id, type) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}getReview/${id}?type=${type}`);
        if (response.status === 200) {
            return response?.data?.staffs;
        }
    } catch (error) {
        // handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const publishCommunication = (id, type) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const unitName = sessionStorage.getItem('unitName');

        const response = await axiosInstance.put(
            `${commsAPI}publishCommunication/${id}?type=${type}&unitName=${unitName}`,
        );
        const successMessage = response?.data?.message || 'Communications Published Successfully';
        if (response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
