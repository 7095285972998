import React, { useEffect, useState } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import CustomTable from '../../../components/CustomTable';
import ConfirmActionDialogueWithInput from '../../../components/popups/confirm-action-with-input';
import { StatusContainer, getStatusStyles } from '../../../components/shared';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { useTableContext } from '../../../context/tableContext';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { copyPayrollSchedule, deletePayrollSchedule, getPaymentSchedule } from '../../../redux/bursary/actions';
import { formatMonthAndYear, formatToDateTime } from '../../../utils/formatDate';

const PayrollSchedule = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, payrollSchedule } = useSelector((state) => state.bursary);

    const [searchValue, setSearchValue] = useState('');

    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [openModal, setOpenModal] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const { data: allpaymentSchedule, pagination } = payrollSchedule || {};
    const { total, totalPages } = pagination || {};

    const [currentPage, setCurrentPage] = useState('1');
    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;

    const fields = [
        {
            headerName: 'Payroll Name',
            field: 'payrollName',
            cellRendererRenderer: (props) => {
                const { _id: id, payrollName } = props.data;
                return (
                    <Link
                        to={`/bursary/payroll/payroll-schedule/${id}`}
                        state={{
                            payrollDetails: props.row.original,
                            payrollName,
                            id,
                        }}
                        style={{ color: '#2563EB', cursor: 'pointer' }}
                    >
                        {props?.getValue()}
                    </Link>
                );
            },
        },

        {
            headerName: 'Created At',
            field: 'createdAt',
            cellRenderer: ({ data }) => {
                return <p>{formatToDateTime(data?.createdAt)}</p>;
            },
        },
        {
            headerName: 'Month & Year',
            field: 'monthAndYear',
            cellRenderer: ({ data }) => formatMonthAndYear(data?.monthAndYear),
        },
        {
            headerName: 'Disbursement Status',
            field: 'status',
            cellRenderer: (props) => (
                <StatusContainer style={getStatusStyles(props.value?.toLowerCase())}>
                    <span>{props.value}</span>
                </StatusContainer>
            ),
        },

        {
            headerName: 'Last Updated At',
            field: 'updatedAt',
            cellRenderer: ({ data }) => {
                return <p>{formatToDateTime(data?.updatedAt)}</p>;
            },
        },
        {
            headerName: 'No of Staff to be paid',
            field: 'staffIds.length',
        },
        {
            headerName: 'Amount to be paid',
            field: 'amount',
        },

        {
            headerName: 'Payment Gateway',
            field: 'paymentGateway.provider',
        },
    ];

    const handleDelete = async () => {
        if (inputValue.toLowerCase() !== 'delete') {
            toast.error('Input delete');
        } else {
            const res = await dispatch(deletePayrollSchedule(selectedItem?._id));
            if (res) {
                setToggleRefetch((prevState) => !prevState);
                setOpenModal(false);
            }
        }
    };

    const makeCopy = async () => {
        if (!selectedItem) {
            toast.error('Select a payroll');
        } else {
            const res = await dispatch(copyPayrollSchedule(selectedItem?._id));
            if (res) {
                setToggleRefetch((prevState) => !prevState);
            }
        }
    };

    useEffect(() => {
        const query = {
            search: debouncedSearchValue,
            page: currentPage,
            limit: numOfPages,
        };
        dispatch(getPaymentSchedule(query));
    }, [dispatch, toggleRefetch, debouncedSearchValue, currentPage, numOfPages]);

    return (
        <>
            <PageLayout
                pageTitle="Payment Schedule"
                secondaryButtonText="Create Payroll"
                customTable={
                    <CustomTable
                        colDefs={fields}
                        data={allpaymentSchedule || []}
                        rowSelection
                        singleRowSelection
                        setSelectedItem={setSelectedItem}
                    />
                }
                onSecondaryButtonClick={() => navigate('/bursary/payroll/payroll-schedule/create-payroll')}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <EditIcon />,
                        name: 'Edit Payroll',
                        disabled: !selectedItem,
                        click: () => navigate(`/bursary/payroll/payroll-schedule/edit-payroll/${selectedItem?._id}`),
                    },
                    {
                        icon: <CopyIcon />,
                        name: 'Make a copy',
                        disabled: !selectedItem,
                        click: () => makeCopy(),
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Payroll',
                        disabled: !selectedItem,
                        click: () => setOpenModal('delete'),
                    },
                ]}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                searchValue={searchValue}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                showFilter
                width={'100%'}
                paginationComponent={
                    <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                        <PaginationStatus
                            currentPage={currentPage}
                            itemsPerPage={tableSettings?.numOfPages}
                            totalItems={total}
                        />
                        <Pagination
                            pageRangeDisplayed={3}
                            totalPages={totalPages}
                            onPageChange={(nextPage) => {
                                setCurrentPage(nextPage);
                            }}
                            currentPage={currentPage}
                        />
                    </FlexRowSpaceBetween>
                }
                openFilterPop={openModal === 'options-popup-main'}
                closeFilterPop={() => setOpenModal(null)}
            />
            <ConfirmActionDialogueWithInput
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete payroll schedule?"
                subtitle={`"${selectedItem?.payrollName}" will be removed permanently..`}
                subtitle2="To confirm, enter delete in the text field.."
                confirmAction={() => handleDelete()}
                btn2Text="Yes, Delete"
                setInputValue={setInputValue}
                value={inputValue}
                isLoading={isLoading}
                btnDisabled={inputValue !== 'delete'}
                placeholder="Input delete"
            />
        </>
    );
};

export default PayrollSchedule;
