import * as Yup from 'yup';

const courseCodeRegex = /^[A-Z]{3}\s\d{3}(\/\d{3})?[A-Z]?$/;

export const ValidationSchema = Yup.object().shape({
    courseTitle: Yup.string().nullable().required('Required'),
    courseCode: Yup.string()
        .required('Required')
        .matches(
            courseCodeRegex,
            'Invalid format. Format should match any of: GNS 101, MAT 101/102, ECN 104A, PHY 104/105B',
        ),
    courseUnit: Yup.string().nullable().required('Required'),
    courseLevel: Yup.string().nullable().required('Required'),
    courseSemester: Yup.string().nullable().required('Required'),
    minimumPassScore: Yup.string().nullable().required('Required'),
});
