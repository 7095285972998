import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LoadingButton } from '../../../components/buttons';
import InputContainer from '../../../components/Container/inputcontainer';
import FormikTextInput from '../../../components/inputs/text-input-with-formik';
import { PoweredBy } from '../../../components/powered-by';
import { FlexRowEnd } from '../../../containers/ScreenContainers';
import { createStaffPassword } from '../../../redux/auth/actions';
import { AuthContainer } from '../confirm-password';

import { ValidationSchema } from './ValidationSchema';

const StaffCreatePassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    // const token = params.get('token');
    const email = params.get('email').replace(' ', '+');

    const isLoading = useSelector((state) => state.auth.isLoading);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    return (
        <AuthContainer>
            <InputContainer>
                <Formik
                    initialValues={{
                        email: email || '',
                        password: '',
                        confirmPassword: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values) => {
                        const emailValue = values.email.toLowerCase();

                        const payload = { ...values, email: emailValue };
                        const res = await dispatch(createStaffPassword(emailValue, payload));
                        if (res) {
                            toast.success('Password successfully created!');
                            navigate('/sign-in-staff');
                        }
                    }}
                >
                    {({ errors, handleChange, values }) => (
                        <Form>
                            <div className="w-[60rem] mt-[32px] md:w-[70rem] max-h-[80vh] h-auto bg-white mx-auto p-[4rem] md:p-[5rem] rounded-3xl shadow-lg">
                                <h3 className="font-semibold text-[#1F2937] text-[19px]">Welcome!</h3>
                                <p className="text-[#9CA3AF] text-[14px] mb-8 mt-2">
                                    Enter your email and create a password to log in
                                </p>

                                <div className="grid gap-6 mb-4">
                                    <FormikTextInput
                                        name="email"
                                        type="text"
                                        placeholder="Enter Email Address"
                                        errors={errors}
                                        onChange={handleChange}
                                        value={values.email}
                                        disabled
                                    />

                                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 w-full">
                                        <div className="w-full">
                                            <FormikTextInput
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                inputType="password"
                                                placeholder="Enter Password"
                                                errors={errors}
                                                onChange={handleChange}
                                                togglePassword={() => setShowPassword((prevState) => !prevState)}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <FormikTextInput
                                                name="confirmPassword"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                inputType="password"
                                                placeholder="Confirm Password"
                                                errors={errors}
                                                onChange={handleChange}
                                                togglePassword={() => setShowConfirmPassword((prevState) => !prevState)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <FlexRowEnd>
                                    <LoadingButton loading={isLoading} bgColor="#6366F1" color="#ffffff" type="submit">
                                        Create Password
                                    </LoadingButton>
                                </FlexRowEnd>
                            </div>
                        </Form>
                    )}
                </Formik>
                <PoweredBy />
            </InputContainer>
        </AuthContainer>
    );
};

export default StaffCreatePassword;
