import React from 'react';

import CustomTable from '../../../../../../components/CustomTable';
import PageLayout from '../../../../../../layout/page-layout/PageLayout';

const fields = [
    {
        headerName: 'Pay Date',
        field: '',
    },
    {
        headerName: 'Gross Salary',
        field: '',
    },
    {
        headerName: 'Net Salary',
        field: '',
    },
    {
        headerName: 'Status',
        field: '',
    },
];

const PayrollHistory = ({ salaryHistory, searchValue, setSearchValue, isLoading }) => {
    return (
        <div className="mt-3">
            <PageLayout
                noMargin
                // fields={fields}
                // data={salaryHistory || []}
                customTable={<CustomTable colDefs={fields} data={salaryHistory || []} />}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                // loading={isLoading}
            />
        </div>
    );
};

export default PayrollHistory;
