import React from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';
import CentreModal from '../../modal/CentreModal';

const ConfirmActionDialogue = ({
    children,
    show = false,
    close,
    svgIcon,
    title,
    subtitle,
    bodyText,
    confirmAction,
    btn2Text,
    input,
    placeholder,
    borderLine,
    bgColor,
    value,
    onChange,
    isLoading,
    withIcon = true,
    noStyle,
    type,
    btnDisabled,
    subtitleElement,
    list,
    listNumber,
    listName,
    listCount,
    count,
    withClose = true,
    withCancelBtn = true,
}) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[50rem]">
                <FlexRowSpaceBetween>
                    <FlexCentredRow className="gap-[1.215rem]">
                        {withIcon ? svgIcon ? svgIcon : <WarningIcon fill="#EF4444" /> : null}
                        <Text as="h3" size="1.9rem" weight="600">
                            {title}
                        </Text>
                    </FlexCentredRow>
                    {withClose && <CloseIcon onClick={close} className="cursor-pointer" />}
                </FlexRowSpaceBetween>
                {subtitleElement ? (
                    subtitleElement
                ) : (
                    <Text align="left" size="1.6rem" bottom="1.6rem" color="#374151" top="1.6rem">
                        {subtitle}
                    </Text>
                )}
                {list && (
                    <FlexCentredRow className="gap-[4rem]">
                        <Text align="left" size="1.4rem" bottom="1.6rem" color="#374151" top="1.6rem">
                            {listName}
                        </Text>
                        <Text align="left" size="1.4rem" bottom="1.6rem" color="#374151" top="1.6rem">
                            {listNumber}
                        </Text>
                    </FlexCentredRow>
                )}
                {borderLine && <hr className="mt-[1rem]" />}
                {count && (
                    <Text align="left" size="1.4rem" bottom="1.6rem" color="#374151" top="1.6rem" lineHeight="1.7rem">
                        {`${listCount} name selected`}
                    </Text>
                )}
                {bodyText && (
                    <Text
                        align="left"
                        size="1.6rem"
                        color="#374151"
                        top="1.6rem"
                        bottom="3.2rem"
                        fontStyle={noStyle ? '' : 'italic'}
                    >
                        {bodyText}
                    </Text>
                )}
                {children}
                {input && (
                    <input
                        type={type || 'text'}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        className="border-[1px] border-[#E5E7EB] pl-3 rounded outline-none focus:ring-[#6366F1] focus:ring-2 placeholder:text-[#6B7280] placeholder:text-[1.4rem] leading-6 font-normal text-[1.4rem] w-full h-[4rem]"
                    />
                )}
                <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                    {withCancelBtn && (
                        <Button onClick={close} disabled={isLoading} border="0.5px solid #D1D5DB">
                            Cancel
                        </Button>
                    )}
                    <LoadingButton
                        loading={isLoading}
                        disabled={btnDisabled || isLoading}
                        onClick={confirmAction}
                        bgColor={bgColor || '#EF4444'}
                        color="#fff"
                    >
                        {btn2Text || 'Confirm'}
                    </LoadingButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default ConfirmActionDialogue;
