import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { LoadingButton } from '../../../../components/buttons';
import TextInput from '../../../../components/inputs/text-input-with-formik';
import CentreModal from '../../../../components/modal/CentreModal';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { changePassword } from '../../../../redux/staff-arena/actions';

import { ValidationSchema } from './validationSchema';

const ChangePassword = ({ close, show }) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.staffArena);

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);

    return (
        <>
            <Formik
                initialValues={{
                    oldpassword: '',
                    password: '',
                    confirmPassword: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    const res = await dispatch(changePassword(values));
                    if (res) {
                        toast.success('Password changed successfully!');
                        close();
                    }
                }}
            >
                {({ errors, handleChange }) => (
                    <CentreModal isOpen={show} onClose={() => close?.()}>
                        <div className="bg-white rounded-[0.8rem] pb-[4.4rem] w-[32rem] md:w-[45rem] h-auto mx-auto p-[2rem] md:p-[2.4rem] relative">
                            <Form>
                                <FlexRowSpaceBetween className="mb-2">
                                    <Text size="1.9rem" weight="600" align="left">
                                        PREFERENCE
                                    </Text>
                                    <CloseIcon className="cursor-pointer" onClick={close} />
                                </FlexRowSpaceBetween>
                                <hr />

                                <div className="grid grid-row-6 gap-6 mt-[2rem]">
                                    <TextInput
                                        name="oldpassword"
                                        type={showOldPassword ? 'text' : 'password'}
                                        inputType="password"
                                        placeholder="Old Password"
                                        label="Old Password"
                                        errors={errors}
                                        onChange={handleChange}
                                        togglePassword={() => setShowOldPassword((prevState) => !prevState)}
                                    />
                                    <TextInput
                                        name="password"
                                        type={showNewPassword ? 'text' : 'password'}
                                        inputType="password"
                                        label="New password"
                                        placeholder="Enter New Password"
                                        errors={errors}
                                        onChange={handleChange}
                                        togglePassword={() => setShowNewPassword((prevState) => !prevState)}
                                    />
                                    <TextInput
                                        name="confirmPassword"
                                        type={showRepeatPassword ? 'text' : 'password'}
                                        inputType="password"
                                        placeholder="Repeat New Password"
                                        label="Repeat Password"
                                        errors={errors}
                                        onChange={handleChange}
                                        togglePassword={() => setShowRepeatPassword((prevState) => !prevState)}
                                    />

                                    <Text align="left" color="#6B7280" size="1.4rem" weight="400" lineHeight="2.4rem">
                                        Use a password at least 8 characters long with both letters and numbers.
                                    </Text>
                                </div>

                                <FlexRowEnd className="mt-6">
                                    <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
                                        Change Password
                                    </LoadingButton>
                                </FlexRowEnd>
                            </Form>
                        </div>
                    </CentreModal>
                )}
            </Formik>
        </>
    );
};

export default ChangePassword;
