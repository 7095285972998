import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as ChevronGrey } from '../../../assets/icons/arrow-down2.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus-blue.svg';
import { Timetable, CalenderToggle, CalenderDoc, ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import {
    FlexRowSpaceBetween,
    FlexCentredRow,
    FlexRowEnd,
    RelativeContainer,
} from '../../../containers/ScreenContainers';
import usePersistedState from '../../../hooks/usePersistedState';
import { getActiveSession, getAdmissionSessions } from '../../../redux/admissions/actions';
import {
    // getAllLectureTimetable,
    getFridayTimetable,
    getLectureTimetableInFirstDegree,
    getMondayTimetable,
    getSaturdayTimetable,
    getSundayTimetable,
    getThursdayTimetable,
    getTuesdayTimetable,
    getWednesdayTimetable,
} from '../../../redux/timetable/actions';
import { localStoreKeys } from '../../../utils/localStore';
// import { getUniqueSortedYearsFromRanges } from '../../../utils';

const MainContainer = tw.div`
    h-auto
    bg-white
    mx-auto
    p-[2.4rem]
    md:p-[5.5rem]
    rounded-lg
    overflow-y-auto
`;

const ViewBox = tw.div`
    cursor-pointer
    flex
    items-center
    gap-[1rem]
    border-[1px]
    rounded-[4px]
    border-[#6366F1]
    px-[2rem]
    py-[.5rem]
`;

const semesters = ['First Semester', 'Second Semester'];

const LectureTimetable = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = window.location.search;
    const view = params?.split('=')[1];

    const {
        firstDegreeTimetable,
        mondayTimetable,
        tuesdayTimetable,
        wednesdayTimetable,
        thursdayTimetable,
        fridayTimetable,
        saturdayTimetable,
        sundayTimetable,
    } = useSelector((store) => store.timetable);
    const { sessions: admissionsSessions, activeSession, activeSemester } = useSelector((state) => state.admission);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const { academicStructureId, _id: firstDegreeId } = currentFaculty || {};

    const [viewActions, setViewActions] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [session, setSession] = usePersistedState(activeSession || '', localStoreKeys.SelectedSession);
    const [semester, setSemester] = usePersistedState(activeSemester || 'First Semester', localStoreKeys.LongSemester);
    const [refetchTimetable, setRefetchTimetable] = useState(false);

    let allEvents = [];

    const generateWeeklyEvents = (data, weekCount = 13) => {
        const events = [];
        const startDate = new Date(data.startTime);
        const endDate = new Date(data.endTime);
        const startHours = startDate.getUTCHours();
        const startMinutes = startDate.getUTCMinutes();
        const endHours = endDate.getUTCHours();
        const endMinutes = endDate.getUTCMinutes();

        const weekdayNumber = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(
            data.weekday.toLowerCase(),
        );

        const currentDate = new Date(startDate);
        for (let i = 0; i < weekCount; i++) {
            const weekday = currentDate.getDay();
            const daysUntilWeekday = (weekdayNumber + 7 - weekday) % 7;
            const date = new Date(currentDate.getTime());
            date.setDate(date.getDate() + daysUntilWeekday);
            if (date) {
                const startTime = new Date(date);
                startTime.setHours(startHours, startMinutes, 0);
                const endTime = new Date(date);
                endTime.setHours(endHours, endMinutes, 0);
                const event = {
                    title: `${data.course} - ${data.venue}`,
                    start: new Date(startTime),
                    end: new Date(endTime),
                };
                events.push(event);
            }
            currentDate.setDate(currentDate.getDate() + 7);
        }

        return events;
    };
    const filteredFirstDegreeData = useMemo(
        () => firstDegreeTimetable?.filter((item) => item.session === session && item.semester === semester) || [],
        [firstDegreeTimetable, session, semester],
    );

    filteredFirstDegreeData?.forEach((event) => {
        const events = generateWeeklyEvents(event);
        allEvents = [...allEvents, ...events];
    });

    const handleDropdown = (action) => {
        if (action === showDropdown) {
            return setShowDropdown(null);
        }
        setShowDropdown(action);
    };

    const documentItems = [
        { dayOfTheWeek: 'Sunday', data: sundayTimetable },
        { dayOfTheWeek: 'Monday', data: mondayTimetable },
        { dayOfTheWeek: 'Tuesday', data: tuesdayTimetable },
        { dayOfTheWeek: 'Wednesday', data: wednesdayTimetable },
        { dayOfTheWeek: 'Thursday', data: thursdayTimetable },
        { dayOfTheWeek: 'Friday', data: fridayTimetable },
        { dayOfTheWeek: 'Saturday', data: saturdayTimetable },
    ];

    useEffect(() => {
        dispatch(getActiveSession());
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    useEffect(() => {
        if (session && semester) {
            dispatch(
                getLectureTimetableInFirstDegree(
                    academicStructureId,
                    firstDegreeId,
                    session,
                    semester === 'Second Semester' ? '2' : '1',
                ),
            );
        }
    }, [dispatch, academicStructureId, firstDegreeId, session, semester]);

    useEffect(() => {
        if (view === 'document') {
            dispatch(getMondayTimetable(academicStructureId, firstDegreeId));
            dispatch(getTuesdayTimetable(academicStructureId, firstDegreeId));
            dispatch(getWednesdayTimetable(academicStructureId, firstDegreeId));
            dispatch(getThursdayTimetable(academicStructureId, firstDegreeId));
            dispatch(getFridayTimetable(academicStructureId, firstDegreeId));
            dispatch(getSaturdayTimetable(academicStructureId, firstDegreeId));
            dispatch(getSundayTimetable(academicStructureId, firstDegreeId));
        }
    }, [firstDegreeId, academicStructureId, dispatch, view, refetchTimetable]);

    return (
        <div onClick={viewActions ? () => setViewActions(false) : null}>
            <FlexRowSpaceBetween className="mb-[2.4rem]">
                <PageTitle align="left">Lecture Time-table</PageTitle>
                <FlexRowEnd className="gap-[2rem] relative">
                    <Button bgColor="#FFF" color="#6366F1" onClick={() => navigate('create')}>
                        <Plus />
                        Create New Schedule
                    </Button>
                    <ViewBox onClick={() => setViewActions(!viewActions)}>
                        <Text weight="500" size="1.4rem" color="#6366F1">
                            View
                        </Text>
                        <ArrowDown />
                    </ViewBox>
                    {viewActions && (
                        <CalenderToggle
                            onDocumentClick={() => {
                                navigate('?view=document');
                            }}
                            onCalenderClick={() => {
                                navigate('');
                            }}
                        />
                    )}
                </FlexRowEnd>
            </FlexRowSpaceBetween>

            <MainContainer>
                <FlexCentredRow className="gap-[2rem]">
                    <RelativeContainer>
                        <Button
                            bgColor="#FFF"
                            color="#6B7280"
                            border="0.5px solid #9CA3AF"
                            onClick={() => handleDropdown('session')}
                        >
                            {session || 'Select Session'}
                            <ChevronGrey />
                        </Button>
                        <ActionsPopup
                            open={showDropdown === 'session'}
                            close={() => setShowDropdown(null)}
                            items={admissionsSessions?.map((item) => ({
                                name: item.admissionYear,
                                click: () => {
                                    setSession(item.admissionYear);
                                    setShowDropdown(null);
                                },
                            }))}
                        />
                    </RelativeContainer>

                    <RelativeContainer>
                        <Button
                            bgColor="#FFF"
                            color="#6B7280"
                            border="0.5px solid #9CA3AF"
                            onClick={() => handleDropdown('semester')}
                        >
                            {semester || 'Select Semester'}
                            <ChevronGrey />
                        </Button>
                        <ActionsPopup
                            open={showDropdown === 'semester'}
                            close={() => setShowDropdown(null)}
                            items={semesters.map((item) => ({
                                name: item,
                                click: () => {
                                    setShowDropdown(null);
                                    setSemester(item);
                                },
                            }))}
                        />
                    </RelativeContainer>
                </FlexCentredRow>

                {view !== 'document' ? (
                    <Timetable eventsList={allEvents} />
                ) : (
                    <>
                        {documentItems.map((item) => (
                            <CalenderDoc
                                key={item.dayOfTheWeek}
                                dayOfTheWeek={item.dayOfTheWeek}
                                data={item.data}
                                session={session}
                                semester={semester}
                                type="lecture"
                                refetchAllTimetable={() => setRefetchTimetable((prev) => !prev)}
                            />
                        ))}
                    </>
                )}
            </MainContainer>
        </div>
    );
};

export default LectureTimetable;
