import React from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';
import Checkbox from '../../inputs/checkbox';
import CentreModal from '../../modal/CentreModal';

const SendOfferList = ({
    show,
    close,
    list,
    onSend,
    subtitle,
    title,
    nameField = 'fullName',
    idField = 'id',
    withInviteToAdmin,
    handleCheckbox,
    checked,
    isLoading,
}) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[33.8rem] max-h-[60rem] mx-auto">
                <FlexRowSpaceBetween>
                    <Text as="h3" size="1.9rem" weight="600">
                        {title || 'Send Offer'}
                    </Text>
                    <CloseIcon className="hover:cursor-pointer" onClick={close} />
                </FlexRowSpaceBetween>
                <Text align="start" size="1.6rem" color="#374151" top="1.6rem" lineHeight="2.4rem" bottom="1rem">
                    {subtitle}
                </Text>
                {withInviteToAdmin && (
                    <Checkbox
                        id="invite"
                        label="also invite to admin portal"
                        labelClassName="select-none mb-0 text-[12px]"
                        checked={checked}
                        onChange={handleCheckbox}
                    />
                )}
                <ul className="overflow-y-auto border-b pb-2 mt-5">
                    {list?.map((item, index) => (
                        <Text align="left" key={item?.[idField] || index}>{`${item?.[nameField]}`}</Text>
                    ))}
                </ul>
                <Text color="#6B7280" size="1.2rem" align="left">
                    {list?.length} name(s) selected
                </Text>
                <FlexRowEnd className="gap-x-[16px]">
                    <Button type="button" onClick={close} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} onClick={onSend}>
                        Send
                    </LoadingButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default SendOfferList;
