import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import NavTabs, { Tab } from '../../../../components/nav-tabs/NavTabs';
import { getCalendars } from '../../../../redux/academic-calendar/actions';
import { getActiveSemester, getActiveSession, getAdmissionSessions } from '../../../../redux/admissions/actions';

import AcademicCal from './tabs/AcademicCall';
import ExamTimeTable from './tabs/ExamTimeTable';
import Timetable from './tabs/Timetable';

const developmentFlag = process.env.REACT_APP_ENV;

const tabs = [
    { label: 'Time-table' },
    { label: 'Exam-Time-table' },
    ...(developmentFlag !== 'production' ? [{ label: 'Academic Calendar' }] : []),
];

const TimeTable = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCalendars());
        dispatch(getActiveSemester());
        dispatch(getActiveSession());
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    return (
        <div>
            <NavTabs tabs={tabs}>
                <Tab.Panel>
                    <Timetable />
                </Tab.Panel>
                <Tab.Panel>
                    <ExamTimeTable />
                </Tab.Panel>
                <Tab.Panel>
                    <AcademicCal />
                </Tab.Panel>
            </NavTabs>
        </div>
    );
};

export default TimeTable;
