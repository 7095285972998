import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { ReactComponent as PlusIcon } from '../../../../../../../../assets/icons/circlePlus.svg';
import { ReactComponent as Close } from '../../../../../../../../assets/icons/close.svg';
import { LoadingButton } from '../../../../../../../../components/buttons';
import { CancelButton } from '../../../../../../../../components/forms/sharedStyles';
import TextInput from '../../../../../../../../components/inputs/text-input';
import CentreModal from '../../../../../../../../components/modal/CentreModal';
import { Text } from '../../../../../../../../containers/MesssageContainers';
import { FlexRowEnd, FlexCentredCol, FlexCentredRow } from '../../../../../../../../containers/ScreenContainers';
import { addBonusToSalaryItem } from '../../../../../../../../redux/bursary/actions';

const validationSchema = Yup.object().shape({
    bonusName: Yup.string().required('Name is required'),
    amount: Yup.number()
        .typeError('Amount must be a valid number')
        .positive('Amount must be a positive number')
        .required('Amount is required'),
    reason: Yup.string().required('Description is required'),
});

const AddBonus = ({ staffId, show, close, isLoading, refetch }) => {
    const dispatch = useDispatch();

    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[59.2rem]">
                <FlexRowEnd className="pb-[1rem]">
                    <Close onClick={close} className="cursor-pointer" />
                </FlexRowEnd>

                <Formik
                    initialValues={{
                        amount: '',
                        bonusName: '',
                        reason: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                        const res = await dispatch(addBonusToSalaryItem(staffId, values));
                        if (res) {
                            actions?.resetForm();
                            refetch();
                            close();
                        }
                    }}
                >
                    {({ handleChange, errors, isSubmitting }) => (
                        <Form>
                            <FlexCentredCol className="gap-[3.2rem] mb-[2rem]">
                                <FlexCentredRow className="gap-3">
                                    <PlusIcon fill="#6366f1" />
                                    <Text as="h3" size="1.9rem" weight="600">
                                        Add Bonus
                                    </Text>
                                </FlexCentredRow>
                                <TextInput
                                    name="bonusName"
                                    onChange={handleChange}
                                    label="Name"
                                    type="text"
                                    placeholder="Input Name"
                                    errors={errors}
                                />
                                <TextInput
                                    name="reason"
                                    onChange={handleChange}
                                    label="Purpose/Description"
                                    type="text"
                                    placeholder="Input Description"
                                    errors={errors}
                                />
                                <TextInput
                                    name="amount"
                                    onChange={handleChange}
                                    label="Amount"
                                    type="text"
                                    placeholder="Input Amount"
                                    errors={errors}
                                />
                            </FlexCentredCol>

                            <FlexRowEnd className="gap-[1.5rem] mt-12">
                                <CancelButton type="button" onClick={close}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading || isSubmitting}
                                    disabled={isLoading || isSubmitting}
                                    type="submit"
                                >
                                    Add Bonus
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
            </div>
        </CentreModal>
    );
};

export default AddBonus;
