import { ReactComponent as LoaderIcon } from '../../assets/icons/loader.svg';
import { Text } from '../../containers/MesssageContainers';
import { CenteredContainer } from '../../containers/ScreenContainers';

export const Loader = ({ withText, containerStyle, size }) => {
    return (
        <CenteredContainer className={containerStyle ? containerStyle : 'flex-column h-screen'}>
            <LoaderIcon
                className={`animate-spin ${
                    size === 'small'
                        ? 'w-[2rem] h-[2rem]'
                        : size === 'medium'
                        ? 'w-[4rem] h-[4rem]'
                        : 'w-[8rem] h-[8rem]'
                }`}
            />
            {withText && (
                <Text color="#312E81" weight="600" size="2.3rem">
                    Getting Everything Ready
                </Text>
            )}
        </CenteredContainer>
    );
};
