import React, { useEffect, useState } from 'react';

import { RadioInput } from '@belrald_hq/belrald-ui';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import CentreModal from '../../../../components/modal/CentreModal';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexRowEnd } from '../../../../containers/ScreenContainers';

const ChangeGatewayStatus = ({ show, close, confirmAction, isLoading, btnDisabled, selectedGateway }) => {
    const [enableGateway, setEnableGateway] = useState(false);

    useEffect(() => {
        if (show) {
            setEnableGateway(selectedGateway?.active);
        }
    }, [show, selectedGateway]);
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[59.2rem]">
                <div className="">
                    <FlexRowEnd>
                        <CloseIcon onClick={close} className="cursor-pointer" />
                    </FlexRowEnd>
                    <FlexCentredRow className="gap-[1.215rem] mb-4 mt-2">
                        <Text as="h3" size="1.9rem" weight="600">
                            Payment Gateway Status
                        </Text>
                    </FlexCentredRow>
                </div>
                <FlexCentredCol className="gap-4">
                    <RadioInput checked={enableGateway} onChange={() => setEnableGateway(true)} label="Enable" />
                    <RadioInput checked={!enableGateway} onChange={() => setEnableGateway(false)} label="Disable" />
                </FlexCentredCol>

                <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                    <Button onClick={close} disabled={isLoading} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        disabled={enableGateway === selectedGateway?.active}
                        onClick={confirmAction}
                        // bgColor={bgColor || '#EF4444'}
                        // color="#fff"
                    >
                        Save Changes
                    </LoadingButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default ChangeGatewayStatus;
