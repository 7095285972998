import React from 'react';

import { ReactComponent as CashIcon } from '../../../../../assets/icons/cash.svg';
import { Loader } from '../../../../../components/loader';
import { Text } from '../../../../../containers/MesssageContainers';
import { FlexColumn } from '../../../../../containers/ScreenContainers';
import currencyFormatter from '../../../../../utils/formatCurrency';

const AccountDetails = ({ walletBalance, isLoading }) => {
    return (
        <FlexColumn className="gap-[2.4rem]">
            <div className="bg-white w-full py-[1.4rem] px-[1.6rem] rounded-lg">
                {isLoading ? (
                    <Loader containerStyle="h-[20rem] items-center" size="small" />
                ) : (
                    <FlexColumn className="gap-[1rem]">
                        <Text align="left" size="1.4rem" color="#6B7280" weight="500">
                            Wallet Balance
                        </Text>
                        <CashIcon />
                        <Text align="left" size="2.3rem" weight="600">
                            {currencyFormatter.format(walletBalance) || ''}
                            {/* {currency || ''} */}
                        </Text>
                    </FlexColumn>
                )}
            </div>
        </FlexColumn>
    );
};

export default AccountDetails;
