import React from 'react';

import warning from '../../../assets/icons/warning-big-ash.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import CentreModal from '../../modal/CentreModal';

const DeleteError = ({ show, type = 'success', title, subtitle, withButton = true, btnText, btnAction, onClose }) => {
    return (
        <CentreModal isOpen={show} onClose={() => onClose?.()}>
            <div className="bg-white rounded-[8px] px-[3.9rem] py-[3.5rem] min-w-[25rem]">
                <CloseIcon onClick={onClose} claasName="absolute top-[3.5rem] right-[3.9rem] cursor-pointer" />
                <div>
                    <div className="flex flex-col justify-center items-center mt-[40.3px] mb-[31px]">
                        <img src={warning} alt="warning" />

                        <Text weight="600" top="3.1rem" bottom="1.6rem" size="4rem">
                            {title || 'Done!'}
                        </Text>
                        <Text size="1.6rem" bottom="3.2rem">
                            {subtitle}
                        </Text>

                        {withButton && (
                            <button
                                onClick={btnAction || onClose}
                                className="text-white rounded w-[9.3rem] h-[4rem] text-[1.4rem] px-4 py-2 bg-[#EF4444] leading-6 font-medium"
                            >
                                {btnText || 'Continue'}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </CentreModal>
    );
};

export default DeleteError;
