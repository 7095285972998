import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as Check } from '../../../assets/icons/circular-mark-sm-black.svg';
import { ReactComponent as CircularPlus } from '../../../assets/icons/circularplus-black.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import CustomTable from '../../../components/CustomTable';
import ApplyDiscount from '../../../components/popups/apply-discount';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import usePersistedState from '../../../hooks/usePersistedState';
import useSearch from '../../../hooks/useSearch';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAdmissionSessions } from '../../../redux/admissions/actions';
import { deleteDiscount, getAllDiscounts, getStudentsAttachedToDiscount } from '../../../redux/bursary/actions';
import { localStoreKeys } from '../../../utils/localStore';

const DiscountList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, allDiscounts } = useSelector((state) => state.bursary);
    const { sessions, activeSession } = useSelector((state) => state.admission);

    const [session, setSession] = usePersistedState(activeSession || '', localStoreKeys.SelectedSession);

    const [filteredDiscounts, setFilteredDiscounts] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [toggleRefetch, setToggleRefetch] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [discountStudents, setDiscountStudents] = useState([]);
    // eslint-disable-next-line
    const [altLoading, setAltLoading] = useState(false);
    const [openModal, setOpenModal] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const fields = [
        {
            cellRenderer: (props) => (
                <Link to={props.data._id} state={{ discount: props.data }}>
                    <p className="text-[#2563EB]">{props?.value}</p>
                </Link>
            ),
            headerName: 'Discount Name',
            field: 'discountName',
        },
        {
            cellRenderer: (props) => <p>{props?.value.replace(/-/g, ' ')}</p>,
            headerName: 'Discount Type',
            field: 'discountType',
        },
        {
            cellRenderer: (props) => <p>{props?.value || ''}</p>,
            headerName: 'Percentage Off',
            field: 'discountPercentage',
        },
        {
            cellRenderer: (props) => <p>{props?.value}</p>,
            headerName: 'Number of Student Applied',
            field: 'beneficiaryCount',
        },
    ];

    const filteredResults = useSearch(searchValue, filteredDiscounts, ['discountName']) || [];

    const sessionItems = sessions?.map((item) => ({
        name: item?.admissionYear,
        click: () => {
            setSession(item?.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const handleDelete = async () => {
        const res = await dispatch(deleteDiscount(selectedItem?._id));
        if (res) {
            setOpenModal('');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    useEffect(() => {
        if (session && allDiscounts?.length > 0) {
            const sessionDiscounts = allDiscounts?.filter((discount) => discount?.session === session);
            setFilteredDiscounts(sessionDiscounts);
        } else {
            setFilteredDiscounts(allDiscounts);
        }
    }, [session, allDiscounts]);

    const query = useMemo(() => {
        let queryParams = {};

        if (session) {
            queryParams.session = session;
        }

        return queryParams;
    }, [session]);

    useEffect(() => {
        const getDiscounts = async () => {
            setAltLoading(true);
            const res = await dispatch(getAllDiscounts(query));
            if (res) {
                setAltLoading(false);
            }
        };
        getDiscounts();
    }, [dispatch, toggleRefetch, query]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    useEffect(() => {
        if (selectedItem && openModal === 'apply') {
            const studentsAttachedToDiscount = async () => {
                const res = await dispatch(getStudentsAttachedToDiscount(selectedItem?._id));
                setDiscountStudents(res);
            };
            studentsAttachedToDiscount();
        }
    }, [dispatch, selectedItem, openModal]);

    return (
        <>
            <PageLayout
                pageTitle="Discounts"
                secondaryButtonText="Apply Discount"
                secondaryButtonIcon={<Check />}
                disableSecondaryBtn={!selectedItem}
                // isActionable
                // actionType="radio"
                // onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                onSecondaryButtonClick={() => setOpenModal('apply')}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <CircularPlus />,
                        name: 'Create Discount',
                        click: () => navigate('/bursary/discounts/create'),
                    },
                    {
                        icon: <EditIcon />,
                        name: 'Edit Discount',
                        disabled: !selectedItem,
                        click: () =>
                            navigate('/bursary/discounts/edit', { state: { selectedDiscount: selectedItem || {} } }),
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Discount',
                        disabled: !selectedItem,
                        click: () => setOpenModal('delete'),
                    },
                ]}
                onSessionFilterClick={() => handleOpenDropdown('sessions')}
                sessionPlaceHolder={session}
                openSessionFilter={openDropdown === 'sessions'}
                closeSessionFilter={() => setOpenDropdown(null)}
                sessionItems={sessionItems}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                searchValue={searchValue}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                showFilter
                // data={filteredResults || []}
                // fields={fields}
                // loading={altLoading}
                // width={'100%'}
                customTable={
                    <CustomTable
                        colDefs={fields}
                        data={filteredResults || []}
                        rowSelection
                        singleRowSelection
                        setSelectedItem={setSelectedItem}
                    />
                }
                openFilterPop={openModal === 'options-popup-main'}
                closeFilterPop={() => setOpenModal(null)}
            />

            <ConfirmActionDialogue
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete this discount?"
                bodyText="Do you want to continue?"
                confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                isLoading={isLoading}
            />

            <ApplyDiscount
                show={openModal === 'apply'}
                close={() => setOpenModal('')}
                discount={selectedItem}
                onFinish={() => {
                    setToggleRefetch((prev) => !prev);
                }}
                students={discountStudents}
            />
        </>
    );
};

export default DiscountList;
