import React from 'react';

import { AgCharts } from 'ag-charts-react';

import { Select } from '../../../../../components';
import { Loader } from '../../../../../components/loader';

const dayQueries = [
    { label: 'Last 7 days', value: 7 },
    { label: 'Last 14 days', value: 14 },
    { label: 'Last 30 days', value: 30 },
    { label: 'Last 90 days', value: 90 },
];

const RevenueChart = ({ revenueData, setParams, params, isLoading, totalRevenue }) => {
    const options = {
        data: revenueData,
        series: [{ type: 'bar', xKey: 'date', yKey: 'amount' }],
    };

    return (
        <div className="bg-white">
            {isLoading ? (
                <Loader containerStyle="h-[20rem] items-center" size="small" />
            ) : (
                <div
                    style={{
                        padding: '20px',
                        height: '560px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ marginBottom: '20px' }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>
                                <div style={{ color: '#666', marginBottom: '8px' }}>Revenue (NGN)</div>
                                <div
                                    style={{
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#1f2937',
                                    }}
                                >
                                    {totalRevenue}
                                </div>
                            </div>
                            <div className="w-[160px]">
                                <Select
                                    name="days"
                                    objProp="label"
                                    placeholder="Select days"
                                    data={dayQueries}
                                    onChange={(selected) => {
                                        setParams(selected);
                                    }}
                                    passedSelectedItems={params}
                                />
                            </div>
                        </div>
                    </div>
                    <AgCharts options={options} className="h-[600px]" />
                </div>
            )}
        </div>
    );
};

export default RevenueChart;
