/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Delete } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit3.svg';
import { ReactComponent as Plus } from '../../../../assets/icons/plus-blue.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import DeleteRole from '../../../../components/popups/delete-role';
import UploadFile from '../../../../components/upload-file';
// import { PageTitle } from '../../../../containers/MesssageContainers';
import {
    FlexCentredRow,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import { getRolesInDepartment } from '../../../../redux/roles/actions';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Roles',
        key: 'role',
    },
    {
        label: 'Number of Staff',
        key: 'staffs',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const DepartmentRoles = ({ unitName }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentDepartment } = useSelector((state) => state.department);
    const { _id: departmentId } = currentDepartment;

    const { isLoading } = useSelector((state) => state.roles);
    const [roles, setRoles] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(roles?.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showDeleteRole, setShowDeleteRole] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const getDetails = async () => {
        const data = await dispatch(getRolesInDepartment(departmentId));
        data && setRoles(data);
    };

    const handleSelect = (item) => {
        const selected = selectedItem?._id === item._id;
        if (selected) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
    };

    useEffect(() => {
        if (departmentId) {
            getDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departmentId]);

    return (
        <>
            <div className="mt-[0.5rem] rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    <FlexCentredRow className="gap-x-4">
                        <Button
                            border="1px solid #e5e7eb"
                            color="#6366F1"
                            onClick={() => navigate('roles/create-role')}
                        >
                            <Plus />
                            Create Role
                        </Button>

                        <RelativeContainer>
                            <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                                Actions
                                <Dropdown />
                            </Button>

                            <ActionsPopup
                                open={dropdownOpen}
                                close={() => setDropdownOpen(false)}
                                items={[
                                    {
                                        icon: <EditIcon />,
                                        name: 'Edit Role',
                                        disabled: !selectedItem,
                                        click: () => {
                                            navigate('roles/edit-role', { state: selectedItem });
                                        },
                                    },
                                    {
                                        icon: <Delete />,
                                        name: 'Delete Role',
                                        click: () => {
                                            setShowDeleteRole(true);
                                            setDropdownOpen(false);
                                        },
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    </FlexCentredRow>
                </FilterContainer>
                {/* <Table
                    items={roles || []}
                    fields={fields}
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    onRowClick={(item) => handleSelect(item)}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox
                                    onChange={() => handleSelect(item)}
                                    checked={selectedItem?._id === item._id}
                                />
                            </td>
                        ),

                        staffs: (item) => <td>{item.staffs?.length}</td>,
                    }}
                /> */}
            </div>

            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
            <DeleteRole show={showDeleteRole} setShow={setShowDeleteRole} />
        </>
    );
};

export default DepartmentRoles;
