import React from 'react';

import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';

import { Select } from '../../../../../components';
import { Loader } from '../../../../../components/loader';

const StatusRateChart = ({ successRate, setParams, params, isLoading }) => {
    const data = [
        { name: 'Successful', value: successRate?.successful || 0, color: '#00A37A' },
        { name: 'Errors', value: successRate?.errors || 0, color: '#E0E0E0' },
    ];

    const total = data.reduce((sum, entry) => sum + entry.value, 0);

    const normalizedData = data.map((item) => ({
        ...item,
        value: Math.round((item.value / total) * 100),
    }));

    return (
        <div
            className="w-[100%] h-[420px] bg-white rounded-[8px] p-[20px]"
            style={{
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
            }}
        >
            {isLoading ? (
                <Loader containerStyle="h-[20rem] items-center" size="small" />
            ) : (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '20px',
                        }}
                    >
                        <h3
                            style={{
                                margin: 0,
                                color: '#333',
                                fontWeight: 500,
                                fontSize: '16px',
                            }}
                        >
                            Success Rate
                        </h3>

                        <div className="w-[160px]">
                            <Select
                                name="days"
                                objProp="label"
                                placeholder="Select days"
                                data={[
                                    { label: 'Last 7 days', value: 7 },
                                    { label: 'Last 14 days', value: 14 },
                                    { label: 'Last 30 days', value: 30 },
                                    { label: 'Last 90 days', value: 90 },
                                ]}
                                onChange={(selected) => {
                                    setParams(selected);
                                }}
                                passedSelectedItems={params}
                            />
                        </div>
                    </div>

                    <div style={{ width: '100%', height: 'calc(100% - 100px)' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={normalizedData}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius="70%"
                                    outerRadius="85%"
                                    startAngle={90}
                                    endAngle={-270}
                                    dataKey="value"
                                    stroke="none"
                                >
                                    {normalizedData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                    <Label
                                        content={({ viewBox }) => {
                                            const { cx, cy } = viewBox;
                                            return (
                                                <text
                                                    x={cx}
                                                    y={cy}
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                    style={{
                                                        fontSize: '28px',
                                                        fontWeight: 'bold',
                                                        fill: '#333',
                                                    }}
                                                >
                                                    {`${normalizedData[0].value}%`}
                                                </text>
                                            );
                                        }}
                                    />
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '30px',
                        }}
                    >
                        {normalizedData.map((entry, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                <div
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        backgroundColor: entry.color,
                                    }}
                                />
                                <span
                                    style={{ fontSize: '14px', color: '#333' }}
                                >{`${entry.name} ${entry.value}%`}</span>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default StatusRateChart;
