import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { GoBack } from '../../../components/go-back';
import { Loader } from '../../../components/loader';
//import useClickOutside from '../../../hooks/useClickOutside';
import NavTabs, { Tab } from '../../../components/nav-tabs/NavTabs';
import { getStudentDetails } from '../../../redux/students/actions';

import Academics from './tabs/Academics';
import BasicInfo from './tabs/BasicInfo';
import Library from './tabs/Library';
import Medicals from './tabs/Medicals';
import Personal from './tabs/Personal';
import Results from './tabs/Results';
import Sports from './tabs/Sports';
import Uploads from './tabs/Uploads';

const tabs = [
    { label: 'Overview' },
    { label: 'Personal' },
    { label: 'Academics' },
    { label: 'Medicals' },
    { label: 'Sports' },
    { label: 'Library' },
    { label: 'Results' },
    { label: 'Uploads' },
];

const RecordDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { studentDetails, isLoading } = useSelector((store) => store.student);

    useEffect(() => {
        dispatch(getStudentDetails(id));
    }, [dispatch, id]);

    if (isLoading || !studentDetails) return <Loader />;

    return (
        <div>
            <GoBack className="mb-[3.2rem]" title="Records" />

            <NavTabs tabs={tabs}>
                <Tab.Panel>
                    <BasicInfo details={studentDetails} />
                </Tab.Panel>
                <Tab.Panel>
                    <Personal details={studentDetails} />
                </Tab.Panel>
                <Tab.Panel>
                    <Academics details={studentDetails} />
                </Tab.Panel>
                <Tab.Panel>
                    <Medicals details={studentDetails} />
                </Tab.Panel>
                <Tab.Panel>
                    <Sports details={studentDetails} />
                </Tab.Panel>
                <Tab.Panel>
                    <Library details={studentDetails} />
                </Tab.Panel>
                <Tab.Panel>
                    <Results />
                </Tab.Panel>
                <Tab.Panel>
                    <Uploads details={studentDetails} />
                </Tab.Panel>
            </NavTabs>
        </div>
    );
};

export default RecordDetails;
