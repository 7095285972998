import React, { useState } from 'react';

import tw from 'twin.macro';

import useSearch from '../../../hooks/useSearch';
import SearchInput from '../../inputs/search-input';

const ItemContainer = tw.ul`
  flex
  flex-col
  gap-[8px]
  text-[1.6rem]
  leading-[2.4rem]
  font-normal
  text-[#1F2937]
  mb-0
  mt-[8px]
`;

const Item = tw.li`
  flex
  items-center
  gap-[0.8rem]
  hover:bg-[#F3F4F6]
  rounded-[8px]
  py-[10px]
  px-[16px]
`;

const FilterPopup = ({ open, items, close, width, isLoading, absolutePosition = 'right-[0px]' }) => {
    const [searchValue, setSearchValue] = useState('');

    const filteredItems = useSearch(searchValue, items, ['name']);

    const handleClick = (item) => {
        item.click();
        close();
    };

    return (
        <div>
            {open && <div className="fixed z-10 inset-0" onClick={close}></div>}
            <div
                className={`${
                    open
                        ? `visible max-h-[306px] absolute ${absolutePosition} top-[30px] shadow-lg z-[40] rounded-[8px] px-[8px] py-[16px] w-[${width}]  overflow-y-auto min-w-max bg-white cursor-pointer`
                        : 'hidden'
                }`}
            >
                <SearchInput
                    width="fit-content"
                    withRadius
                    value={searchValue}
                    iconLeft
                    onClose={() => setSearchValue('')}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <ItemContainer>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        <>
                            {filteredItems?.map((item) => (
                                <Item key={item.name} onClick={() => handleClick(item)}>
                                    {item.icon && item.icon}
                                    {item.name}
                                </Item>
                            ))}
                        </>
                    )}
                </ItemContainer>
            </div>
        </div>
    );
};

export default FilterPopup;
