import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import NavTabs, { Tab } from '../../../../components/nav-tabs/NavTabs';
import { PageTitle } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, RelativeContainer } from '../../../../containers/ScreenContainers';
import { getAllSent, getAllSentMemo, getSentLetterComms } from '../../../../redux/staff-arena/actions';

import OutboxAll from './tabs/SentAll';
import SentLetter from './tabs/SentLetter';
import SentMemo from './tabs/SentMemo';
import SentNotice from './tabs/SentNotice';

const tabs = [{ label: 'All' }, { label: 'Letter' }, { label: 'Memo' }, { label: 'Notice' }];

const Outbox = ({ unitName }) => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const [sentOfficeMemo, setSentOfficeMemo] = useState([]);
    const [sentLetter, setSentLetter] = useState([]);
    const [allSent, setAllSent] = useState([]);

    const ComposeList = [
        {
            name: 'Letters',
            click: () => {
                navigate('/staff-arena/office/letter');
                setDropdownOpen(false);
            },
        },

        {
            name: ' Memo ',
            click: () => {
                navigate('/staff-arena/office/memo');
                setDropdownOpen(false);
            },
        },
        {
            name: 'Notices ',
            click: () => {
                // navigate('/staff-arena/office/notice');
                setDropdownOpen(false);
            },
        },
    ];

    useEffect(() => {
        const getSentOfficeMemo = async () => {
            const res = await dispatch(getAllSentMemo());
            setSentOfficeMemo(res);
        };

        const getSentOfficeLetter = async () => {
            const res = await dispatch(getSentLetterComms());
            setSentLetter(res);
        };

        const getAllComms = async () => {
            const res = await dispatch(getAllSent());
            setAllSent(res);
        };

        getSentOfficeMemo();
        getSentOfficeLetter();
        getAllComms();
    }, [dispatch]);

    // const handleActionClick = (route) => {
    //     navigate(route);
    //     setDropdownOpen(false);
    // };

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Office</PageTitle>

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Compose
                        <Dropdown />
                    </Button>
                    <ActionsPopup open={dropdownOpen} close={() => setDropdownOpen(false)} items={ComposeList} />
                </RelativeContainer>
            </FlexRowSpaceBetween>

            <NavTabs tabs={tabs}>
                <Tab.Panel>
                    <OutboxAll allSent={allSent} />
                </Tab.Panel>
                <Tab.Panel>
                    <SentLetter sentLetter={sentLetter} />
                </Tab.Panel>
                <Tab.Panel>
                    <SentMemo sentMemo={sentOfficeMemo} />
                </Tab.Panel>
                <Tab.Panel>
                    <SentNotice />
                </Tab.Panel>
            </NavTabs>
        </div>
    );
};

export default Outbox;
