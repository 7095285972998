import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as Plus } from '../../../../assets/icons/circularplus-black.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import CustomTable from '../../../../components/CustomTable';
import { GoBack } from '../../../../components/go-back';
import NavTabs, { Tab } from '../../../../components/nav-tabs/NavTabs';
import ConfirmActionDialogueWithInput from '../../../../components/popups/confirm-action-with-input';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import { useTableContext } from '../../../../context/tableContext';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getPaymentSubGroupsById, removeStaffFromPaymentSubGroup } from '../../../../redux/bursary/actions';
import { capitalizeFirstLetter } from '../../../../utils';
import { formatToDateTime } from '../../../../utils/formatDate';
import { storeUnit } from '../../../../utils/originUnit';

const BasicContent = tw.div`
    grid
    md:grid-cols-4
    grid-cols-2
    my-8
    gap-4
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-[2.4rem]
    rounded-lg
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
`;

const tabs = [{ label: 'Academics' }, { label: 'Non-academics' }];

const SubGroupDetails = ({ unitName }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        state: { _id: id, groupName, groupDetails, stepName },
    } = useLocation();

    const { isLoading, stepDetails: SubGroupDetails } = useSelector((state) => state.bursary);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [filteredAcademics, setFilteredAcademics] = useState([]);
    const [filteredNonAcademics, setFilteredNonAcademics] = useState([]);

    const { staffIds: allstaff = {} } = SubGroupDetails;
    // eslint-disable-next-line
    const { platformStaffs: staff, pagination } = allstaff || {};
    // const { totalItems, totalPages } = pagination || {};
    // eslint-disable-next-line
    const [currentPage, setCurrentPage] = useState('1');
    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;

    useEffect(() => {
        if (!id) return;

        const queries = {
            platformSearch: debouncedSearchValue,
            platformPage: currentPage,
            platformLimit: numOfPages,
            manualSearch: debouncedSearchValue,
            manualPage: currentPage,
            manualLimit: numOfPages,
        };
        dispatch(getPaymentSubGroupsById(id, queries));
    }, [id, debouncedSearchValue, currentPage, numOfPages, toggleRefetch, dispatch]);

    useEffect(() => {
        // Filter staff based on staffType
        const filteredAcademicsData = staff?.filter((staff) => staff?.staffType === 'Teaching');
        setFilteredAcademics(filteredAcademicsData);

        const filteredNonAcademicsData = staff?.filter((staff) => staff?.staffType === 'Non-Teaching');
        setFilteredNonAcademics(filteredNonAcademicsData);
    }, [debouncedSearchValue, staff]);

    useEffect(() => {
        const filteredNonAcademicsData = staff?.filter(
            (staff) =>
                staff?.staffType === 'Non-Teaching' &&
                (staff?.staffName?.includes(debouncedSearchValue) ||
                    staff?.staffNumber?.includes(debouncedSearchValue)),
        );
        setFilteredNonAcademics(filteredNonAcademicsData);
    }, [debouncedSearchValue, staff]);

    const handleRemove = async () => {
        const staffIdToRemove = selectedItem?._id;
        const payload = {
            staffIds: [staffIdToRemove],
            staffType: selectedItem?.staffType,
        };
        const res = await dispatch(removeStaffFromPaymentSubGroup(payload, id));
        if (res) {
            setFilteredAcademics((prev) => prev.filter((staff) => staff._id !== staffIdToRemove));
            setFilteredNonAcademics((prev) => prev.filter((staff) => staff._id !== staffIdToRemove));
            setToggleRefetch((prevState) => !prevState);
            setOpenModal(false);
        }
    };

    const handleCloseModal = (modal) => {
        if (openModal === modal) {
            setOpenModal(null);
        } else {
            setOpenModal(modal);
        }
    };

    const fields = [
        {
            cellRenderer: ({ data }) => {
                const { firstName, otherName, surname } = data || {};
                return (
                    <p>
                        {firstName} {otherName || ''} {surname}
                    </p>
                );
            },
            headerName: 'Staff Name',
        },

        {
            cellRenderer: (props) => <p>{props?.value}</p>,
            headerName: 'Staff Number',
            accessorKey: 'staffNumber',
        },

        { headerName: 'College', accessorKey: 'collegeName' },

        {
            accessorKey: 'unitName',
            headerName: 'Organization Unit',
        },
    ];

    const nonteachingfields = [
        {
            cellRenderer: ({ data }) => {
                const { firstName, otherName, surname } = data || {};
                return (
                    <p>
                        {firstName} {otherName || ''} {surname}
                    </p>
                );
            },
            headerName: 'Staff Name',
        },

        {
            cellRenderer: (props) => <p>{props?.value}</p>,
            headerName: 'Staff Number',
            accessorKey: 'staffNumber',
        },

        {
            accessorKey: 'unitName',
            headerName: 'Organization Unit',
        },
    ];

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <>
            <div>
                <GoBack title={`Payment Group / ${groupName}`} subTitle={stepName}></GoBack>
                <FlexRowSpaceBetween className="mt-[4.5rem]">
                    <PageTitle align="left">{`${groupDetails?.groupName} , ${SubGroupDetails?.stepName}`}</PageTitle>
                    <FlexCentredRow className="gap-x-4">
                        <RelativeContainer>
                            <Button bgColor="#6366F1" color="#fff" onClick={() => handleCloseModal('actions')}>
                                Actions
                                <Dropdown className="ml-[13.15px]" />
                            </Button>
                            <ActionsPopup
                                open={openModal === 'actions'}
                                close={() => setOpenModal(null)}
                                items={[
                                    {
                                        icon: <Plus />,
                                        name: 'Add Staff',
                                        click: () =>
                                            navigate(
                                                `/bursary/payroll/payment-group/${groupDetails?._id}/group-subgroups/${id}/add-staff`,
                                                { state: { stepName, groupDetails, SubGroupDetails } },
                                            ),
                                    },

                                    {
                                        icon: <RemoveIcon />,
                                        name: 'Remove Staff',
                                        disabled: !selectedItem,
                                        click: () => {
                                            setOpenModal('remove');
                                        },
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
                <Details className="mt-[3rem]">
                    <FlexCentredCol>
                        <PageTitle align="left" size="1.9rem" lineheight="2.8rem">
                            Overview
                        </PageTitle>
                    </FlexCentredCol>

                    <BasicContent>
                        <FlexCol>
                            <GreyText>Group Name</GreyText>
                            <DarkText>{groupDetails?.groupName}</DarkText>
                        </FlexCol>

                        <FlexCol>
                            <GreyText>Sub Group Name</GreyText>
                            <DarkText>{SubGroupDetails?.stepName}</DarkText>
                        </FlexCol>

                        <FlexCol className="max-w-xs break-words">
                            <GreyText>Description</GreyText>
                            <DarkText className="line-clamp-2">{SubGroupDetails?.description}</DarkText>
                        </FlexCol>

                        <FlexCol>
                            <GreyText>Group Type</GreyText>
                            <DarkText>{groupDetails?.groupType}</DarkText>
                        </FlexCol>

                        <FlexCol>
                            <GreyText>Staff Type</GreyText>
                            <DarkText>{capitalizeFirstLetter(groupDetails?.staffType)}</DarkText>
                        </FlexCol>

                        <FlexCol>
                            <GreyText>Created At</GreyText>
                            <DarkText>{formatToDateTime(SubGroupDetails?.createdAt)}</DarkText>
                        </FlexCol>

                        <FlexCol>
                            <GreyText>Created By</GreyText>
                            <DarkText>{SubGroupDetails?.createdBy}</DarkText>
                        </FlexCol>
                    </BasicContent>
                </Details>

                <NavTabs tabs={tabs} className="flex items-center mt-[2.4rem] gap-[2.4rem]">
                    <Tab.Panel>
                        <PageLayout
                            onSearchChange={(e) => setSearchValue(e.target.value)}
                            searchValue={searchValue}
                            onSearchClose={() => setSearchValue('')}
                            showTableUtils
                            showFilter
                            customTable={
                                <CustomTable
                                    colDefs={fields}
                                    data={filteredAcademics || []}
                                    rowSelection
                                    singleRowSelection
                                    setSelectedItem={setSelectedItem}
                                />
                            }
                            // data={filteredAcademics || []}
                            // fields={fields}
                            // loading={isLoading}
                            // width={'100%'}
                            // isActionable
                            // actionType="radio"
                            // onSelectedItemItemsChange={(items) => setSelectedItem(items[0])}
                            openFilterPop={openModal === 'options-popup-main'}
                            closeFilterPop={() => setOpenModal(null)}
                            // paginationComponent={
                            //     <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                            //         <PaginationStatus
                            //             currentPage={currentPage}
                            //             itemsPerPage={tableSettings?.numOfPages}
                            //             totalItems={totalItems}
                            //         />
                            //         <Pagination
                            //             pageRangeDisplayed={3}
                            //             totalPages={totalPages}
                            //             onPageChange={(nextPage) => {
                            //                 setCurrentPage(nextPage);
                            //             }}
                            //             currentPage={currentPage}
                            //         />
                            //     </FlexRowSpaceBetween>
                            // }
                        />
                    </Tab.Panel>
                    <Tab.Panel>
                        <PageLayout
                            onSearchChange={(e) => setSearchValue(e.target.value)}
                            searchValue={searchValue}
                            onSearchClose={() => setSearchValue('')}
                            showTableUtils
                            showFilter
                            customTable={
                                <CustomTable
                                    colDefs={nonteachingfields}
                                    data={filteredNonAcademics}
                                    rowSelection
                                    singleRowSelection
                                    setSelectedItem={setSelectedItem}
                                />
                            }
                            // data={filteredNonAcademics || []}
                            // fields={nonteachingfields}
                            // loading={isLoading}
                            // width={'100%'}
                            // isActionable
                            // actionType="radio"
                            // onSelectedItemItemsChange={(items) => setSelectedItem(items[0])}
                            openFilterPop={openModal === 'options-popup-main'}
                            closeFilterPop={() => setOpenModal(null)}
                            // paginationComponent={
                            //     <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                            //         <PaginationStatus
                            //             currentPage={currentPage}
                            //             itemsPerPage={tableSettings?.numOfPages}
                            //             totalItems={totalItems}
                            //         />
                            //         <Pagination
                            //             pageRangeDisplayed={3}
                            //             totalPages={totalPages}
                            //             onPageChange={(nextPage) => {
                            //                 setCurrentPage(nextPage);
                            //             }}
                            //             currentPage={currentPage}
                            //         />
                            //     </FlexRowSpaceBetween>
                            // }
                        />
                    </Tab.Panel>
                </NavTabs>
            </div>

            <ConfirmActionDialogueWithInput
                show={openModal === 'remove'}
                close={() => setOpenModal('')}
                title="Remove Staff"
                subtitle="The selected staff will be removed permanently."
                subtitle2="To confirm, enter remove in the text field."
                confirmAction={() => handleRemove()}
                btn2Text="Yes, Remove"
                placeholder="Input remove"
                setInputValue={setInputValue}
                value={inputValue}
                isLoading={isLoading}
                btnDisabled={inputValue !== 'remove'}
                list
                listName={`${selectedItem?.firstName} ${selectedItem?.otherName || ''} ${selectedItem?.surname}`}
                listNumber={`${selectedItem?.staffNumber}`}
                bgColor="#EF4444"
            />
        </>
    );
};
export default SubGroupDetails;
