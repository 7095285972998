import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as FolderIcon } from '../../../assets/icons/folder.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import { getSearchFolder, moveFile, moveFolder } from '../../../redux/staff-arena/actions';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../../forms/sharedStyles';
import SearchInput from '../../inputs/search-input';
import CentreModal from '../../modal/CentreModal';
import NavTabs, { Tab } from '../../nav-tabs/NavTabs';

const tabs = [{ label: 'Suggested' }, { label: 'All' }];

const MoveFolder = ({ show, close, setOpen, fileName, id, setToggleRefetch, type }) => {
    const { searchFolder, createdFolders, isLoading } = useSelector((state) => state.staffArena);
    const [searchValue, setSearchValue] = useState('');
    const [filteredFolder, setFilteredFolder] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const debouncedSearchValue = useDebounce(searchValue, 700);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchValue(query);
    };

    useEffect(() => {
        if (!debouncedSearchValue) {
            return;
        }

        dispatch(getSearchFolder(debouncedSearchValue));
    }, [debouncedSearchValue, dispatch]);

    useEffect(() => {
        if (searchFolder && searchFolder?.folderName?.includes(debouncedSearchValue)) {
            setFilteredFolder(searchFolder);
        } else {
            setFilteredFolder(null);
        }
    }, [searchFolder, debouncedSearchValue]);

    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[1rem] w-[59.2rem]">
                <FlexCentredRow className=" pb-[1rem] mt-[2.4rem] border-b">
                    <Text as="h3" size="1.9rem" weight="600" className="pl-[2.4rem]">
                        Move '{fileName}'
                    </Text>
                </FlexCentredRow>

                <Formik
                    initialValues={{
                        search: '',
                    }}
                    onSubmit={async (values, actions) => {
                        const payload = {
                            search: filteredFolder?.folderName || values?.search,
                        };

                        if (type === 'file') {
                            const res = await dispatch(moveFile(payload, id));
                            if (res) {
                                setOpen();
                                setToggleRefetch();
                                setFilteredFolder('');
                            }
                        } else if (type === 'folder') {
                            const res = await dispatch(moveFolder(payload, id));
                            if (res) {
                                setOpen();
                                setToggleRefetch();
                                setFilteredFolder('');
                            }
                        }
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className=" py-[1rem] border-b">
                                <div className="ml-[2.4rem]">
                                    <SearchInput
                                        width="45rem"
                                        onClose={() => setSearchValue('')}
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                        placeholder="Input name"
                                        className="border mt-[1rem] ml-[2.4rem] p-2 rounded"
                                    />
                                </div>
                            </div>

                            <NavTabs tabs={tabs} className="flex items-center mt-[1rem] ml-[2.4rem] gap-[2.4rem]">
                                <Tab.Panel>
                                    {filteredFolder ? (
                                        <FlexCentredRow
                                            key={filteredFolder?._id}
                                            className="gap-[1rem] py-[0.5rem] mt-[1rem]  pl-[3.4rem] cursor-pointer hover:bg-gray-200"
                                            onClick={() => setFieldValue('search', filteredFolder?.folderName)}
                                        >
                                            <FolderIcon />
                                            <Text align="left" weight="400" size="1.4rem" lineHeight="2.4rem">
                                                {filteredFolder?.folderName}
                                            </Text>
                                        </FlexCentredRow>
                                    ) : (
                                        ''
                                    )}
                                </Tab.Panel>
                                <Tab.Panel>
                                    {createdFolders?.map((folder) => (
                                        <FlexCentredRow
                                            onClick={() => setFieldValue('search', folder?.folderName)}
                                            key={folder?._id}
                                            className="gap-[1rem] py-[0.5rem] mt-[1rem] pl-[3.4rem] cursor-pointer hover:bg-gray-200"
                                        >
                                            <FolderIcon />
                                            <Text align="left" weight="400" size="1.4rem" lineHeight="2.4rem">
                                                {folder?.folderName}
                                            </Text>
                                        </FlexCentredRow>
                                    ))}
                                </Tab.Panel>
                            </NavTabs>

                            <FlexRowEnd className="gap-[1.5rem] mt-[1rem] mr-[2.4rem] mb-[2.4rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} type="submit">
                                    Move
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
            </div>
        </CentreModal>
    );
};

export default MoveFolder;
