import { Dialog } from '@headlessui/react';

const CentreModal = ({ children, isOpen, onClose }) => {
    return (
        <Dialog open={isOpen} onClose={onClose} className="relative z-50">
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                {/* The actual dialog panel  */}
                <Dialog.Panel className="">{children}</Dialog.Panel>
            </div>
        </Dialog>
    );
};

export default CentreModal;
