import React from 'react';

import NavTabs, { Tab } from '../../../../components/nav-tabs/NavTabs';

import Files from './files';
import Folders from './folders';

const tabs = [{ label: 'Files' }, { label: 'Folders' }];

const Tabs = ({ toggleRefetch, setToggleRefetch }) => {
    return (
        <div>
            <NavTabs tabs={tabs} className="flex items-center gap-[2.4rem]">
                <Tab.Panel>
                    <Files toggleRefetch={toggleRefetch} setToggleRefetch={setToggleRefetch} />
                </Tab.Panel>
                <Tab.Panel>
                    <Folders toggleRefetch={toggleRefetch} setToggleRefetch={setToggleRefetch} />
                </Tab.Panel>
            </NavTabs>
        </div>
    );
};

export default Tabs;
