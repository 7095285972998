import React, { useEffect, useState } from 'react';

// import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import { useDispatch, useSelector } from 'react-redux';

import { ExportSheetDialogue } from '../../../../../components';
import CustomTable from '../../../../../components/CustomTable';
import DatePicker from '../../../../../components/new-date-picker';
// import { FlexRowSpaceBetween } from '../../../../../containers/ScreenContainers';
import { useTableContext } from '../../../../../context/tableContext';
import useDebounce from '../../../../../hooks/useDebounce';
import PageLayout from '../../../../../layout/page-layout/PageLayout';
import { getPaidFull } from '../../../../../redux/bursary/actions';
import currencyFormatter from '../../../../../utils/formatCurrency';

const PaidStudents = ({ paidStudents, loading, currentFee, setPaidStudents, id }) => {
    const dispatch = useDispatch();
    // const { paidStudents: responseData } = useSelector((store) => store.bursary);
    // const { totalPages, totalNoOfStudents } = responseData || {};
    const {
        firstDegreeOptionsStr,
        secondDegreeOptionsStr,
        //  firstDegreeOptionsArray = [],
    } = useSelector((state) => state.academicStructure);
    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    // eslint-disable-next-line
    const [currentPage, setCurrentPage] = useState('1');
    const [selectedDates, setSelectedDates] = useState({ startDate: '', endDate: '' });

    const colDefs = [
        {
            headerName: 'Names',
            field: 'name',
            cellRenderer: ({ data }) => (
                <p>
                    {data?.lastName} {data?.firstName} {data?.middleName || ''}
                </p>
            ),
        },
        {
            headerName: 'Matric No',
            field: 'matricNumber',
        },
        {
            headerName: firstDegreeOptionsStr,
            field: 'faculty',
            cellRenderer: ({ data }) => <p>{data?.college || data?.faculty || data?.school}</p>,
        },
        {
            headerName: secondDegreeOptionsStr,
            field: 'program',
            cellRenderer: ({ data }) => <p>{data?.program || data?.department}</p>,
        },
        {
            cellRenderer: (p) => <p>{currencyFormatter.format(p.value || 0)}</p>,
            headerName: 'Amount Payable',
            field: 'amountPayable',
        },
        {
            cellRenderer: ({ data }) => (
                <p>
                    {data?.[0]?.paymentDate
                        ? `${new Date(data?.payments?.[0]?.paymentDate).toLocaleDateString()}, ${new Date(
                              data?.payments?.[0]?.paymentDate,
                          ).toLocaleTimeString()}`
                        : ''}
                </p>
            ),
            headerName: 'Paid On',
            field: 'createdAt',
        },
    ];

    useEffect(() => {
        if (id) {
            const queries = {
                search: debouncedSearchValue,
                page: currentPage,
                limit: numOfPages,
                startDate: selectedDates?.startDate,
                endDate: selectedDates?.endDate,
            };
            const getPaidStudents = async () => {
                const res = await dispatch(getPaidFull(id, queries));
                setPaidStudents(res);
            };
            getPaidStudents();
        }
    }, [id, debouncedSearchValue, currentPage, numOfPages, dispatch, setPaidStudents, selectedDates]);

    const handleDateChange = (startDate, endDate) => {
        setSelectedDates({ startDate, endDate });
    };

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                // data={paidStudents || []}
                // fields={fields}
                // loading={loading}
                customTable={<CustomTable colDefs={colDefs} data={paidStudents || []} />}
                tableUtilsChildren={<DatePicker onDateChange={handleDateChange} />}
                // isPaginated={true}
                // paginationComponent={
                //     <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                //         <PaginationStatus
                //             currentPage={currentPage}
                //             itemsPerPage={tableSettings?.numOfPages}
                //             totalItems={totalNoOfStudents}
                //         />
                //         <Pagination
                //             pageRangeDisplayed={3}
                //             totalPages={totalPages}
                //             onPageChange={(nextPage) => {
                //                 setCurrentPage(nextPage);
                //             }}
                //             currentPage={currentPage}
                //         />
                //     </FlexRowSpaceBetween>
                // }
            />

            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                // chooseAction={} exportAction={}
            />
        </>
    );
};

export default PaidStudents;
