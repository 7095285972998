import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../../assets/icons/arrow-down2.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download.svg';
import { ReactComponent as PeopleIcon } from '../../../../../assets/icons/peopleIcon.svg';
import { ActionsPopup } from '../../../../../components';
import { Button, LoadingButton } from '../../../../../components/buttons';
import { CancelButton } from '../../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../../components/go-back';
import TextAreaInput from '../../../../../components/inputs/text-area';
import TextInput from '../../../../../components/inputs/text-input-with-formik';
import { Loader } from '../../../../../components/loader';
import ImportAcademicSettings from '../../../../../components/popups/import-settings';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { RelativeContainer, FlexRowSpaceBetween } from '../../../../../containers/ScreenContainers';
import usePersistedState from '../../../../../hooks/usePersistedState';
import {
    createAcademicStanding,
    getAcademicStandingSettings,
    getDegreeSetting,
    importAcademicStanding,
} from '../../../../../redux/settings/actions';
import { generateLevels } from '../../../../../utils';
import { localStoreKeys } from '../../../../../utils/localStore';

import { ValidationSchema } from './ValidationSchema';

const Heading = tw.h3`
    font-semibold
    text-[1.9rem]
    text-[#1F2937]
    leading-[2.4rem]
    mt-8
    mb-5
`;

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const MainContainer = tw.div`
    bg-white
    px-[3rem]
    py-[3rem]
    rounded-2xl
`;

const STEP = 0.01;
const MIN = 0.0;

const AcademicStanding = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { sessions, activeSession } = useSelector((state) => state.admission);
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentProgramme } = useSelector((state) => state.programmes);
    const { isLoading, academicStandingSettings, degreeSettings } = useSelector((state) => state.settings);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const { _id: firstDegreeId } = currentFaculty;
    const { departmentId, studyDuration, academicStructure = '', academicStructureId } = currentDepartment;
    const { _id: programId } = currentProgramme || {};
    const { degreePoint } = degreeSettings || {};

    const splitStruct = academicStructure.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const [openDropdown, setOpenDropdown] = useState(null);
    const [altLoading, setAltLoading] = useState(false);
    const [session, setSession] = usePersistedState(activeSession || '', localStoreKeys.SelectedSession);
    const levels = generateLevels(studyDuration);

    const createFormikLevelsData = () => {
        const formikLevelsData = {};
        levels?.forEach((num) => {
            formikLevelsData[num] = 0;
        });
        return formikLevelsData;
    };

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const handleImportAcademicStanding = async (values) => {
        const res = await dispatch(importAcademicStanding(academicStructureId, lastDegreeId, values));
        if (res) {
            setOpenDropdown(null);
        }
    };

    useEffect(() => {
        if (!session) return;
        dispatch(getAcademicStandingSettings(academicStructureId, lastDegreeId, session));
    }, [academicStructureId, dispatch, lastDegreeId, session]);

    useEffect(() => {
        if (!session) return;
        dispatch(getDegreeSetting(academicStructureId, firstDegreeId, session));
    }, [academicStructureId, dispatch, firstDegreeId, session]);

    if (isLoading) return <Loader />;

    return (
        <div className="max-w-[100.3rem]">
            <GoBack title="Settings" subtitle="Academic Standing" />

            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle weight="600" size="2.3rem" top="3.6rem" lineHeight="3.4rem" bottom="3.7rem" align="left">
                    Academic Standing
                </PageTitle>
                <Button border="0.5px solid #E5E7EB" onClick={() => handleOpenDropdown('import')}>
                    <DownloadIcon />
                    Import Academic Standing
                </Button>
            </FlexRowSpaceBetween>
            <MainContainer>
                {!degreePoint && (
                    <Text color="red" align="left" bottom="2rem">
                        Organisation needs to set the GPA scale in class of degree settings!
                    </Text>
                )}

                <Formik
                    initialValues={{
                        message: academicStandingSettings?.message || '',
                        levels: academicStandingSettings?.levels || { ...createFormikLevelsData() },
                    }}
                    validationSchema={ValidationSchema}
                    enableReinitialize
                    onSubmit={async (values) => {
                        if (!session) return toast.error('Kindly select a session to add this setting to.');
                        setAltLoading(true);

                        const res = await dispatch(
                            createAcademicStanding({
                                academicStructureId,
                                lastDegreeId,
                                session,
                                payload: values,
                                useLoader: false,
                            }),
                        );
                        if (res) {
                            toast.success('Settings saved successfully!');
                        }
                        setAltLoading(false);
                    }}
                >
                    {({ errors, values, setFieldValue }) => (
                        <Form>
                            <RelativeContainer className="max-w-max my-12">
                                <Button
                                    color="#6B7280"
                                    type="button"
                                    border="1px solid #9CA3AF"
                                    onClick={() => handleOpenDropdown('sessions')}
                                >
                                    {session || 'Session'}
                                    <Dropdown />
                                </Button>
                                <ActionsPopup
                                    open={openDropdown === 'sessions'}
                                    close={() => setOpenDropdown(null)}
                                    items={actionItemSessions}
                                />
                            </RelativeContainer>

                            <div className="flex items-center gap-[1.6rem] mb-5">
                                <PeopleIcon />
                                <Text size="1.6rem" weight="600" align="left">
                                    Set Academic Standing Conditions
                                </Text>
                            </div>
                            <TextAreaInput
                                label="Input Message"
                                value={values.message}
                                name="message"
                                height="8rem"
                                errorMessage={errors.message}
                                onChange={(e) => setFieldValue('message', e.target.value)}
                                placeholder={
                                    'e.g. "That the following students who at the end of Second Semester of 200 level are NOT in GOOD STANDING (i.e having CGPA) that is less than 2.50 in both semesters for the session be advised to withdraw from  the degree programme."'
                                }
                            />

                            <Heading>Choose Minimum CGPA for Good Standing</Heading>

                            <div className="gap-8 flex flex-col">
                                {levels?.map((level) => {
                                    const fieldName = `levels.${level}`;
                                    return (
                                        <div key={level}>
                                            <TextInput
                                                name={fieldName}
                                                type="number"
                                                label={`${level} Level`}
                                                step={STEP}
                                                placeholder="Enter minimum CGPA"
                                                min={MIN}
                                                max={degreePoint}
                                                value={values.levels[`${level}`]}
                                                errors={errors}
                                                onChange={(e) => {
                                                    if (!degreePoint) return;
                                                    const value = e.target.value;
                                                    if (value > degreePoint) {
                                                        toast.error(
                                                            `You can't set a value greater than ${degreePoint}`,
                                                        );
                                                        return;
                                                    }
                                                    setFieldValue(fieldName, value);
                                                }}
                                                width="31rem"
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={altLoading} disabled={!session || altLoading} type="submit">
                                    Save
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
            <ImportAcademicSettings
                show={openDropdown === 'import'}
                close={() => setOpenDropdown(null)}
                sessions={sessions}
                title={'Import Academic Standing Settings'}
                onSubmitAction={(values) => handleImportAcademicStanding(values)}
            />
        </div>
    );
};

export default AcademicStanding;
