import React from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';
import CentreModal from '../../modal/CentreModal';

const ReceiversList = ({ show, close, Receivers }) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[33.8rem] max-h-[60rem]">
                <FlexRowSpaceBetween>
                    <Text as="h3" size="1.9rem" weight="600">
                        List of receivers
                    </Text>
                    <CloseIcon onClick={close} />
                </FlexRowSpaceBetween>
                <Text align="start" size="1.6rem" color="#1F2937" top="1.6rem" lineHeight="2.4rem" bottom="3.2rem">
                    Receivers
                </Text>
                <ul className="overflow-y-auto border-b pb-2">
                    {Receivers?.map((item, i) => (
                        <Text
                            key={i}
                            align="start"
                            size="1.6rem"
                            color="#374151"
                            top="1.6rem"
                            lineHeight="2.4rem"
                            weight="400"
                        >
                            {item}
                        </Text>
                    ))}
                </ul>

                <FlexRowEnd className="gap-x-[16px]">
                    <Button onClick={close} border="0.5px solid #D1D5DB">
                        Close
                    </Button>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default ReceiversList;
