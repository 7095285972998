import { Tab } from '@headlessui/react';
import clsx from 'clsx';

// interface TabItem {
//   label: string;
//   badge?: { color: string; text: string }; // Optional badge
// }

// interface NavTabsProps {
//   tabs: TabItem[];
// }

const NavTabs = ({ tabs, children, defaultIndex = 0, selectedIndex, className, onClick }) => {
    const handleTabClick = (tabName, tabIndex) => {
        onClick?.(tabName, tabIndex);
    };

    return (
        <Tab.Group
            defaultIndex={defaultIndex}
            // onChange={(index) => {
            //     console.log('Changed selected tab to:', index)
            //   }}
            selectedIndex={selectedIndex && selectedIndex}
            // onChange={setSelectedIndex}
        >
            <Tab.List className={clsx('flex w-full gap-4 no-scrollbar my-5 whitespace-nowrap', className)}>
                {tabs.map((tab, idx) => (
                    <Tab
                        key={tab.label}
                        onClick={() => handleTabClick(tab.label, idx)}
                        // disabled
                        className={({ selected }) =>
                            clsx(
                                'flex items-center border-b-[3px] px-2 pb-2 gap-2 focus-visible:outline-none text-[1.4rem] font-medium',
                                selected ? ' border-[#6366F1] text-[#6366F1]' : 'text-gray-500 hover:text-gray-700',
                            )
                        }
                    >
                        {tab.label}
                        {tab.badge ? (
                            <span
                                className={clsx(
                                    'bg-[#E5E7EB] px-[6px] py-[2px] rounded-full text-[#1F2937] font-semibold text-[1.3rem]',
                                    tab.badge.color,
                                )}
                            >
                                {tab.badge.text}
                            </span>
                        ) : null}
                    </Tab>
                ))}
            </Tab.List>

            <Tab.Panels className="mt-4">
                {/* // Each child must be wrapped in Tab.Panel */}
                {/* <Tab.Panel>{TabContent}</Tab.Panel> */}
                {children}
            </Tab.Panels>
        </Tab.Group>
    );
};
export { Tab };

export default NavTabs;
