import React from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';
import CentreModal from '../../modal/CentreModal';

const ExportSheetDialogue = ({ show, close, chooseAction, exportAction }) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[59.2rem]">
                <FlexRowSpaceBetween>
                    <Text as="h3" size="1.9rem" weight="600">
                        Export Sheet
                    </Text>
                    <CloseIcon onClick={close} />
                </FlexRowSpaceBetween>
                <Text align="start" size="1.6rem" color="#374151" top="1.6rem" bottom="3.2rem">
                    Do you want to export all columns?
                </Text>
                <FlexRowEnd className="gap-x-[16px]">
                    <Button onClick={chooseAction} color="#6366F1" border="0.5px solid #D1D5DB">
                        Choose Columns
                    </Button>
                    <Button onClick={exportAction} bgColor="#6366F1" color="#fff">
                        Export
                    </Button>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default ExportSheetDialogue;
