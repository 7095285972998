import React, { useEffect, useMemo, useState } from 'react';

// import { Table } from '@belrald_hq/belrald-ui';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';

import { ReactComponent as CancelIcon } from '../../../../../../../assets/icons/circular-cancel-red.svg';
import { ReactComponent as CheckGreenIcon } from '../../../../../../../assets/icons/circular-mark-green.svg';
import { ReactComponent as CheckWhiteIcon } from '../../../../../../../assets/icons/circular-mark-white-sm.svg';
import { ReactComponent as DropdownIcon } from '../../../../../../../assets/icons/dropdown.svg';
// import { ReactComponent as SendIcon } from '../../../../../../../assets/icons/send-green.svg';
import { ActionsPopup } from '../../../../../../../components';
import { Button } from '../../../../../../../components/buttons';
import ConfirmApproval from '../../../../../../../components/popups/confirm-approval';
import ConfirmApprovalWithInput from '../../../../../../../components/popups/confirm-approval-with-input';
import ConfirmCourseFormApproval from '../../../../../../../components/popups/confirm-course-form-approval';
import { StatusContainer, getStatusStyles } from '../../../../../../../components/shared';
import { Text } from '../../../../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, RelativeContainer } from '../../../../../../../containers/ScreenContainers';
import PageLayout from '../../../../../../../layout/page-layout/PageLayout';
import {
    approveCourse,
    advisorApproveCourseForm,
    getStudentRegisteredCourseForm,
    rejectCourse,
    advisorRejectCourseForm,
} from '../../../../../../../redux/course-form-magt/actions';

// const carryOverFields = [
//     {
// cell: (props) => <p>{props?.getValue()}</p>,//
// header: '',
//         accessorKey: 'action',
//     },
//     {
// cell: (props) => <p>{props?.getValue()}</p>,//
// header: 'Course Code',
//         accessorKey: 'courseCode',
//     },
//     {
// cell: (props) => <p>{props?.getValue()}</p>,//
// header: 'Times Failed',
//         accessorKey: 'failed',
//     },
//     {
// cell: (props) => <p>{props?.getValue()}</p>,//
// header: 'Unit',
//         accessorKey: 'courseUnit',
//     },
//     {
// cell: (props) => <p>{props?.getValue()}</p>,//
// header: 'Type',
//         accessorKey: 'courseClass',
//     },
//     {
// cell: (props) => <p>{props?.getValue()}</p>,//
// header: 'Status',
//         accessorKey: 'approval',
//     },
// ];

const DarkText = styled(Text)`
    text-align: left;
    font-size: 1.6rem;
    font-weight: 600;
`;

// const LightText = styled(Text)`
//     color: #9ca3af;
// `;

const Card = tw.div`
    bg-white
    p-6
    rounded-lg
    mb-3
`;

// const EmptyBox = tw.div`
//     bg-white
//     flex
//     justify-center
//     items-center
//     min-h-[30vh]
// `;

const OverViewItem = ({ title, subtitle }) => {
    return (
        <div>
            <Text color="#9CA3AF" size="1.2rem" lineHeight="1.7rem" align="left" weight="500">
                {title}
            </Text>
            <Text color="#4B5563" weight="500" lineHeight="2.4rem" align="left">
                {subtitle}
            </Text>
        </div>
    );
};

const Status = ({ status = '' }) => {
    return (
        <StatusContainer style={getStatusStyles(status?.toLowerCase())}>
            <span>{status}</span>
        </StatusContainer>
    );
};

const fields = [
    {
        // cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Course Code',
        accessorKey: 'courseCode',
    },
    {
        // cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Title',
        accessorKey: 'courseTitle',
    },
    {
        // cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Unit',
        accessorKey: 'courseUnit',
    },
    {
        header: 'Type',
        accessorKey: 'courseClass',
        cell: (props) => <Status status={props.getValue()} />,
    },
    {
        header: 'Status',
        accessorKey: 'approval',
        cell: (props) => <Status status={props.getValue()} />,
    },
];

const Form = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: studentId } = useParams();
    const { state } = useLocation();
    const { session, semester, level } = state || {};

    const { isLoading } = useSelector((state) => state.courseForm);
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentProgramme } = useSelector((state) => state.programmes);

    const { departmentId, academicStructure, academicStructureId } = currentDepartment || {};
    const { _id: programId } = currentProgramme || {};

    const splitStruct = academicStructure?.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const secondDegreeStruct = splitStruct?.[1] || '';

    const [details, setDetails] = useState({});
    const [openModal, setOpenModal] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [rejectionReason, setRejectionReason] = useState('');
    const [toggleRefetchDetails, setToggleRefetchDetails] = useState(false);
    const [advisorNotes, setAdvisorNotes] = useState(
        "Your Course Form is in good shape and you're eligible to continue with your registrations.",
    );

    const { registeredCourseForStudent: courseDetails } = details || {};
    const { registeredCourses = [] } = courseDetails || {};

    const totalUnits = useMemo(() => {
        const total = registeredCourses?.reduce((acc, item) => acc + item.courseUnit, 0);
        return total || '';
    }, [registeredCourses]);

    const isAllCoursesNotApproved = useMemo(
        () =>
            registeredCourses?.some(
                (item) => item?.approval?.toLowerCase() === 'pending' || item?.approval?.toLowerCase() === 'rejected',
            ),
        [registeredCourses],
    );

    const sharedParams = useMemo(() => {
        const params = {
            academicStructureId,
            lastDegreeId,
            session: state?.session,
            studentId,
            level,
            semester,
        };
        return params;
    }, [academicStructureId, lastDegreeId, level, semester, state?.session, studentId]);

    const handleApproveCourseForm = async () => {
        const payload = {
            advisorNotes,
        };
        const res = await dispatch(advisorApproveCourseForm({ ...sharedParams, payload }));
        if (res) {
            toast.success('This course form has been approved successfully');
            navigate(-1);
        }
    };

    const handleRejectCourseForm = async () => {
        const payload = { rejectionReason: rejectionReason };
        const res = await dispatch(advisorRejectCourseForm({ ...sharedParams, payload }));

        if (res) {
            toast.success('This course form has been rejected successfully');
            navigate(-1);
        }
    };

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const handleApproveCourse = async () => {
        const payload = { courses: selectedCourses.map((item) => item.courseCode) };
        const res = await dispatch(approveCourse({ ...sharedParams, payload }));

        if (res) {
            setOpenModal('');
            setToggleRefetchDetails((prev) => !prev);
        }
    };

    const handleRejectCourse = async () => {
        const payload = { rejectionReason, courses: selectedCourses.map((item) => item.courseCode) };
        const res = await dispatch(rejectCourse({ ...sharedParams, payload }));

        if (res) {
            setOpenModal('');
            setToggleRefetchDetails((prev) => !prev);
        }
    };

    useEffect(() => {
        if (studentId && lastDegreeId && session && level && semester) {
            const getDetails = async () => {
                const params = { academicStructureId, lastDegreeId, session, level, semester, studentId };
                // const data = await dispatch(getStudentCourseForm(lastDegreeId, studentId));
                const data = await dispatch(getStudentRegisteredCourseForm(params));
                if (data) return setDetails(data);
                setDetails({});
            };

            getDetails();
        }
    }, [toggleRefetchDetails, academicStructureId, dispatch, lastDegreeId, level, semester, session, studentId]);

    return (
        <>
            <Card>
                <FlexRowSpaceBetween>
                    <Text weight="600" size="1.9rem" lineHeight="2.8rem">
                        Overview
                    </Text>

                    <div className="flex gap-4">
                        <Button
                            color="#059669"
                            border="1px solid #E5E7EB"
                            onClick={() => setOpenModal('approve-course')}
                            disabled={!selectedCourses?.length || !isAllCoursesNotApproved}
                        >
                            <CheckGreenIcon className="mr-2" />
                            Approve Course
                        </Button>

                        <RelativeContainer>
                            <Button border="1px solid #E5E7EB" onClick={() => handleOpenDropdown('course')}>
                                <DropdownIcon stroke="#059669" fill="#059669" />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'course'}
                                close={() => setOpenDropdown(null)}
                                items={[
                                    {
                                        icon: <CancelIcon />,
                                        name: 'Reject Course',
                                        disabled: !selectedCourses?.length,
                                        color: 'text-[#EF4444]',
                                        click: () => {
                                            setOpenModal('reject-course');
                                            setOpenDropdown(false);
                                        },
                                    },
                                ]}
                            />
                        </RelativeContainer>

                        <Button
                            bgColor="#6366F1"
                            color="#fff"
                            disabled={isAllCoursesNotApproved}
                            onClick={() => setOpenModal('approve-form-reason')}
                        >
                            <CheckWhiteIcon className="mr-2" />
                            Approve Course Form
                        </Button>
                        <RelativeContainer>
                            <Button bgColor="#6366F1" color="#fff" onClick={() => handleOpenDropdown('course-form')}>
                                <DropdownIcon />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'course-form'}
                                close={() => setOpenDropdown(null)}
                                items={[
                                    {
                                        icon: <CancelIcon />,
                                        name: 'Reject Course Form',
                                        color: 'text-[#EF4444]',
                                        disabled: !registeredCourses?.length,
                                        click: () => {
                                            setOpenModal('reject-form-reason');
                                            setOpenDropdown(false);
                                        },
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    </div>
                </FlexRowSpaceBetween>

                <div className="mt-10 flex justify-between">
                    <OverViewItem title="Session" subtitle={session} />
                    <OverViewItem title="Semester" subtitle={semester} />
                    <OverViewItem title="No. of courses selected" subtitle={registeredCourses?.length || 0} />
                    <OverViewItem title="Cumulative Units" subtitle={`${totalUnits}/${details?.maximumUnit || ''}`} />
                </div>
            </Card>

            {/* {!details?.carryOverCourses?.length &&
            !registeredCourses?.length &&
            !details?.rejectedCourses?.length ? (
                <>
                    <EmptyBox>
                        <DarkText>No Course form to display</DarkText>
                    </EmptyBox>
                </>
            ) : ( */}
            <>
                <Card>
                    <DarkText>Select Course(s)</DarkText>
                    {/* <Table
                        width={'100%'}
                        columns={fields}
                        data={tableData || []}
                        loading={isLoading}
                        isActionable
                        actionType="checkbox"
                        onSelectedItemsChange={(items) => setSelectedCourses(items)}
                    /> */}
                    <PageLayout
                        noMargin
                        applySettings={false}
                        actionType="checkbox"
                        loading={isLoading}
                        isActionable
                        searchable={false}
                        fields={fields}
                        data={registeredCourses || []}
                        onSelectedItemsChange={(items) => setSelectedCourses(items)}
                    />
                    {/* <LightText align="left">
                            **the disabled courses will be approved by the host departments
                        </LightText> */}
                </Card>
                {/* <Card>
                        <FlexRowSpaceBetween>
                            <LightText>No of Courses Selected: {courses?.registeredCourses?.length}</LightText>
                            <LightText>Total Units Selected: {calcTotalUnits(courses?.registeredCourses)}</LightText>
                        </FlexRowSpaceBetween>
                    </Card> */}

                {/* <Card>
                    <DarkText>Non-Registered Course(s)</DarkText>
                    <Table
                        items={details?.rejectedCourses || []}
                        fields={fields}
                        selectable
                        loading={isLoading}
                        responsive
                        sorter
                        scopedSlots={{
                            action: (item) => (
                                <td>
                                    <Checkbox />
                                </td>
                            ),
                            courseClass: (item) => (
                                <td>
                                    <Status status={item?.courseClass} />
                                </td>
                            ),
                            approval: (item) => (
                                <td>
                                    <Status status={item?.approval} />
                                </td>
                            ),
                        }}
                    />
                </Card> */}
                {/* <Card>
                        <FlexRowSpaceBetween>
                            <LightText>No of Courses Selected: {courses?.rejectedCourses?.length}</LightText>
                            <LightText>Total Units Selected: {calcTotalUnits(courses?.rejectedCourses)}</LightText>
                        </FlexRowSpaceBetween>
                    </Card> */}
                {/* <Card>
                    <DarkText>Carry-Over Course(s)</DarkText>
                    <Table
                        items={details?.carryOverCourses || []}
                        fields={carryOverFields}
                        selectable
                        loading={isLoading}
                        responsive
                        sorter
                        scopedSlots={{
                            action: (item) => (
                                <td>
                                    <Checkbox />
                                </td>
                            ),
                            courseClass: (item) => (
                                <td>
                                    <Status status={item?.courseClass} />
                                </td>
                            ),
                            approval: (item) => (
                                <td>
                                    <Status status={item?.approval} />
                                </td>
                            ),
                        }}
                    />
                </Card> */}
                {/* <Card>
                        <FlexRowSpaceBetween>
                            <LightText>No of Courses Selected: {courses?.carryOverCourses?.length}</LightText>
                            <LightText>Total Units Selected: {calcTotalUnits(courses?.carryOverCourses)}</LightText>
                        </FlexRowSpaceBetween>
                    </Card> */}
                {/* {courseDetails?.status === 'Not Submitted' && (
                    <FlexCentredRow className="justify-end gap-[2.4rem] mt-[4rem]">
                        <Button color="#D1D5DB" bgColor="#EF4444" onClick={() => setOpenModal('reject-reason')}>
                            Reject
                        </Button>
                        <Button color="#D1D5DB" bgColor="#059669" onClick={() => setOpenModal('approval-reason')}>
                            Approve
                        </Button>
                    </FlexCentredRow>
                )} */}

                <ConfirmCourseFormApproval
                    show={openModal === 'approve-form-reason'}
                    placeholder="Leave a note for the student..."
                    title="Confirm Approval"
                    value={advisorNotes}
                    setInputValue={setAdvisorNotes}
                    confirmAction={handleApproveCourseForm}
                    close={() => setOpenModal(null)}
                    isLoading={isLoading}
                    secondDegreeStruct={secondDegreeStruct}
                />

                <ConfirmApprovalWithInput
                    type="textarea"
                    show={openModal === 'reject-form-reason'}
                    placeholder="Reason"
                    bgColor="#EF4444"
                    title="Confirm Rejection"
                    confirmAction={handleRejectCourseForm}
                    close={() => setOpenModal(null)}
                    isLoading={isLoading}
                    value={rejectionReason}
                    setInputValue={setRejectionReason}
                />

                <ConfirmApproval
                    show={openModal === 'approve-course'}
                    title="Confirm Approval"
                    subtitle="The selected course(s) will be approved for the student"
                    // bgColor="#6366F1"
                    confirmAction={() => handleApproveCourse()}
                    close={() => setOpenModal(null)}
                    isLoading={isLoading}
                />

                <ConfirmApprovalWithInput
                    show={openModal === 'reject-course'}
                    title="Confirm Rejection"
                    subtitle="The selected course(s) will be rejected for the student"
                    placeholder="Reason"
                    bgColor="#EF4444"
                    confirmAction={() => handleRejectCourse()}
                    close={() => setOpenModal(null)}
                    isLoading={isLoading}
                    value={rejectionReason}
                    setInputValue={setRejectionReason}
                />
            </>
            {/* )} */}
        </>
    );
};

export default Form;
