import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as PlusCircle } from '../../../assets/icons/circlePlus.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icons/Minus-Circle.svg';
import { ConfirmActionDialogue } from '../../../components';
import CustomTable from '../../../components/CustomTable';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { deleteAgent, getAllAgents } from '../../../redux/bursary/actions';
import { storeUnit } from '../../../utils/originUnit';

const AgentManagement = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { allAgents, isLoading } = useSelector((store) => store.bursary);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [openModal, setOpenModal] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [selectedItems, setSelectedItems] = useState(null);

    const fields = [
        {
            headerName: 'Company Name',
            field: 'companyName',
            cellRenderer: (props) => (
                <Link style={{ color: '#2563EB' }} to={props?.data?._id} state={{ agentDetails: props?.data }}>
                    {props.value}
                </Link>
            ),
        },
        {
            headerName: 'Company Email',
            field: 'companyEmail',
            cellRenderer: (props) => <p>{props?.value?.toLowerCase()}</p>,
        },
        {
            cellRenderer: (props) => (
                <p>{`${new Date(props?.value).toLocaleDateString()}, ${new Date(
                    props?.value,
                ).toLocaleTimeString()}`}</p>
            ),
            headerName: 'Created At',
            field: 'createdAt',
        },
    ];

    const handleCloseModal = () => {
        setInputValue('');
        setSearchValue('');
        setOpenModal(null);
        dispatch(getAllAgents());
    };

    const handleDeleteAgent = async () => {
        const res = await dispatch(deleteAgent(selectedItems._id));
        if (res) {
            handleCloseModal();
        }
    };

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.search = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        dispatch(getAllAgents(query));
    }, [dispatch, query]);

    return (
        <div className="">
            <PageLayout
                pageTitle="Agents Management"
                secondaryButtonText="Add Agent"
                // data={allAgents || []}
                // fields={fields}
                // isActionable
                // actionType="radio"
                // onSelectedItemsChange={(items) => setSelectedItems(items[0])}
                // loading={isLoading}
                customTable={
                    <CustomTable
                        colDefs={fields}
                        data={allAgents || []}
                        rowSelection
                        singleRowSelection
                        setSelectedItem={setSelectedItems}
                    />
                }
                actionItems={[
                    {
                        icon: <PlusCircle fill="#1f2937" />,
                        name: 'Attach Fee',
                        click: () => navigate('attach-fee', { state: { agent: selectedItems } }),
                        disabled: !selectedItems,
                    },
                    {
                        icon: <MinusIcon fill="#1f2937" />,
                        name: 'Detach Fee',
                        click: () => navigate('detach-fee', { state: { agent: selectedItems } }),
                        disabled: !selectedItems,
                    },
                    {
                        icon: <EditIcon fill="#1f2937" />,
                        name: 'Edit Agent',
                        click: () => navigate('edit-agent', { state: { agent: selectedItems } }),
                        disabled: !selectedItems,
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Agent',
                        click: () => setOpenModal('delete-agent'),
                        disabled: !selectedItems,
                    },
                ]}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                onSecondaryButtonClick={() => navigate('add')}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                // noItemView={
                //     <CenteredContainer className="mt-[5rem]">
                //         <ListEmptyContent
                //             isInTable
                //             title={searchValue ? '' : 'You have not created Agents yet!'}
                //             subtitle={searchValue ? 'No agents exist that match your search' : 'Create Agent'}
                //         >
                //             {!searchValue && (
                //                 <FlexCentredRow className="gap-x-4">
                //                     <Button bgColor="#6366F1" color="#fff" onClick={() => navigate('add')}>
                //                         <PlusWhite />
                //                         Add Agent
                //                     </Button>
                //                 </FlexCentredRow>
                //             )}
                //         </ListEmptyContent>
                //     </CenteredContainer>
                // }
            />

            <ConfirmActionDialogue
                borderLine
                input
                isLoading={isLoading}
                placeholder="Input delete"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                show={openModal === 'delete-agent'}
                title="Delete Agent"
                close={handleCloseModal}
                subtitle="The selected Agent will be deleted permanently"
                bodyText="To confirm, enter delete in the text field."
                btn2Text="Yes, Delete"
                bgColor={'#ef4444'}
                confirmAction={handleDeleteAgent}
                btnDisabled={inputValue?.toLowerCase() !== 'delete'}
            />
        </div>
    );
};

export default AgentManagement;
