import React, { useMemo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../components/loader';
import NavTabs, { Tab } from '../../components/nav-tabs/NavTabs';
import { PageTitle } from '../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../containers/ScreenContainers';
import { getProfileDetails, getStaffArenaDetails } from '../../redux/staff-arena/actions';

import Experience from './tabs/Experience';
import Overview from './tabs/overview';
import Payroll from './tabs/Payroll';
import Personal from './tabs/personal';
import Publications from './tabs/Publications';
import TeachingStaffExperience from './tabs/TeachingStaffExperience';
import UploadDetails from './tabs/Uploads';

const StaffProfile = () => {
    const dispatch = useDispatch();

    const { staffData: data } = useSelector((store) => store.user);
    const { staffBio, staffAcad, staff, staffUploads, staffPublications, payrollDetails } = useSelector(
        (store) => store.staffArena,
    );

    const tabs = useMemo(
        () => [
            { label: 'Overview' },
            { label: 'Bio-Data' },
            { label: 'Experience' },
            ...(data?.staffType === 'Teaching' ? [{ label: 'Publications' }, { label: 'Uploads' }] : []),
            { label: 'Payroll' },
        ],
        [data?.staffType],
    );

    useEffect(() => {
        if (!data) return;
        dispatch(getProfileDetails(data._id));
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        dispatch(getStaffArenaDetails());
    }, [dispatch]);

    if (!data) return <Loader />;

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Staff Arena</PageTitle>
            </FlexRowSpaceBetween>

            <NavTabs tabs={tabs}>
                <Tab.Panel>
                    <Overview details={staff} bio={staffBio} acad={staffAcad} />
                </Tab.Panel>
                <Tab.Panel>
                    <Personal details={staff} bio={staffBio} />
                </Tab.Panel>
                <Tab.Panel>
                    {data?.staffType === 'Teaching' ? (
                        <TeachingStaffExperience acad={staffAcad} />
                    ) : (
                        <Experience acad={staffAcad} />
                    )}
                </Tab.Panel>

                {data?.staffType === 'Teaching' && (
                    <>
                        <Tab.Panel>
                            <Publications pub={staffPublications} />
                        </Tab.Panel>
                        <Tab.Panel>
                            <UploadDetails uploads={staffUploads} />
                        </Tab.Panel>
                    </>
                )}
                <Tab.Panel>
                    <Payroll id={data?._id} payrollDetails={payrollDetails} />
                </Tab.Panel>
            </NavTabs>
        </div>
    );
};

export default StaffProfile;
