import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
// import { Button } from '../../../components/buttons';
// import ListEmptyContent from '../../../components/list-empty';
import CustomTable from '../../../components/CustomTable';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
// import { CenteredContainer } from '../../../containers/ScreenContainers';
import useSearch from '../../../hooks/useSearch';
import PageLayout from '../../../layout/page-layout/PageLayout';
import {
    // deleteDebtor,
    getAllDebtors,
} from '../../../redux/bursary/actions';
import { getDegreeInfo } from '../../../utils';
import currencyFormatter from '../../../utils/formatCurrency';

export const getSessionsOwed = (debts = []) => new Set(debts.map((debt) => debt.session)).size;

const DebtorsList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, allDebtors } = useSelector((state) => state.bursary);
    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((state) => state.academicStructure);

    const [searchValue, setSearchValue] = useState('');
    const filteredResults = useSearch(searchValue, allDebtors, ['studentFullName']);

    const [openModal, setOpenModal] = useState(null);
    // const [selectedItem, setSelectedItem] = useState(null);

    const fields = [
        {
            headerName: 'Name of Debtor',
            field: 'studentFullName',
            cellRenderer: (p) => (
                <p
                    style={{ color: '#2563EB', cursor: 'pointer' }}
                    onClick={() => navigate(p.data.studentId, { state: { debtor: p.data } })}
                >
                    {p.value}
                </p>
            ),
        },
        {
            headerName: 'Matric Number',
            field: 'studentMatricNumber',
        },
        {
            headerName: 'Number of sessions owed',
            field: 'noOfSessionOwed',
            cellRenderer: ({ data }) => {
                const sessionsOwed = getSessionsOwed(data.debts);
                return <p>{sessionsOwed || 0}</p>;
            },
        },
        {
            headerName: 'Total amount owed',
            field: 'amountOwed',
            cellRenderer: ({ data }) => {
                const totalAmountOwed = data?.debts?.reduce((acc, debt) => acc + debt.amountOwed, 0);
                return <p>{currencyFormatter.format(totalAmountOwed || 0)}</p>;
            },
        },
        {
            headerName: 'Date of last payment',
            field: 'dateOfLastPayment',
            cellRenderer: ({ data }) => (
                <p>
                    {data.installmentDetails?.deadlines?.[0]
                        ? format(new Date(data.installmentDetails.deadlines[0]), 'dd/MM/yyyy')
                        : 'N/A'}
                </p>
            ),
        },
        {
            headerName: firstDegreeOptionsStr,
            field: 'firstDegree',
            cellRenderer: ({ data }) => {
                const { firstDegree } = getDegreeInfo(data, 'structureType');
                return <p>{firstDegree}</p>;
            },
        },
        {
            headerName: secondDegreeOptionsStr,
            field: 'secondDegree',
            cellRenderer: ({ data }) => {
                const { secondDegree } = getDegreeInfo(data, 'structureType');
                return <p>{secondDegree}</p>;
            },
        },
    ];

    // const handleDelete = async () => {
    //     const res = await dispatch(deleteDebtor(selectedItem._id));
    //     if (res) {
    //         setOpenModal('');
    //     }
    // };

    useEffect(() => {
        dispatch(getAllDebtors());
    }, [dispatch]);

    return (
        <>
            <PageLayout
                pageTitle="Debt Management"
                secondaryButtonText="Add Debtor"
                // data={filteredResults || []}
                // fields={fields}
                // loading={isLoading}
                // isActionable
                // actionType="radio"
                // onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                onSecondaryButtonClick={() => navigate('/bursary/debtors-list/add')}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                customTable={<CustomTable colDefs={fields} data={filteredResults} />}
                // actionItems={[
                //     {
                //         icon: <DeleteIcon />,
                //         name: 'Delete Debtor',
                //         disabled: !selectedItem,
                //         click: () => setOpenModal('delete'),
                //     },
                // ]}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                searchValue={searchValue}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                showFilter
                width={'100%'}
                // noItemView={
                //     <CenteredContainer className="mt-[5rem]">
                //         <ListEmptyContent isInTable title="No Debtors Found" subtitle="Add a Debtor">
                //             <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                //                 <Button
                //                     onClick={() => navigate('/bursary/debtors-list/add')}
                //                     bgColor="#10B981"
                //                     color="#fff"
                //                 >
                //                     Add Debtor
                //                 </Button>
                //             </CenteredContainer>
                //         </ListEmptyContent>
                //     </CenteredContainer>
                // }
                openFilterPop={openModal === 'options-popup-main'}
                closeFilterPop={() => setOpenModal(null)}
            />

            <ConfirmActionDialogue
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete this debtor?"
                subtitle="This debtor alongside their history will be deleted totally."
                bodyText="Do you want to continue?"
                // confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                isLoading={isLoading}
            />
        </>
    );
};

export default DebtorsList;
