import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomTable from '../../../components/CustomTable';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getSavedAcademicStructure } from '../../../redux/academic-structure/actions';
import { SAVE_CURRENT_STRUCTURE } from '../../../redux/academic-structure/slice';
import { capitalizeEachWord } from '../../../utils';
import { storeUnit } from '../../../utils/originUnit';

const Academics = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, savedAcademicStructure } = useSelector((state) => state.academicStructure);
    const tableItems = Object.keys(savedAcademicStructure || {}).map((item) => ({ name: capitalizeEachWord(item) }));

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    useEffect(() => {
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    const fields = [
        {
            field: 'name',
            headerName: 'Name',
            cellRenderer: (props) => {
                return (
                    <p
                        style={{ color: '#2563EB' }}
                        className="cursor-pointer"
                        onClick={() => {
                            if (props.value?.toLowerCase() === 'school') return;
                            const structure = {
                                name: props?.data.name,
                                items: savedAcademicStructure[props?.data?.name?.toLowerCase()],
                            };
                            dispatch(SAVE_CURRENT_STRUCTURE(structure));
                            navigate(`faculties`);
                        }}
                    >
                        {props.value}
                    </p>
                );
            },
            width: 1100,
            suppressHeaderFilterButton: true,
        },
    ];

    return (
        <div>
            <PageLayout
                showTableUtils
                backTitle="Dashboard"
                backLink="/dashboard"
                backSubtitle="Academics"
                pageTitle="Academics"
                customTable={
                    <CustomTable
                        colDefs={fields}
                        data={tableItems || []}
                        isLoading={isLoading}
                        tableHeight={'300px'}
                        pagination={false}
                    />
                }
            />
        </div>
    );
};

export default Academics;
