import React, { useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as Warning } from '../../../assets/icons/warning.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { deleteRole } from '../../../redux/roles/actions';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../../forms/sharedStyles';
import TextInput from '../../inputs/text-input';
import CentreModal from '../../modal/CentreModal';

const INPUT_VALUE = 'delete';

const DeleteRole = ({ show, setShow, role, onFinish }) => {
    const dispatch = useDispatch();

    const handleClose = () => setShow(null);

    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const isBtnDisabled = () => inputValue !== INPUT_VALUE || isLoading;

    const handleDelete = async () => {
        setIsLoading(true);
        const res = await dispatch(deleteRole(role?._id));
        if (res) {
            setInputValue('');
            toast.success('Role deleted Successfully');
            onFinish();
            handleClose();
        }
        setIsLoading(false);
    };

    return (
        <CentreModal isOpen={show} onClose={() => handleClose()}>
            <div className="bg-white rounded-[8px] p-[3.2rem] w-[48.8rem]">
                <FlexRowSpaceBetween className="mb-[1.6rem]">
                    <FlexCentredRow className=" items-center  gap-[1.215rem]">
                        <Warning fill="#EF4444" />
                        <PageTitle size="1.9rem" lineHeight="2.8rem" weight="600">
                            Delete Role?
                        </PageTitle>
                    </FlexCentredRow>
                    <CloseIcon onClick={handleClose} />
                </FlexRowSpaceBetween>

                <Text align="left" bottom="2.4rem" size="1.6rem" lineHeight="2.4rem">
                    This role will be completely deleted
                </Text>
                <Text align="left" bottom="2.4rem" size="1.6rem" lineHeight="2.4rem">
                    To confirm deletion, enter '{INPUT_VALUE}' in the text field.
                </Text>
                <TextInput
                    type="text"
                    value={inputValue}
                    placeholder="Input delete"
                    onChange={(e) => setInputValue(e.target.value)}
                />

                <FlexRowEnd className="gap-[1.6rem] mt-8">
                    <CancelButton onClick={handleClose}>Cancel</CancelButton>
                    <LoadingButton onClick={handleDelete} disabled={isBtnDisabled()} bgColor="red" loading={isLoading}>
                        Yes, Delete
                    </LoadingButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default DeleteRole;
