import React, { useMemo } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { addStaffToUnit } from '../../../redux/administrators/actions';
import { getAcademicStaff, getNonAcademicStaff } from '../../../redux/staff/actions';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import CentreModal from '../../modal/CentreModal';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const AddStaffToUnit = ({ show, setShow, selectedStaff, tab, onFinish }) => {
    const dispatch = useDispatch();
    const onClose = () => setShow('');

    const { isLoading } = useSelector((state) => state.administrators);
    const { activeOrgUnits } = useSelector((store) => store.organisationUnits);

    const unitsToUse = useMemo(
        () =>
            activeOrgUnits
                ?.filter((item) => item.to)
                //don't show units already added to
                ?.filter((item) => !selectedStaff?.unitName?.includes(item.unitName))
                ?.sort((a, b) => a?.unitName?.localeCompare(b?.unitName)),
        [activeOrgUnits, selectedStaff],
    );

    return (
        <CentreModal isOpen={show} onClose={() => onClose()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[59.2rem]">
                <FlexRowSpaceBetween>
                    <Text as="h3" size="1.9rem" weight="600">
                        Add Staff to Unit(s)
                    </Text>
                    <CloseIcon onClick={onClose} className="cursor-pointer" />
                </FlexRowSpaceBetween>

                <Text align="left" size="1.6rem" bottom="1.6rem" color="#374151" top="1.6rem">
                    Add staff to new units
                </Text>

                <Formik
                    initialValues={{
                        organizationUnit: [],
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const payload = { organizationUnit: values.organizationUnit.map((item) => item.unitName) };

                        const res = await dispatch(addStaffToUnit(selectedStaff._id, payload));
                        if (res) {
                            tab === 0 ? dispatch(getAcademicStaff()) : dispatch(getNonAcademicStaff());
                            onFinish?.();
                            actions.resetForm();
                            toast.success('Staff added to unit(s) successfully!');
                            onClose();
                        }
                    }}
                >
                    {({ errors, setFieldValue, values }) => (
                        <Form>
                            <Select
                                multiSelect
                                name="organizationUnit"
                                objProp="unitName"
                                label="Organization Units"
                                placeholder="Select Unit(s)"
                                data={unitsToUse}
                                useComponentState={false}
                                passedSelectedItems={values.organizationUnit ? values.organizationUnit : null}
                                onChange={(selected) => {
                                    setFieldValue('organizationUnit', selected);
                                }}
                                error={errors.organizationUnit}
                            />

                            <div className="flex items-center gap-[2.4rem] mt-[2.9rem] justify-end">
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
                                    Save
                                </LoadingButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </CentreModal>
    );
};

export default AddStaffToUnit;
