import toast from 'react-hot-toast';

const handleApiError = (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 500) return;

    const errorMessage =
        error?.response?.data?.message ||
        error?.response?.data?.Message ||
        error?.data?.message ||
        'An error occurred, please try again.';

    toast.error(errorMessage);
    return false;
};

export default handleApiError;
