import React from 'react';

import { TextInput } from '@belrald_hq/belrald-ui';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';
import TextAreaInput from '../../inputs/text-area';
import CentreModal from '../../modal/CentreModal';

const ConfirmActionRadio = ({
    show,
    close,
    svgIcon,
    title,
    confirmAction,
    btn2Text,
    input,
    placeholder,
    bgColor,
    value,
    label,
    setInputValue,
    isLoading,
    withIcon = true,
    statuses,
    btnDisabled,
    selectedStatus,
    setSelectedStatus,
    textInput,
    setTextInputValue,
    textPlaceholder,
    textInputValue,
    textValue,
    list,
    listName,
    listNumber,
    percentage,
    text,
    percentagePlaceholder,
    percentageInputValue,
    setPercentageInputValue,
    listTitle,
    listTitle1,
    length,
}) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[59.2rem]">
                <FlexRowSpaceBetween>
                    <FlexCentredRow className="gap-[1.215rem]">
                        {withIcon ? svgIcon ? svgIcon : <WarningIcon fill="#EF4444" /> : null}
                        <Text as="h3" size="1.9rem" weight="600">
                            {title}
                        </Text>
                    </FlexCentredRow>
                    <CloseIcon onClick={close} className="cursor-pointer" />
                </FlexRowSpaceBetween>
                <div className="space-y-2 mb-[3rem]">
                    {statuses.map((status) => (
                        <FlexCentredCol key={status} className="mt-[3rem]">
                            <FlexCentredRow>
                                <input
                                    type="radio"
                                    name="status"
                                    value={status}
                                    checked={selectedStatus === status}
                                    onChange={(e) => setSelectedStatus(e.target.value)}
                                />
                                <Text className="ml-2">{status}</Text>
                            </FlexCentredRow>
                        </FlexCentredCol>
                    ))}
                </div>
                {percentage && (
                    <>
                        <Text align="left" size="1.6rem" weight="400" lineHeight="2.4rem" bottom="1rem">
                            {text}
                        </Text>
                        <TextInput
                            type="text"
                            placeholder={percentagePlaceholder}
                            onChange={(e) => setPercentageInputValue(e.target.value)}
                            value={percentageInputValue}
                        />
                    </>
                )}
                {list && (
                    <div className="overflow-y-auto max-h-40">
                        <div className="grid grid-cols-2 ">
                            <Text align="left" size="1.4rem" top="1.6rem" weight="500">
                                {listTitle}
                            </Text>
                            <Text align="left" size="1.4rem" top="1.6rem" weight="500">
                                {listTitle1}
                            </Text>
                            <Text align="left" size="1.4rem" color="#374151" top="0.5rem">
                                {listName}
                            </Text>
                            <Text align="left" size="1.4rem" color="#374151" top="0.5rem">
                                {listNumber}
                            </Text>
                        </div>
                        <Text align="left" size="1.2rem" lineHeight="1.7rem" weight="400" color="#6B7280">
                            {length}
                        </Text>
                    </div>
                )}

                {textInput && (
                    <>
                        <Text align="left" size="1.6rem" weight="400" lineHeight="2.4rem" bottom="2.4rem" top="2.4rem">
                            {textValue}
                        </Text>
                        <TextInput
                            type="text"
                            placeholder={textPlaceholder}
                            onChange={(e) => setTextInputValue(e.target.value)}
                            value={textInputValue}
                        />
                    </>
                )}
                {input && (
                    <TextAreaInput
                        label={label}
                        type="text"
                        placeholder={placeholder}
                        onChange={(e) => setInputValue(e.target.value)}
                        value={value}
                        height="7rem"
                    />
                )}
                <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                    <Button onClick={close} disabled={isLoading} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        disabled={btnDisabled || isLoading}
                        onClick={confirmAction}
                        bgColor={bgColor || '#6366F1'}
                        color="#fff"
                    >
                        {btn2Text || 'Confirm'}
                    </LoadingButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default ConfirmActionRadio;
