import { axiosInstance, currentAPI } from '../../config';
import handleApiError from '../handleApiError';

import { GET_PERMISSIONS, LOADING, STOP_LOADING } from './slice';

export const getPermissions = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}permission/:campusId/:unitId/get_all_permission`);
        if (response.status === 200) {
            dispatch(GET_PERMISSIONS(response.data.data));
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getPermissionsInUnit = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}permission/:campusId/:unitId/all_permissions_in_unit`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
