import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-sm.svg';
import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import CustomTable from '../../../components/CustomTable';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow } from '../../../containers/ScreenContainers';
import { useTableContext } from '../../../context/tableContext';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAgentDetails } from '../../../redux/bursary/actions';

const ViewAgent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { state } = useLocation();
    const { agentDetails: agent } = state || {};

    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((state) => state.academicStructure);

    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [agentDetail, setAgentDetail] = useState(null);
    // eslint-disable-next-line
    const [currentPage, setCurrentPage] = useState('1');
    const [showAccountInformation, setShowAccountInformation] = useState(false);

    const { students } = agentDetail || {};

    const {
        companyName,
        companyAddress,
        companyEmail,
        companyPhoneNumber,
        companyRegistrationNumber,
        createdBy,
        createdAt,
        agentAccountName,
        agentAccountNumber,
        agentAccountType,
        agentBankName,
    } = agent || {};

    useEffect(() => {
        const query = {
            search: debouncedSearchValue,
            page: currentPage,
            limit: numOfPages,
        };

        const fetchAgentDetails = async (query) => {
            const response = await dispatch(getAgentDetails(id, query));
            setAgentDetail(response);
        };
        fetchAgentDetails(query);
    }, [currentPage, debouncedSearchValue, dispatch, id, numOfPages]);

    const agentDetails = [
        {
            key: 'Company Name',
            value: companyName || '',
        },
        {
            key: 'Company Address',
            value: companyAddress || '',
        },
        {
            key: 'Company Email',
            value: companyEmail || '',
        },
        {
            key: "Company's Phone Number",
            value: companyPhoneNumber || '',
        },
        {
            key: 'Company Registration Number',
            value: companyRegistrationNumber || '',
        },
        {
            key: 'Created At',
            value: `${new Date(createdAt).toLocaleDateString()}, ${new Date(createdAt).toLocaleTimeString()}` || '',
        },
        {
            key: 'Created By',
            value: createdBy || '',
        },
    ];

    const accountInformation = [
        {
            key: 'Account Name',
            value: agentAccountName || '',
        },
        {
            key: 'Account Number',
            value: agentAccountNumber || '',
        },
        {
            key: 'Bank',
            value: agentBankName || '',
        },
        {
            key: 'Account Type',
            value: agentAccountType || '',
        },
    ];

    const fields = [
        {
            field: 'fullName',
        },
        {
            headerName: 'Matric No',
            field: 'matricNumber',
        },
        {
            headerName: firstDegreeOptionsStr,
            field: 'faculty',
        },
        {
            headerName: secondDegreeOptionsStr,
            field: 'program',
        },
        {
            field: 'admissionType',
        },
        {
            headerName: 'Admission Level',
            field: 'admittedLevel',
        },
        {
            headerName: 'Agent Fee',
            field: 'agentFeeAmount',
        },
        {
            field: 'paymentStatus',
        },
        {
            cellRenderer: (props) => <p>{new Date(props.value?.[0]).toLocaleDateString()}</p>,
            headerName: 'Date Added',
            field: 'dateAdded',
        },
    ];

    const modifiedStudentsData = students?.map((item) => {
        return {
            ...item,
            faculty: item?.college || item?.faculty || item?.school,
            program: item?.subProgram || item?.department,
        };
    });

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.3rem] gap-[0.5rem]">
                <BackArrow className="" />

                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <PageTitle
                    as="span"
                    size="1.6rem"
                    weight="400"
                    color="#6366F1"
                    lineHeight="2.8rem"
                    className="mx-[1.4rem]"
                >
                    /
                </PageTitle>
                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Agents Management
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                    /
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                    {companyName}
                </PageTitle>
            </FlexCentredRow>

            <FlexCentredCol>
                <PageTitle align="left">{companyName}</PageTitle>
            </FlexCentredCol>

            <div className="bg-white p-[2.4rem] rounded-[8px] my-[2.4rem]">
                <PageTitle align="left" bottom="2.4rem">
                    Overview
                </PageTitle>
                <div className="grid grid-cols-4 gap-[2.4rem]">
                    {agentDetails?.map((item, index) => (
                        <div className="">
                            <Text align={index !== 3 ? 'left' : 'right'} size="1.2rem" weight="500" color="#9CA3AF">
                                {item?.key}
                            </Text>
                            <Text align={index !== 3 ? 'left' : 'right'} size="1.4rem" weight="500" color="#4B5563">
                                {item?.value}
                            </Text>
                        </div>
                    ))}
                </div>
                <div className="">
                    <FlexCentredRow
                        className="pt-[3.2rem] gap-[1rem]"
                        onClick={() => setShowAccountInformation(!showAccountInformation)}
                    >
                        <Text size="1.6rem" weight="600" align="left">
                            Account Information
                        </Text>

                        <div className="">
                            <ArrowDown
                                onClick={() => {
                                    setShowAccountInformation(!showAccountInformation);
                                }}
                                className={`${
                                    showAccountInformation ? 'rotate-90' : 'rotate-0'
                                } cursor-pointer transform duration-100`}
                            />
                        </div>
                    </FlexCentredRow>

                    {showAccountInformation && (
                        <div className="grid grid-cols-4 gap-[2.4rem]">
                            {accountInformation?.map((item, index) => (
                                <div className="">
                                    <Text
                                        align={index !== 3 ? 'left' : 'right'}
                                        size="1.2rem"
                                        weight="500"
                                        color="#9CA3AF"
                                    >
                                        {item?.key}
                                    </Text>
                                    <Text
                                        align={index !== 3 ? 'left' : 'right'}
                                        size="1.4rem"
                                        weight="500"
                                        color="#4B5563"
                                    >
                                        {item?.value}
                                    </Text>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="bg-white py-[2rem] rounded-[8px] my-[2.4rem]">
                <div className="">
                    <PageLayout
                        tableTitle="Referred Students"
                        searchValue={searchValue}
                        onSearchChange={(e) => setSearchValue(e.target.value)}
                        onSearchClose={() => setSearchValue('')}
                        customTable={<CustomTable colDefs={fields} data={modifiedStudentsData || []} />}
                    />
                </div>
            </div>
        </div>
    );
};

export default ViewAgent;
