import { axiosInstance, currentAPI } from '../../config';
import handleApiError from '../handleApiError';

import { LOADING, STOP_LOADING } from './slice';

export const getInternalCourseForm =
    ({ academicStructureId, lastDegreeId, session, level, semester }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.get(
                `${currentAPI}courseForm/:campusId/:unitId/course_form/${academicStructureId}/${lastDegreeId}/${session}/${level}?semester=${semester?.toLowerCase()}`,
            );

            if (response.status === 200) {
                const { data } = response?.data || {};
                return data;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getStudentCourseForm = (programId, studentId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}courseForm/:campusId/:unitId/get_student_form/${programId}/${studentId}`,
        );

        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStudentRegisteredCourseForm =
    ({ academicStructureId, lastDegreeId, session, level, semester, studentId }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.get(
                `${currentAPI}courseForm/:campusId/:unitId/student_registered_courses/${academicStructureId}/${lastDegreeId}/${session}/${level}/${studentId}?semester=${semester?.toLowerCase()}`,
            );

            if (response.status === 200) {
                const { data } = response?.data || {};
                return data;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const advisorApproveCourseForm =
    ({ academicStructureId, lastDegreeId, session, level, semester, studentId, payload }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.put(
                `${currentAPI}courseForm/:campusId/:unitId/advisor_approval_of_course_form/${academicStructureId}/${lastDegreeId}/${session}/${level}/${studentId}?semester=${semester?.toLowerCase()}`,
                payload,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const advisorRejectCourseForm =
    ({ academicStructureId, lastDegreeId, session, level, semester, studentId, payload }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.put(
                `${currentAPI}courseForm/:campusId/:unitId/advisor_rejection_of_course_form/${academicStructureId}/${lastDegreeId}/${session}/${level}/${studentId}?semester=${semester?.toLowerCase()}`,
                payload,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const approveCourse =
    ({ academicStructureId, lastDegreeId, session, studentId, payload, level, semester }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.put(
                `${currentAPI}courseForm/:campusId/:unitId/approval_of_each_course/${academicStructureId}/${lastDegreeId}/${session}/${level}/${studentId}?semester=${semester.toLowerCase()}`,
                payload,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const rejectCourse =
    ({ academicStructureId, lastDegreeId, session, studentId, payload, level, semester }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.put(
                `${currentAPI}courseForm/:campusId/:unitId/rejection_of_course/${academicStructureId}/${lastDegreeId}/${session}/${level}/${studentId}?semester=${semester.toLowerCase()}`,
                payload,
            );

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };
