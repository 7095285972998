import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as PlusCircle } from '../../../../../../../../assets/icons/circlePlus.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../../../../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../../../../../../assets/icons/edit.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../../../../../../components';
import { Button } from '../../../../../../../../components/buttons';
import CustomTable from '../../../../../../../../components/CustomTable';
import { Text } from '../../../../../../../../containers/MesssageContainers';
import { FlexCentredRow, RelativeContainer } from '../../../../../../../../containers/ScreenContainers';
import PageLayout from '../../../../../../../../layout/page-layout/PageLayout';
import { deleteSalaryItemBonus, getStaffBonus } from '../../../../../../../../redux/bursary/actions';

import AddBonus from './add-bonus';
import EditBonus from './edit-bonus';

const Bonus = ({ isLoading }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [searchValue, setSearchValue] = useState('');
    const [openModal, setOpenModal] = useState(null);
    const [staffBonuses, setStaffBonuses] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const fields = [
        {
            headerName: 'Name',
            field: 'bonusName',
        },
        {
            headerName: 'Purpose/Description',
            field: 'reason',
        },
        {
            headerName: 'Amount',
            field: 'amount',
        },
    ];

    const fetchStaffBonus = async () => {
        const res = await dispatch(getStaffBonus(id));
        setStaffBonuses(res);
    };

    useEffect(() => {
        fetchStaffBonus();
        //eslint-disable-next-line
    }, [refetch]);

    const handleDeleteBonus = async () => {
        const res = await dispatch(deleteSalaryItemBonus(selectedItem?._id));
        if (res) {
            setRefetch(!refetch);
            setOpenModal(null);
        }
    };

    return (
        <div className="bg-white">
            <div className="border-b border-b-solid border-b-[#E5E7EB] py-[8px] px-[32px]">
                <Text align="left" weight="600" size="1.6rem">
                    Bonus
                </Text>
            </div>
            <PageLayout
                noMargin
                customTable={
                    <CustomTable
                        colDefs={fields}
                        data={staffBonuses || []}
                        rowSelection
                        singleRowSelection
                        setSelectedItem={setSelectedItem}
                    />
                }
                // fields={fields}
                // data={staffBonuses || []}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                loading={isLoading}
                tableUtilsChildren={
                    <FlexCentredRow className="gap-3">
                        <Button onClick={() => setOpenModal('add-bonus')} border="0.5px solid #E5E7EB">
                            <PlusCircle fill="#1F2937" />
                            <Text size="1.5rem" weight="500">
                                Add Bonus
                            </Text>
                        </Button>
                        <RelativeContainer>
                            <Button
                                disabled={!selectedItem}
                                bgColor="#6366F1"
                                color="#fff"
                                onClick={() => setOpenModal('actions')}
                            >
                                Actions
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openModal === 'actions'}
                                close={() => setOpenModal(null)}
                                items={[
                                    {
                                        icon: <EditIcon fill="#1f2937" />,
                                        name: 'Edit Bonus',
                                        click: () => setOpenModal('edit-bonus'),
                                    },
                                    {
                                        icon: <DeleteIcon />,
                                        name: 'Delete Bonus',
                                        click: () => setOpenModal('delete-bonus'),
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    </FlexCentredRow>
                }
                // isActionable
                // actionType="radio"
                // onSelectedItemsChange={(item) => setSelectedItem(item?.[0])}
            />
            <AddBonus
                show={openModal === 'add-bonus'}
                close={() => setOpenModal(null)}
                refetch={() => setRefetch(!refetch)}
                staffId={id}
            />
            <EditBonus
                editIcon={<EditIcon fill="#6366f1" />}
                show={openModal === 'edit-bonus'}
                close={() => setOpenModal(null)}
                refetch={() => setRefetch(!refetch)}
                bonusData={selectedItem}
            />
            <ConfirmActionDialogue
                show={openModal === 'delete-bonus'}
                close={() => setOpenModal(null)}
                title={'Delete Bonus'}
                subtitle={'Are you sure you want to delete this permanently?'}
                btn2Text={'Yes, Delete'}
                confirmAction={handleDeleteBonus}
            />
        </div>
    );
};

export default Bonus;
