import React from 'react';

import { ReactComponent as Checkmark } from '../../../assets/icons/circular-mark.svg';
// import checkmark from '../../../assets/icons/circular-mark.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { Button } from '../../buttons';
import CentreModal from '../../modal/CentreModal';

const SuccessPopup = ({
    show,
    type = 'success',
    title,
    subtitle,
    withButton = true,
    btnText,
    btnAction,
    onClose,
    svgIcon,
    children,
}) => {
    return (
        <CentreModal isOpen={show} onClose={() => onClose?.()}>
            <div className="bg-white rounded-[8px] px-[3.9rem] py-[3.5rem] min-w-[25rem]">
                <CloseIcon onClick={onClose} className="absolute top-[3.5rem] right-[3.9rem] cursor-pointer" />
                <div>
                    <div className="flex flex-col justify-center items-center mt-[40.3px] mb-[31px]">
                        {svgIcon || <Checkmark />}

                        <Text weight="600" top="3.1rem" bottom="1.6rem" size="4rem">
                            {title || 'Done!'}
                        </Text>
                        <Text size="1.6rem" bottom="3.2rem">
                            {subtitle}
                        </Text>

                        {withButton && (
                            <Button type="button" bgColor="#6366F1" color="#ffffff" onClick={btnAction || onClose}>
                                {btnText || 'Continue'}
                            </Button>
                        )}
                        {children}
                    </div>
                </div>
            </div>
        </CentreModal>
    );
};

export default SuccessPopup;
