import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as FlutterwaveIcon } from '../../../../../assets/icons/Flutterwave.svg';
import { ReactComponent as MonnifyIcon } from '../../../../../assets/icons/monify.svg';
import { ReactComponent as PaystackIcon } from '../../../../../assets/icons/paystack.svg';
import CustomTable from '../../../../../components/CustomTable';
import { GoBack } from '../../../../../components/go-back';
import NavTabs, { Tab } from '../../../../../components/nav-tabs/NavTabs';
import { Text } from '../../../../../containers/MesssageContainers';
import { FlexColumn, FlexDiv } from '../../../../../containers/ScreenContainers';
import { getPaymentGatewayById, getPaymentGatewayDashboardDetails } from '../../../../../redux/bursary/actions';
import { capitalizeFirstLetter } from '../../../../../utils';

import AccountDetails from './account-details';
import AttachedFees from './attached-fees';
import StatusRateChart from './rate-chart';
import RevenueChart from './revenue-chart';

const creditColDefs = [
    {
        field: 'payer',
    },
    {
        field: 'feeType',
    },
    {
        field: 'amount',
        valueFormatter: (p) => p?.value?.toLocaleString(),
    },
    {
        field: 'email',
    },
    {
        field: 'status',
    },
    {
        field: 'reference',
    },
    {
        field: 'channel',
        valueFormatter: (p) => capitalizeFirstLetter(p?.value?.split('_')?.join(' ')),
    },
    {
        field: 'transactionDate',
        valueFormatter: (p) => `${new Date(p?.value).toLocaleDateString()}, ${new Date(p?.value).toLocaleTimeString()}`,
    },
];

const debitColDefs = [
    {
        field: 'amount',
    },
    {
        field: 'feeType',
    },
    {
        field: 'amount',
        valueFormatter: (p) => p?.value?.toLocaleString(),
    },
    {
        field: 'reference',
    },
    {
        field: 'channel',
        valueFormatter: (p) => capitalizeFirstLetter(p?.value?.split('_')?.join(' ')),
    },
    {
        field: 'transactionDate',
        valueFormatter: (p) => `${new Date(p?.value).toLocaleDateString()}, ${new Date(p?.value).toLocaleTimeString()}`,
    },
];

const tabs = [{ label: 'Credit History' }, { label: 'Debit History' }];

const GatewayDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { paymentGateway, isLoading } = useSelector((store) => store.bursary);
    const { provider, businessName } = paymentGateway || {};

    const [dashboardDetails, setDashboardDetails] = useState({});

    const [successPeriod, setSuccessPeriod] = useState([{ label: 'Last 7 days', value: 7 }]);
    const [revenuePeriod, setRevenuePeriod] = useState([{ label: 'Last 7 days', value: 7 }]);

    const { wallet, dashboard, history } = dashboardDetails || {};

    useEffect(() => {
        if (!id) return;
        dispatch(getPaymentGatewayById(id));
    }, [dispatch, id]);

    const query = useMemo(() => {
        let queryParams = {};

        if (revenuePeriod) {
            queryParams.revenuePeriod = revenuePeriod?.[0]?.value;
        }
        if (successPeriod) {
            queryParams.successRatePeriod = successPeriod?.[0]?.value;
        }

        return queryParams;
    }, [successPeriod, revenuePeriod]);

    const fetchDashboardDetails = async () => {
        const res = await dispatch(getPaymentGatewayDashboardDetails(id, query));
        setDashboardDetails(res);
    };

    useEffect(() => {
        fetchDashboardDetails();
        //eslint-disable-next-line
    }, [successPeriod, revenuePeriod]);

    return (
        <div>
            <GoBack title="Payment Gateway" subTitle={businessName} />
            <div className="">
                <FlexDiv className="justify-between items-start">
                    <FlexColumn className="gap-[1rem]">
                        <Text align="left" weight="600" size="32px" lineHeight="38.73px">
                            {businessName}
                        </Text>
                        {provider?.toLowerCase() === 'paystack' ? (
                            <PaystackIcon />
                        ) : provider?.toLowerCase() === 'monnify' ? (
                            <MonnifyIcon />
                        ) : provider?.toLowerCase() === 'flutterwave' ? (
                            <FlutterwaveIcon />
                        ) : null}
                    </FlexColumn>
                </FlexDiv>
                <div className="grid grid-cols-2 gap-[2.4rem] pt-[4rem] h-[756px]">
                    <div className="flex flex-col gap-[1rem]">
                        <AccountDetails walletBalance={wallet?.balance || ''} isLoading={isLoading} />
                        <RevenueChart
                            revenueData={
                                dashboard?.revenueStat?.dailyRevenue?.map((item) => {
                                    return {
                                        date: new Date(item?.date).toLocaleDateString('en-US', {
                                            month: 'short',
                                            day: 'numeric',
                                        }),
                                        ...item,
                                    };
                                }) || []
                            }
                            setParams={setRevenuePeriod}
                            params={revenuePeriod}
                            isLoading={isLoading}
                            totalRevenue={dashboard?.revenueStat?.totalRevenue}
                        />
                    </div>
                    <div className="flex flex-col gap-[1rem]">
                        <AttachedFees attachedFees={dashboard?.feeTypes} isLoading={isLoading} />
                        <StatusRateChart
                            successRate={dashboard?.revenueStat?.successRate || {}}
                            setParams={setSuccessPeriod}
                            params={successPeriod}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
                <NavTabs tabs={tabs}>
                    <Tab.Panel>
                        <CustomTable colDefs={creditColDefs} data={history?.credit} />
                    </Tab.Panel>
                    <Tab.Panel>
                        <CustomTable colDefs={debitColDefs} data={history?.debit} />
                    </Tab.Panel>
                </NavTabs>
            </div>
        </div>
    );
};

export default GatewayDetails;
