import React from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';
import CentreModal from '../../modal/CentreModal';

const ConfirmApproval = ({ children, show, close, title, subtitle, confirmAction, bgColor, isLoading }) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[59.2rem]">
                <FlexRowSpaceBetween>
                    <FlexCentredRow>
                        <Text as="h3" size="1.9rem" weight="600">
                            {title}
                        </Text>
                    </FlexCentredRow>
                    <CloseIcon onClick={close} className="cursor-pointer" />
                </FlexRowSpaceBetween>
                <Text align="left" size="1.6rem" color="#374151" top="1.6rem">
                    {subtitle} <Text></Text>
                </Text>
                {children}
                <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                    <Button onClick={close} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={confirmAction}
                        bgColor={bgColor || '#6366F1'}
                    >
                        Confirm
                    </LoadingButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default ConfirmApproval;
