import React from 'react';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { ReactComponent as Closeicon } from '../../../assets/images/close.svg';
import CentreModal from '../../modal/CentreModal';

const FormContainer = ({ show, close, headerIcon, title, children }) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[16px] px-[3.1rem] pt-[6rem] pb-[3.6rem] w-[50rem]">
                <Closeicon className="absolute top-[2.9rem] right-[3.73rem] cursor-pointer" onClick={close} />
                <div className="flex gap-[0.715rem] mb-[3.2rem] items-center">
                    {headerIcon ? headerIcon : <PeopleIcon />}
                    <h2 className="text-[1.6rem] font-semibold leading-6 text-[#1F2937] mb-[0]">{title}</h2>
                </div>
                {children}
            </div>
        </CentreModal>
    );
};

export default FormContainer;
