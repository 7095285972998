/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import ListEmptyContent from '../../../../components/list-empty';
// import { PageTitle } from '../../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    // FlexColumn,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
} from '../../../../containers/ScreenContainers';
import { getDepartmentStaff } from '../../../../redux/departments/actions';
const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Full Name',
        key: 'fullName',
    },
    {
        label: 'Employment Date',
        key: 'yearOfEmployment',
    },
    {
        label: 'Employment Type',
        key: 'staffType',
    },
    {
        label: 'Email Address',
        key: 'email',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const DepartmentPersonnel = () => {
    const dispatch = useDispatch();
    const { isLoading, currentDepartment, staff } = useSelector((state) => state.department);

    const { _id: departmentId, academicStructureId } = currentDepartment || {};

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(staff?.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (departmentId) {
            dispatch(getDepartmentStaff(academicStructureId, departmentId));
        }
    }, [dispatch, departmentId, academicStructureId]);

    return (
        <>
            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>
                {/* <Table
                    items={staff || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    noItemsViewSlot={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent isInTable title="No Staff "></ListEmptyContent>
                        </CenteredContainer>
                    }
                    clickableRows
                    loading={isLoading}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        fullName: (item) => <td>{item.fullName}</td>,
                        yearOfEmployment: (item) => <td>{format(new Date(item.yearOfEmployment), 'PP')}</td>,
                    }}
                /> */}
            </div>
        </>
    );
};

export default DepartmentPersonnel;
