import React, { useState, useRef, useEffect } from "react";

import tw, { styled } from "twin.macro";

import { ReactComponent as SelectIcon } from "../../../assets/icons/arrow-down2.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search2.svg";
import Checkbox from "../checkbox";

const SearchInput = tw.input`
    w-full px-4 py-2 relative
    focus:outline-none rounded border
    placeholder:text-[1.4rem] placeholder:text-[#6B7280]
    border-[#e5e7eb] focus:ring-[#6366F1] focus:ring-2
    cursor-pointer
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 20px;
`;

const StepDropdown = ({ groupIds = [], onSelect, steps, searchable, searchPlaceholder = "Search" }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSteps, setSelectedSteps] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Handle click outside dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (groupId, stepId, stepName, isChecked) => {
    setSelectedSteps((prev) => {
      const updated = { ...prev };
  
      if (isChecked) {
        if (!updated[groupId]) updated[groupId] = [];
        updated[groupId].push({ stepId, stepName });
      } else {
        if (updated[groupId]) {
          updated[groupId] = updated[groupId].filter((step) => step?.stepId !== stepId);
          if (updated[groupId].length === 0) delete updated[groupId];
        }
      }
  
      onSelect(updated);
      return updated;
    });
  };
  

  const selectedGroups = steps?.filter(
    (group) => groupIds?.includes(group?._id) && group?.subGroups?.length > 0
  ) || [];

  const selectedStepNames =
    selectedGroups
      .flatMap((group) =>
        group.subGroups.filter((step) =>
          selectedSteps[group?._id]?.some((s) => s?.stepId === step?._id)
        ).map((step) => step?.stepName)
      )
      .join(", ") || "Select Step";

  const filteredGroups = selectedGroups
    .map((group) => ({
      ...group,
      subGroups: group?.subGroups?.filter((step) =>
        step?.stepName.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    }))
    .filter((group) => group?.subGroups?.length > 0);

  return (
    <div className="relative w-full cursor-default min-h-[4rem] border text-left text-[1.4rem] font-normal" ref={dropdownRef}>
      <button
        type="button"
        className="w-full px-3 py-2 bg-white text-left flex justify-between items-center outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="truncate">{selectedStepNames}</span>
        <SelectIcon className="ml-2" />
      </button>

      {isOpen && (
        <div className="absolute w-full mt-2 bg-white border rounded-md shadow-md z-10">
          {searchable && (
            <div className="py-2 relative">
              <SearchInput
                value={searchTerm}
                placeholder={searchPlaceholder}
                type="text"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IconWrapper>
                <SearchIcon />
              </IconWrapper>
            </div>
          )}

          <div className="max-h-60 overflow-y-auto p-2">
            {filteredGroups?.map((group) => (
              <div className="flex flex-col gap-[1rem] ml-2" key={group?._id}>
                <div className="font-semibold text-gray-700 ">{group?.groupName}</div>
                {group?.subGroups?.map((step) => (
                  <Checkbox
                    key={step?._id}
                    id={step?._id}
                    label={step?.stepName}
                    checked={selectedSteps[group?._id]?.some((s) => s?.stepId === step?._id)}
                    onChange={() =>
                      handleSelect(
                        group?._id,
                        step?._id,
                        step?.stepName,
                        !selectedSteps[group?._id]?.some((s) => s?.stepId === step?._id)
                      )
                    }
                    className="ml-[2rem] mb-[1rem]"
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default StepDropdown;
