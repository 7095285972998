import React, { useMemo, useState } from 'react';

import { Checkbox } from '../../../components';
import NavTabs, { Tab } from '../../../components/nav-tabs/NavTabs';
import { PageTitle } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { All, Bulletin, InternalMemo, Mail, Notice } from '../components';

const getBadgeCount = (count) => {
    if (!count && count !== 0) return '0';
    return count > 10 ? `${count}+` : `${count}`;
};

const activeClass = 'bg-[#FEE2E2] text-[#EF4444]';
const inActiveClass = 'bg-[#E5E7EB] text-[#1F2937]';

const Received = ({ unitName }) => {
    const url = window.location.href;
    const [activeTab, setActiveTab] = useState('all');

    const TableHeaders = [
        {
            header: <Checkbox />,
            size: 5,
            accessorKey: 'action',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'To',
            accessorKey: 'to',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Subject',
            accessorKey: 'subject',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Ref No.',
            accessorKey: 'ref',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Session',
            accessorKey: 'session',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Time Stamp',
            accessorKey: 'time',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        ...(activeTab === 'all'
            ? [
                  {
                      header: 'Type',
                      accessorKey: 'type',
                      cell: (props) => <p>{props?.getValue()}</p>,
                  },
              ]
            : []),
    ];

    const tabs = useMemo(
        () => [
            {
                label: 'All',
                badge: {
                    color: activeTab === 'all' ? activeClass : inActiveClass,
                    text: getBadgeCount(0),
                },
            },
            {
                label: 'Bulletin',
                badge: {
                    color: activeTab === 'bulletin' ? activeClass : inActiveClass,
                    text: getBadgeCount(0),
                },
            },
            {
                label: 'Internal Memo',
                badge: {
                    color: activeTab === 'internal memo' ? activeClass : inActiveClass,
                    text: getBadgeCount(0),
                },
            },
            {
                label: 'Notice',
                badge: {
                    color: activeTab === 'notice' ? activeClass : inActiveClass,
                    text: getBadgeCount(0),
                },
            },
            ...(!url.includes('staff-arena')
                ? [
                      {
                          label: 'Mail',
                          badge: {
                              color: activeTab === 'mail' ? activeClass : inActiveClass,
                              text: getBadgeCount(0),
                          },
                      },
                  ]
                : []),
        ],
        [activeTab, url],
    );

    const handleTabClick = (tabName) => {
        setActiveTab(tabName.toLowerCase());
    };

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Received</PageTitle>
            </FlexRowSpaceBetween>

            <NavTabs tabs={tabs} onClick={handleTabClick}>
                <Tab.Panel>
                    <All tableHeader={TableHeaders} />
                </Tab.Panel>
                <Tab.Panel>
                    <Bulletin tableHeader={TableHeaders} />
                </Tab.Panel>

                {url.includes('staff-arena') && (
                    <Tab.Panel>
                        <InternalMemo tableHeader={TableHeaders} />
                    </Tab.Panel>
                )}
                <Tab.Panel>
                    <Notice tableHeader={TableHeaders} />
                </Tab.Panel>
                {!url.includes('staff-arena') && (
                    <Tab.Panel>
                        <Mail tableHeader={TableHeaders} />
                    </Tab.Panel>
                )}
            </NavTabs>
        </div>
    );
};

export default Received;
