/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from 'twin.macro';

import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down-white.svg';
import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../../components';
import { Button } from '../../../../components/buttons';
import Checkbox from '../../../../components/inputs/checkbox';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween, RelativeContainer } from '../../../../containers/ScreenContainers';
import { deleteDomain, getCustomDomain } from '../../../../redux/ict/actions';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Domain',
        key: 'domain',
    },
    {
        label: 'Type',
        key: 'type',
    },
    {
        label: 'Status',
        key: 'status',
    },
    {
        label: '',
        key: 'view',
    },
    {
        label: '',
        key: 'delete',
    },
];

const StatusContainer = styled.div`
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
    width: fit-content;
`;

const CustomDomain = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { customDomains, isLoading } = useSelector((store) => store.ict);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [value, setValue] = useState('');
    const [selectedDomain, setSelectedDomain] = useState({});

    useEffect(() => {
        dispatch(getCustomDomain());
    }, [dispatch]);

    const deleteCustomDomain = async () => {
        const { domain, type } = selectedDomain;
        if (value !== domain) return toast.error('Domain name is wrong');
        const payload = { portalType: type, subDomainName: domain };
        const res = await dispatch(deleteDomain(payload));
        if (res) {
            setShowDelete(false);
            dispatch(getCustomDomain());
        }
    };

    const Domains = customDomains?.map((domain) => {
        return {
            domain: domain?.subDomainName,
            type: domain?.subDomainName?.includes('admin')
                ? 'admin'
                : domain?.subDomainName.includes('lecturer')
                ? 'lecturer'
                : 'student',
            status: domain?.verified === false ? 'Unverified' : 'Verified',
        };
    });

    const handleActionClick = (route) => {
        navigate(route);
        setDropdownOpen(false);
    };

    return (
        <>
            <FlexRowSpaceBetween>
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.6rem]">
                    <BackArrow className="mr-[8px]" />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Settings
                    </PageTitle>
                    <Text left="1rem" size="1.6rem">
                        / Custom Domains
                    </Text>
                </FlexCentredRow>
            </FlexRowSpaceBetween>
            <FlexCentredRow className="mb-[5.8rem] gap-x-[1rem]">
                <PageTitle weight="600" size="3.3rem">
                    Custom Domains
                </PageTitle>
            </FlexCentredRow>

            <FlexRowSpaceBetween>
                <FlexCentredRow onClick={() => navigate('')} className="hover:cursor-pointer">
                    <Text left="1rem" size="1.6rem">
                        Host your portals with custom domains.
                    </Text>
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem" left="2.5rem">
                        Learn more
                    </PageTitle>
                </FlexCentredRow>
                <RelativeContainer>
                    <Button
                        disabled={Domains?.length === 3}
                        bgColor="#6366F1"
                        color="#fff"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                    >
                        Add custom domain
                        <ArrowDown className="ml-[8px]" />
                    </Button>
                    <ActionsPopup
                        open={dropdownOpen}
                        close={() => setDropdownOpen(false)}
                        items={[
                            {
                                name: 'Admin Portal',
                                click: () => handleActionClick('add-admin'),
                            },
                            {
                                name: 'Lecturers Portal',
                                click: () => handleActionClick('add-lecturer'),
                            },
                            {
                                name: 'Student Portal',
                                click: () => handleActionClick('add-students'),
                            },
                        ]}
                    />
                </RelativeContainer>
            </FlexRowSpaceBetween>

            <div className="mt-[3.2rem] rounded-t-lg bg-[white] pb-[1.8rem]">
                {/* <Table
                    items={Domains || []}
                    fields={fields}
                    selectable
                    responsive
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        type: (item) => (
                            <td>
                                <Text align="left" transform="capitalize" color="#6B7280">
                                    {item.type} Portal
                                </Text>
                            </td>
                        ),
                        delete: (item) => (
                            <td
                                className="cursor-pointer"
                                onClick={() => {
                                    setSelectedDomain(item);
                                    setShowDelete(true);
                                }}
                            >
                                <DeleteIcon />
                            </td>
                        ),
                        view: (item) => (
                            <td
                                onClick={() => {
                                    if (item.type === 'Admin Portal') {
                                        navigate(`/ict/settings/add-admin/admin-proceed?name=${item.domain}`);
                                    } else if (item.type === 'Student Portal') {
                                        navigate(`/ict/settings/add-student/student-proceed?name=${item.domain}`);
                                    } else if (item.type === 'Lecturer Portal') {
                                        navigate(`/ict/settings/add-lecturer/lecturer-proceed?name=${item.domain}`);
                                    }
                                }}
                            >
                                <Text
                                    className="cursor-pointer"
                                    align="left"
                                    size="1.4rem"
                                    weight="500"
                                    color="#3B82F6"
                                >
                                    View Details
                                </Text>
                            </td>
                        ),
                        status: (item) => (
                            <td>
                                {item.status === 'Verified' ? (
                                    <StatusContainer bgColor="#ECFDF5">
                                        <Text color="#059669" size="1.2rem" weight="500">
                                            {item.status}
                                        </Text>
                                    </StatusContainer>
                                ) : item.status === 'Unverified' ? (
                                    <StatusContainer bgColor="#FEF2F2">
                                        <Text color="#EF4444" size="1.2rem" weight="500">
                                            {item.status}
                                        </Text>
                                    </StatusContainer>
                                ) : (
                                    <StatusContainer bgColor="#F5F3FF">
                                        <Text color="#8B5CF6" size="1.2rem">
                                            {item.status}
                                        </Text>
                                    </StatusContainer>
                                )}
                            </td>
                        ),
                    }}
                /> */}
            </div>
            <ConfirmActionDialogue
                show={showDelete}
                close={() => {
                    setValue('');
                    setShowDelete(false);
                }}
                title="Delete Custom Domain?"
                subtitle="Are you sure you want to delete this Custom Domain?"
                bodyText="To confirm, enter the name of the Custom Domain in the text field."
                input
                placeholder="admin.organization.com"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                btn2Text="Yes, Delete"
                isLoading={isLoading}
                confirmAction={deleteCustomDomain}
            />
        </>
    );
};

export default CustomDomain;
