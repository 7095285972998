import React, { useState } from 'react';

import CustomTable from '../../../../../../../../components/CustomTable';
import { Text } from '../../../../../../../../containers/MesssageContainers';
import PageLayout from '../../../../../../../../layout/page-layout/PageLayout';

const fields = [
    {
        headerName: 'Added By',
        field: '',
    },
    {
        headerName: 'Salary',
        field: '',
    },
    {
        headerName: 'Changes Made',
        field: '',
    },
    {
        headerName: 'Amount',
        field: '',
    },
    {
        headerName: 'Description',
        field: '',
    },
    {
        headerName: 'Date Added',
        field: '',
    },
];

const SalaryHistory = ({ salaryHistoryData, isLoading }) => {
    const [searchValue, setSearchValue] = useState('');
    return (
        <div className="bg-white">
            <div className="border-b border-b-solid border-b-[#E5E7EB] py-[8px] px-[32px]">
                <Text align="left" weight="600" size="1.6rem">
                    Salary Item History
                </Text>
            </div>
            <PageLayout
                noMargin
                // fields={fields}
                // data={salaryHistoryData || []}
                customTable={<CustomTable colDefs={fields} data={salaryHistoryData || []} />}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                // loading={isLoading}
            />
        </div>
    );
};

export default SalaryHistory;
