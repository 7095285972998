import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit-black.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../../../components';
import { Button } from '../../../../../components/buttons';
import CustomTable from '../../../../../components/CustomTable';
import TableWithUtils from '../../../../../components/table-with-utils';
import { Text } from '../../../../../containers/MesssageContainers';
import { RelativeContainer } from '../../../../../containers/ScreenContainers';
import useSearch from '../../../../../hooks/useSearch';
import { deleteDebtorFee } from '../../../../../redux/bursary/actions';
import currencyFormatter from '../../../../../utils/formatCurrency';

const SPAN_LOOKUP = {
    'first-semester': 'First Semester',
    'second-semester': 'Second Semester',
    'both-semesters': 'Both Semester',
};

const DebtHistory = ({ details, setToggleRefetch }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading } = useSelector((state) => state.bursary);

    const [selectedItem, setSelectedItem] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [openModal, setOpenModal] = useState('');

    const [searchValue, setSearchValue] = useState('');
    const filteredResults = useSearch(searchValue, details.debts, ['feeType']);

    const fields = [
        {
            headerName: 'Fee Type',
            field: 'feeType',
        },
        {
            headerName: 'Session',
            field: 'session',
        },
        {
            headerName: 'Amount Owed',
            field: 'amountOwed',
            cellRenderer: (p) => <p>{currencyFormatter.format(p.value || 0)}</p>,
        },
        {
            headerName: 'Created At',
            field: 'createdAt',
            cellRenderer: (p) => (
                <p>
                    {p?.value
                        ? `${new Date(p.value).toLocaleDateString()}, ${new Date(p.value).toLocaleTimeString()}`
                        : ''}
                </p>
            ),
        },
        {
            headerName: 'Span Of Fee',
            field: 'span',
            cellRenderer: (p) => <p>{SPAN_LOOKUP[p.value]}</p>,
        },
    ];

    const handleDelete = async () => {
        const payload = {
            debtIdToDelete: selectedItem._id,
        };

        const res = await dispatch(deleteDebtorFee(details.studentId, payload));
        if (res) {
            setToggleRefetch((prevState) => !prevState);
            setOpenModal('');
        }
    };

    return (
        <>
            <div className="bg-[#fff] rounded-t-[16px] mb-10 mt-4">
                <div className="flex items-center justify-between px-[24px] pt-[18px] pb-[0px] mb-[-20px]">
                    <Text weight="500" size="1.9rem">
                        Debt History
                    </Text>

                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setOpenDropdown(true)}>
                            Actions
                            <Dropdown />
                        </Button>
                        <ActionsPopup
                            open={openDropdown}
                            close={() => setOpenDropdown(false)}
                            items={[
                                {
                                    icon: <EditIcon />,
                                    name: 'Edit Fee',
                                    disabled: !selectedItem,
                                    click: () => navigate('edit', { state: selectedItem }),
                                },
                                {
                                    icon: <DeleteIcon />,
                                    name: 'Delete Fee',
                                    disabled: !selectedItem,
                                    click: () => setOpenModal('delete'),
                                },
                            ]}
                        />
                    </RelativeContainer>
                </div>
                <TableWithUtils
                    // fields={fields}
                    // data={filteredResults || []}
                    showTableUtils
                    searchable
                    // loading={isLoading}
                    // showFilter
                    // isActionable
                    // actionType="radio"
                    customTable={
                        <CustomTable
                            colDefs={fields}
                            data={filteredResults || []}
                            rowSelection
                            singleRowSelection
                            setSelectedItem={setSelectedItem}
                        />
                    }
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    searchValue={searchValue}
                    onSearchClose={() => setSearchValue('')}
                />
            </div>

            <ConfirmActionDialogue
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete this fee?"
                subtitle="This fee will be deleted permanently."
                bodyText="Do you want to continue?"
                confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                isLoading={isLoading}
            />
        </>
    );
};

export default DebtHistory;
