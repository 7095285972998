import React from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';
import CentreModal from '../../modal/CentreModal';

const DetachFeeModal = ({
    show,
    close,
    subtitle,
    title,
    nameField = 'fullName',
    idField = 'id',
    value,
    placeholder,
    bodyText,
    input,
    onChange,
    type,
    list,
    onSend,
    isLoading,
    disabled,
}) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[45.8rem] max-h-[60rem] mx-auto">
                <FlexRowSpaceBetween>
                    <Text as="h3" size="1.9rem" weight="600">
                        {title || ''}
                    </Text>
                    <CloseIcon className="hover:cursor-pointer" onClick={close} />
                </FlexRowSpaceBetween>
                <Text align="start" size="1.6rem" color="#374151" top="1.6rem" lineHeight="2.4rem" bottom="1rem">
                    {subtitle}
                </Text>
                <Text align="start" size="1.6rem" weight="600" top="1.6rem" lineHeight="2.4rem" bottom="1rem">
                    Name of Fee to Detach
                </Text>
                <ul className="overflow-y-auto pb-2 mb-5">
                    {list?.map((item, index) => (
                        <Text align="left" key={item?.[idField] || index}>{`${item?.[nameField]}`}</Text>
                    ))}
                </ul>
                {bodyText}
                {input && (
                    <input
                        type={type || 'text'}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        className="border-[1px] border-[#E5E7EB] pl-3 rounded outline-none focus:ring-[#6366F1] focus:ring-2 placeholder:text-[#6B7280] placeholder:text-[1.4rem] leading-6 font-normal text-[1.4rem] w-full h-[4rem]"
                    />
                )}
                <FlexRowEnd className="gap-x-[16px] mt-5">
                    <Button type="button" onClick={close} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton disabled={disabled} loading={isLoading} onClick={onSend}>
                        Send
                    </LoadingButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default DetachFeeModal;
