import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import NavTabs, { Tab } from '../../../../components/nav-tabs/NavTabs';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, RelativeContainer } from '../../../../containers/ScreenContainers';
import { getAllMemo, getAllInbox, getInboxLetterComms } from '../../../../redux/staff-arena/actions';

import InboxAll from './tabs/All';
import LetterThrough from './tabs/LetterThrough';
import LetterTo from './tabs/LetterTo';
import MemoThrough from './tabs/MemoThrough';
import MemoTo from './tabs/MemoTo';

const tabs = [
    { label: 'All' },
    { label: 'Letters (To)' },
    { label: 'Memo (To)' },
    { label: 'Letters (Through)' },
    { label: 'Memo (Through)' },
];

const Inbox = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const [toOfficeMemo, setToOfficeMemo] = useState([]);
    const [throughOfficeMemo, setThroughOfficeMemo] = useState([]);
    const [inbox, setInbox] = useState({});
    const [toLetter, setToLetter] = useState([]);
    const [throughLetter, setThroughLetter] = useState([]);

    const ComposeList = [
        {
            name: 'Letters',
            click: () => {
                navigate('/staff-arena/office/letter');
                setDropdownOpen(false);
            },
        },

        {
            name: ' Memo ',
            click: () => {
                navigate('/staff-arena/office/memo');
                setDropdownOpen(false);
            },
        },
        {
            name: 'Notices ',
            click: () => {
                // navigate('/staff-arena/office/notice');
                setDropdownOpen(false);
            },
        },
    ];

    useEffect(() => {
        const getToOfficeMemo = async () => {
            const res = await dispatch(getAllMemo('to'));
            setToOfficeMemo(res);
        };
        const getThroughOfficeMemo = async () => {
            const res = await dispatch(getAllMemo('through'));
            setThroughOfficeMemo(res);
        };
        const getAllReceived = async () => {
            const res = await dispatch(getAllInbox());
            setInbox(res);
        };
        const getToLetters = async () => {
            const res = await dispatch(getInboxLetterComms('to'));
            setToLetter(res);
        };
        const getThroughLetters = async () => {
            const res = await dispatch(getInboxLetterComms('through'));
            setThroughLetter(res);
        };

        getToOfficeMemo();
        getThroughOfficeMemo();
        getToLetters();
        getThroughLetters();
        getAllReceived();
    }, [dispatch]);

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Office</PageTitle>

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Compose
                        <Dropdown />
                    </Button>
                    <ActionsPopup open={dropdownOpen} close={() => setDropdownOpen(false)} items={ComposeList} />
                </RelativeContainer>
            </FlexRowSpaceBetween>
            <Text align="left" weight="500" size="1.6rem" lineHeight="2.4rem" top="1.9rem" bottom="3.2rem">
                Received - Communications
            </Text>

            <NavTabs tabs={tabs}>
                <Tab.Panel>
                    <InboxAll allInbox={inbox} />
                </Tab.Panel>
                <Tab.Panel>
                    <LetterTo toLetter={toLetter} />
                </Tab.Panel>
                <Tab.Panel>
                    <MemoTo memo={toOfficeMemo} />
                </Tab.Panel>
                <Tab.Panel>
                    <LetterThrough throughLetter={throughLetter} />
                </Tab.Panel>
                <Tab.Panel>
                    <MemoThrough memo={throughOfficeMemo} />
                </Tab.Panel>
            </NavTabs>
        </div>
    );
};

export default Inbox;
