import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    courseCode: Yup.string()
        // allows "gns 101"  "gns 101/102"
        .matches(/^[A-Za-z]+\s\d+(\/\d+)?$/, 'Format must be text followed by a space, and numbers (e.g. GNS 101 or GNS 101/102).')
        .required('Required'),
    courseTitle: Yup.string().nullable().required('Required'),
    courseUnit: Yup.string().nullable().required('Required'),
    courseSemester: Yup.string().nullable().required('Required'),
    courseLevel: Yup.string().nullable().required('Required'),
    minimumPassScore: Yup.string().nullable().required('Required'),
});
