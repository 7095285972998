/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { GoBack } from '../../../components/go-back';
import Checkbox from '../../../components/inputs/checkbox';
import SearchInput from '../../../components/inputs/search-input';
import ItemsPerPage from '../../../components/items-per-page';
import ListEmptyContent from '../../../components/list-empty';
import { PageTitle } from '../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
} from '../../../containers/ScreenContainers';
import { getOrganisationUnitDetails } from '../../../redux/organisation-unit/actions';

const fields = [
    {
        label: 'Name',
        key: 'fullName',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const OrganizationUnitDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { isLoading } = useSelector((state) => state.organisationUnits);

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [details, setDetails] = useState({});

    const { staffDetail, unitName } = details || {};
    const totalPages = Math.ceil(staffDetail?.length / itemsPerPage);

    const getDetails = async () => {
        const res = await dispatch(getOrganisationUnitDetails(id));
        if (res) setDetails(res);
    };

    useEffect(() => {
        if (id) {
            getDetails(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    return (
        <>
            <GoBack title="Organization Unit" subtitle={unitName} />

            <PageTitle top="2.8rem" align="start">
                {unitName}
            </PageTitle>

            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                    </FlexCentredRow>
                </FilterContainer>
                {/* <Table
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    items={staffDetail || []}
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    noItemsViewSlot={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent isInTable title="No Organization units "></ListEmptyContent>
                        </CenteredContainer>
                    }
                    clickableRows
                    loading={isLoading}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                    }}
                />
                 */}
            </div>
        </>
    );
};

export default OrganizationUnitDetails;
