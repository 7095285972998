/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Mark } from '../../../../assets/icons/circular-mark-sm-black.svg';
import { ReactComponent as Plus } from '../../../../assets/icons/circularplus-black.svg';
import { ReactComponent as CircularPlus } from '../../../../assets/icons/circularplus.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import ListEmptyContent from '../../../../components/list-empty';
import ConfirmActionDialogueWithInput from '../../../../components/popups/confirm-action-with-input';
// import { PageTitle } from '../../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import { getAdministratorsInUnit } from '../../../../redux/administrators/actions';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Name',
        key: 'name',
    },
    {
        label: 'Staff Number',
        key: 'staffNumber',
    },
    {
        label: 'Roles',
        key: 'staffRole',
    },
    {
        label: 'Email Address',
        key: 'email',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const DepartmentAdministrators = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.administrators);
    const [admins, setAdmins] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(admins.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const handleSelect = (item) => {
        const selected = selectedItem?._id === item._id;
        if (selected) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
    };

    const confirmAction = () => {
        const fullName = `${selectedItem.firstName} ${selectedItem.surname}`;
        if (fullName.toLowerCase() !== inputValue.toLowerCase())
            return toast.error("Name doesn't match. Check and try again");
    };

    useEffect(() => {
        if (unitName) {
            const getDetails = async () => {
                const data = await dispatch(getAdministratorsInUnit(unitName));
                data && setAdmins(data);
            };

            getDetails();
        }
    }, [dispatch, unitName]);

    return (
        <>
            <div>
                <div className="mt-[0.5rem] rounded-t-lg bg-[white] pb-[1.8rem]">
                    <FilterContainer>
                        <FlexCentredRow>
                            <SearchInput
                                withRadius
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                            />
                        </FlexCentredRow>
                        <FlexCentredRow className="gap-x-4">
                            <Link to="admin/add">
                                <Button border="1px solid #e5e7eb">
                                    <Plus />
                                    Add Administrator
                                </Button>
                            </Link>

                            <RelativeContainer>
                                <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                                    Actions
                                    <Dropdown />
                                </Button>
                                <ActionsPopup
                                    open={dropdownOpen}
                                    close={() => setDropdownOpen(false)}
                                    items={[
                                        {
                                            icon: <Mark />,
                                            name: 'Edit Administrator',
                                            click: () => {
                                                navigate('admin/edit-administrator');
                                                setDropdownOpen(false);
                                            },
                                        },
                                        {
                                            icon: <Delete />,
                                            name: 'Remove Administrator',
                                            disabled: !selectedItem,
                                            click: () => {
                                                setShowConfirm(true);
                                                setDropdownOpen(false);
                                            },
                                        },
                                    ]}
                                />
                            </RelativeContainer>
                        </FlexCentredRow>
                    </FilterContainer>
                    {/* <Table
                        items={admins || []}
                        fields={fields}
                        // striped
                        selectable
                        responsive
                        loading={isLoading}
                        itemsPerPage={itemsPerPage}
                        activePage={currentPage || 1}
                        noItemsViewSlot={
                            <CenteredContainer className="mt-[5rem]">
                                <ListEmptyContent isInTable title="No Administrator " subtitle="Add an Administrator">
                                    <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                        <Button
                                            onClick={() => navigate('add-administrator')}
                                            bgColor="#10B981"
                                            color="#fff"
                                        >
                                            <CircularPlus className="mr-[1.15rem]" />
                                            Add Administrator
                                        </Button>
                                    </CenteredContainer>
                                </ListEmptyContent>
                            </CenteredContainer>
                        }
                        clickableRows
                        onRowClick={(item) => handleSelect(item)}
                        scopedSlots={{
                            action: (item) => (
                                <td>
                                    <Checkbox
                                        onChange={() => handleSelect(item)}
                                        checked={selectedItem?._id === item._id}
                                    />
                                </td>
                            ),
                            name: (item) => (
                                <td>
                                    {item.firstName} {item.surname}
                                </td>
                            ),
                            staffNumber: (item) => <td>{item.staffNumber || '-'}</td>,
                        }}
                    /> */}

                    {/* <ItemsPerPageCont>
                        <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                        <Pagination
                            activePage={currentPage || 1}
                            onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                            pages={totalPages || 1}
                            align="end"
                        />
                    </ItemsPerPageCont> */}
                </div>
            </div>

            <ConfirmActionDialogueWithInput
                show={showConfirm}
                title="Remove Administrator?"
                subtitle="The administrator's access to this organization unit will be terminated."
                subtitle2="To confirm Removal, enter the name of the administrator in the text field."
                close={() => setShowConfirm(false)}
                confirmAction={confirmAction}
                btn2Text="Yes, Remove"
                setInputValue={setInputValue}
                value={inputValue}
            />
        </>
    );
};

export default DepartmentAdministrators;
