import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ListEmptyContent from '../../../../../../components/list-empty';
import { CenteredContainer } from '../../../../../../containers/ScreenContainers';
import usePersistedState from '../../../../../../hooks/usePersistedState';
import PageLayout from '../../../../../../layout/page-layout/PageLayout';
import { getActiveSemester, getActiveSession, getAdmissionSessions } from '../../../../../../redux/admissions/actions';
import { getCurriculum } from '../../../../../../redux/curriculum/actions';
import { localStoreKeys } from '../../../../../../utils/localStore';

const CurriculumLevels = () => {
    const dispatch = useDispatch();

    const { currentDepartment } = useSelector((state) => state.department);
    const { currentProgramme } = useSelector((state) => state.programmes);
    const { isLoading } = useSelector((state) => state.curriculum);
    const { sessions: admissionsSessions, activeSession } = useSelector((state) => state.admission);

    const { departmentId, academicStructureId, academicStructure } = currentDepartment || {};
    const { _id: programId } = currentProgramme || {};

    const splitStruct = academicStructure?.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const [data, setData] = useState([]);
    const [session, setSession] = usePersistedState(activeSession || '', localStoreKeys.SelectedSession);
    const [openDropdown, setOpenDropdown] = useState('');

    const fields = [
        {
            header: 'Level',
            accessorKey: 'level',
            cell: (props) => (
                <Link to={`${props.getValue()}`} state={{ session }} style={{ color: '#2563EB' }}>
                    {props.getValue()}
                </Link>
            ),
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Total no of courses',
            accessorKey: 'courses',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Total no of units',
            accessorKey: 'totalUnit',
        },
    ];

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const sessionItems = admissionsSessions?.map((item) => ({
        name: item?.admissionYear,
        click: () => {
            setSession(item?.admissionYear);
            setOpenDropdown(null);
        },
    }));

    useEffect(() => {
        if (lastDegreeId && session) {
            const getData = async () => {
                const res = await dispatch(getCurriculum(academicStructureId, lastDegreeId, session));
                if (res) return setData(res);
                setData([]);
            };

            getData();
        }
    }, [academicStructureId, dispatch, lastDegreeId, session]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getActiveSession());
        dispatch(getActiveSemester());
    }, [dispatch]);

    return (
        <div className="h-[100vh]">
            <PageLayout
                pageTitle="Curriculum"
                showTableUtils
                isActionable
                data={data || []}
                fields={fields}
                loading={isLoading}
                onSessionFilterClick={() => handleOpenDropdown('sessions')}
                sessionPlaceHolder={session}
                openSessionFilter={openDropdown === 'sessions'}
                closeSessionFilter={() => setOpenDropdown(null)}
                sessionItems={sessionItems}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No curriculum yet "></ListEmptyContent>
                    </CenteredContainer>
                }
            />
        </div>
    );
};

export default CurriculumLevels;
