import React from 'react';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload3.svg';
import { Text } from '../../containers/MesssageContainers';
import CentreModal from '../modal/CentreModal';

const UploadFile = ({ show, setShow }) => {
    const close = () => setShow(false);
    return (
        <CentreModal isOpen={show} onClose={() => close()}>
            <div className="bg-white rounded-[8px] px-[11.2rem] pt-[10.9rem] pb-[10.3rem] w-[59.2rem]">
                <CloseIcon onClick={close} className="absolute top-[3.5rem] right-[3.9rem]" />
                <div className="border-[#6B7280] flex flex-col items-center border border-dashed pt-[2.7rem] pb-[2.4rem] px-[12.1rem] rounded-[8px]">
                    <UploadIcon />
                    <Text size="1.6rem" weight="500" top="1.9rem" bottom="1.6rem">
                        Drop Files or
                    </Text>
                    <label className="border border-gray-300/[.5] text-[1.6rem] w-[12.6rem] rounded-[4px] px-4 py-2 h-[4rem] text-gray-800 font-medium cursor-pointer">
                        Upload Files
                        <input
                            type="file"
                            id="uploadSheet"
                            // accept={accept}
                            accept={'.xlx, .xlsx'}
                            // onChange={(event) => {
                            //     if (event.target.files[0].size >= 100000000) {
                            //         toast.error('File size cannot be more than 10MB');
                            //         return;
                            //     } else {
                            //         handleFile(event);
                            //     }
                            // }}
                            className="overflow-hidden bg-transparent opacity-0"
                        />
                    </label>
                    <Text color="#6B7280" top="0.8rem">
                        Supports .xlxs
                    </Text>
                </div>
                <Text size="1.6rem" color="#8B5CF6" top="4.6rem">
                    Download template here
                </Text>
            </div>
        </CentreModal>
    );
};

export default UploadFile;
