import { format } from 'date-fns';

export const formatToDate = (date) => {
    if (date) {
        const newDate = new Date(Date.parse(date));
        return newDate.toLocaleDateString('en-GB');
    }
    return null;
};

export const formatDate = (date) => {
    if (date) {
        return format(new Date(date), 'yyyy-MM-dd');
    }
    return null;
};

   // Function to format the date
  export const formatToDateTime = (dateString) => {
    const date = new Date(dateString);

    // Extracting components of the date and time
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert to 12-hour format
    const time = `${hours}:${minutes} ${amPm}`;

    return `${year}-${month}-${day} ${time}`;
  }

  export const formatMonthAndYear = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
};

// export const reverseDate = (date) => date?.split('-').reverse().join('-');

// const transformDate = (date) => {
//     console.log('date', date);
//     //mm/dd/yyyy
//     const dte = date && format(new Date(date), 'yyyy-MM-dd');
//     // console.log('date', dte);
//     const dteToUse = dte?.split('/').reverse().join('-');
//     // console.log('dteToUse', dteToUse);
//     //function returns  yyyy-mm-dd
//     return dteToUse;
// };
