import React, { useState } from 'react';

import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { markResultAsIncomplete } from '../../../redux/exam-magt/actions';
import { Button, LoadingButton } from '../../buttons';
import TextAreaInput from '../../inputs/text-area';
import CentreModal from '../../modal/CentreModal';

const ConfirmIncompleteResult = ({ show, close, level, semester, session, student }) => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.examMagt);

    const { currentProgramme } = useSelector((state) => state.programmes);
    const { _id: programId } = currentProgramme || {};
    const { studentId } = student || {};

    const [inputValue, setInputValue] = useState('');

    const handleConfirm = async () => {
        const params = { studentId, programId, level, semester: semester?.toLowerCase(), session };

        const res = await dispatch(markResultAsIncomplete(params));
        if (res) {
            toast.success('Student result has been marked as incomplete');
            close();
        }
    };
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[59.2rem]">
                <FlexRowSpaceBetween>
                    <Text as="h3" size="1.9rem" weight="600">
                        Confirm Incomplete Result
                    </Text>

                    <CloseIcon onClick={close} className="cursor-pointer" />
                </FlexRowSpaceBetween>

                <TextAreaInput
                    name="remark"
                    type="text"
                    placeholder="Remark"
                    errorMessage={!inputValue ? 'Required' : ''}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                    value={inputValue}
                    height="6rem"
                />

                <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                    <Button onClick={close} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton onClick={handleConfirm} disabled={!inputValue || isLoading} loading={isLoading}>
                        Confirm
                    </LoadingButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default ConfirmIncompleteResult;
