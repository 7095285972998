import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as Dropdown2 } from '../../../assets/icons/arrow-down2.svg';
import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, RelativeContainer } from '../../../containers/ScreenContainers';
import usePersistedState from '../../../hooks/usePersistedState';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAgentDetails } from '../../../redux/bursary/actions';
import { capitalizeEachWord } from '../../../utils';
import { localStoreKeys } from '../../../utils/localStore';

const AgentDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { sessions, activeSession } = useSelector((store) => store.admission);
    const {
        firstDegreeOptionsStr,
        secondDegreeOptionsStr,
        //  firstDegreeOptionsArray = [],
    } = useSelector((state) => state.academicStructure);

    const [sessionValue, setSessionValue] = usePersistedState(activeSession || '', localStoreKeys.SelectedSession);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [agentDetail, setAgentDetail] = useState(null);

    const { students } = agentDetail || {};
    const { agent } = state || {};

    const { companyName, companyAddress, companyEmail, companyPhoneNumber, companyRegistrationNumber, createdAt } =
        agent || {};

    const sessionItems = sessions?.map((item) => ({
        name: item?.admissionYear,
        click: () => {
            setSessionValue(item?.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const query = useMemo(() => {
        let queryParams = {};

        if (sessionValue) {
            queryParams.session = sessionValue;
        }

        return queryParams;
    }, [sessionValue]);

    useEffect(() => {
        if (!sessionValue) return;

        const fetchAgentDetails = async () => {
            const response = await dispatch(getAgentDetails(id, query));
            setAgentDetail(response);
        };

        fetchAgentDetails();
    }, [dispatch, id, query, sessionValue]);

    const agentDetails = [
        {
            key: 'Company Name',
            value: companyName || '',
        },
        {
            key: 'Company Address',
            value: companyAddress || '',
        },
        {
            key: 'Company Email',
            value: companyEmail || '',
        },
        {
            key: "Company's Phone Number",
            value: companyPhoneNumber || '',
        },
        {
            key: 'Company Registration Number',
            value: companyRegistrationNumber || '',
        },
        {
            key: 'Dare Created',
            value: `${new Date(createdAt).toLocaleDateString()}, ${new Date(createdAt).toLocaleTimeString()}` || '',
        },
    ];

    const fields = [
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Full Name',
            accessorKey: 'fullName',
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Matric No',
            accessorKey: 'matricNumber',
        },
        {
            cell: (props) => (
                <p>{props.row.original?.college || props.row.original?.faculty || props.row.original?.school}</p>
            ),
            header: firstDegreeOptionsStr,
            accessorKey: 'faculty',
        },
        {
            cell: (props) => <p>{props?.row?.original?.subProgram || props?.row?.original?.department}</p>,
            header: secondDegreeOptionsStr,
            accessorKey: 'program',
        },
        {
            header: 'Admission Type',
            accessorKey: 'admissionType',
        },
        {
            header: 'Admission Level',
            accessorKey: 'admittedLevel',
        },
        {
            cell: (props) => <p>{new Date(props.getValue()?.[0]).toLocaleDateString()}</p>,
            header: 'Date Added',
            accessorKey: 'dateAdded',
        },
    ];

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.3rem] gap-[0.5rem]">
                <BackArrow className="" />

                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Agent Profile
                </PageTitle>
                <PageTitle
                    as="span"
                    size="1.6rem"
                    weight="400"
                    color="#6366F1"
                    lineHeight="2.8rem"
                    className="mx-[1.4rem]"
                >
                    /
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                    {companyName}
                </PageTitle>
            </FlexCentredRow>

            <FlexCentredCol>
                <PageTitle align="left">{capitalizeEachWord(companyName)}</PageTitle>
            </FlexCentredCol>

            <div className="bg-white p-[2.4rem] rounded-[8px] my-[2.4rem]">
                <PageTitle align="left" bottom="2.4rem">
                    Overview
                </PageTitle>
                <div className="grid grid-cols-3 gap-[2.4rem]">
                    {agentDetails?.map((item) => (
                        <div className="">
                            <Text align="left" size="1.2rem" weight="500" color="#9CA3AF">
                                {item?.key}
                            </Text>
                            <Text align="left" size="1.4rem" weight="500" color="#4B5563">
                                {item?.value}
                            </Text>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bg-white py-[2rem] rounded-[8px] my-[2.4rem]">
                <FlexCentredRow className="py-[8px] px-[3.2rem]">
                    <RelativeContainer>
                        <Button color="#6B7280" border="1px solid #9CA3AF" onClick={() => setOpenDropdown('session')}>
                            {sessionValue || 'Session'}
                            <Dropdown2 />
                        </Button>
                        <ActionsPopup
                            open={openDropdown === 'session'}
                            close={() => setOpenDropdown(null)}
                            items={sessionItems}
                        />
                    </RelativeContainer>
                </FlexCentredRow>
                <div className="">
                    <PageLayout noMargin tableTitle="Referred Students" fields={fields} data={students || []} />
                </div>
            </div>
        </div>
    );
};

export default AgentDetails;
