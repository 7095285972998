import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PayrollIcon } from '../../../assets/icons/payroll.svg';
import { LoadingButton } from '../../../components/buttons';
import { CancelButton } from '../../../components/forms/sharedStyles';
import Select from '../../../components/inputs/new-select';
import TextInput from '../../../components/inputs/text-input-with-formik';
import { FlexCentredRow } from '../../../containers/ScreenContainers';
import { getAllBanksFromPaystack } from '../../../redux/bursary/actions';
import { createPayroll, editPayroll } from '../../../redux/staff-arena/actions';
const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;
const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[100.5rem]
    mt-[3rem]
`;

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

const Type = ['savings', 'current'];

const Payroll = ({ payrollDetails, hasPayroll }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.staffArena);

    const [allBanks, setAllBanks] = useState([]);
    const [selectedBankCode, setSelectedBankCode] = useState('');

    useEffect(() => {
        const getBanks = async () => {
            const res = await dispatch(getAllBanksFromPaystack());
            setAllBanks(res);
        };
        getBanks();
    }, [dispatch]);

    return (
        <div className="mb-[3rem]">
            <Formik
                initialValues={{
                    accountName: payrollDetails?.accountName || '',
                    accountNumber: payrollDetails?.accountNumber || '',
                    bankName: payrollDetails?.bankName || '',
                    accountType: payrollDetails?.accountType || '',
                    bankCode: payrollDetails?.bankCode ||'',
                }}
                enableReinitialize={true}
                onSubmit={async (values) => {

                    const payload = {
                        ...values,
                        bankCode: selectedBankCode
                    }
                    let res;
                    if (payrollDetails && hasPayroll) {
                        res = await dispatch(editPayroll(payload, payrollDetails?._id));
                    } else {
                        res = await dispatch(createPayroll(payload));
                    }
                    if (res) {
                        toast.success('Payroll Saved Successfully!');
                    }
                }}
            >
                {({ errors, handleChange, values, setFieldValue, dirty }) => (
                    <Form>
                        <MainContainer>
                            <FlexCentredRow className="mb-[3.2rem] mt-[1.2rem] gap-[0.715rem]">
                                <PayrollIcon />
                                <Title> Payroll</Title>
                            </FlexCentredRow>
                            <div className="gap-y-[2.4rem] grid grid-cols-1 ">
                                <Select
                                    name="bankName"
                                    objProp="name"
                                    placeholder="Select Bank"
                                    label="Bank"
                                    searchable
                                    data={allBanks || []}
                                    onChange={(selected) => {
                                        setFieldValue('bankName', selected?.[0]?.name);
                                        setSelectedBankCode(selected?.[0]?.code)
                                    }}
                                    error={errors.bankName}
                                    passedSelectedItems={values?.bankName}
                                />
                                <TextInput
                                    name="accountName"
                                    type="text"
                                    label="Account Name"
                                    placeholder="input Account Name"
                                    value={values.accountName}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                                <Select
                                    name="accountType"
                                    objProp="accountType"
                                    placeholder="Select Account Type"
                                    label="Account Type"
                                    data={Type.map((item) => ({ accountType: item }))}
                                    onChange={(selected) => {
                                        setFieldValue('accountType', String(selected[0].accountType));
                                    }}
                                    error={errors.accountType}
                                    passedSelectedItems={values.accountType}
                                />
                                <TextInput
                                    name="accountNumber"
                                    type="text"
                                    label="Account Number"
                                    placeholder="Input Account Number"
                                    value={values.accountNumber}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                            </div>
                        </MainContainer>
                        {dirty && (
                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
                                    Save Changes
                                </LoadingButton>
                            </FlexContainer>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Payroll;



