import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery, transformThirdDegreeData } from '../../utils';
import handleApiError from '../handleApiError';
import { GET_DEPARTMENT_PROGRAMMES } from '../programmes/slice';

import {
    GET_FACULTIES,
    GET_FACULTY_DEPARTMENTS,
    GET_FACULTY_COURSES,
    GET_FACULTY_STAFF,
    GET_FACULTY_STUDENTS,
    LOADING,
    STOP_LOADING,
    GET_FIRST_DEGREE_LECTURE_VENUES,
    GET_GENERAL_COURSES_FIRST_DEGREE,
} from './slice';

export const getFaculties = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    // dispatch(GET_FACULTIES([]));
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}faculty/:campusId/:unitId/all_faculties?${queries}`);

        if (response.status === 200) {
            const { data = [] } = response?.data || {};
            dispatch(GET_FACULTIES(data));
            return true;
        }
    } catch (error) {
        dispatch(GET_FACULTIES([]));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getFacultyDetails = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}faculty/:campusId/:unitId/detail/${id}`);
        if (response.status === 200) {
            return response?.data?.data || {};
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getFacultyDepartments =
    (id, showLoader = true) =>
    async (dispatch, getState) => {
        showLoader && dispatch(LOADING());
        try {
            const response = await axiosInstance.get(`${currentAPI}faculty/:campusId/:unitId/department/${id}`);
            if (response.status === 200) {
                dispatch(GET_FACULTY_DEPARTMENTS(response.data.data));
            }
        } catch (error) {
            dispatch(GET_FACULTY_DEPARTMENTS([]));
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getFacultyCourses = (structureId, id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}course/:campusId/:unitId/fetch_all_courses_first_degree_without_lecturer/${structureId}/${id}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_FACULTY_COURSES(response.data.data));
        }
    } catch (error) {
        dispatch(GET_FACULTY_COURSES([]));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getGeneralCoursesInFirstDegree = (structureId, firstDegreeId, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(
            `${currentAPI}course/:campusId/:unitId/fetch_all_general_courses_without_lecturer/${structureId}/${firstDegreeId}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_GENERAL_COURSES_FIRST_DEGREE(response.data.data));
        }
    } catch (error) {
        dispatch(GET_GENERAL_COURSES_FIRST_DEGREE([]));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getGeneralCoursesInFirstDegreeWithLecturers =
    (structureId, firstDegreeId, session) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.get(
                `${currentAPI}course/:campusId/:unitId/get_all_general_courses/${structureId}/${firstDegreeId}/${session}`,
            );
            if (response.status === 200) {
                return response.data.data;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getFirstDegreeLectureVenues = (structureId, firstDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}venue/:campusId/:unitId/get_venue_in_first_degree/${structureId}/${firstDegreeId}`,
        );
        if (response.status === 200) {
            dispatch(GET_FIRST_DEGREE_LECTURE_VENUES(response.data.data));
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getFacultyStaff = (academicStructureId, facultyId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}faculty/:campusId/:unitId/staff/${academicStructureId}/${facultyId}`,
        );
        if (response.status === 200) {
            dispatch(GET_FACULTY_STAFF(response.data.data));
        }
    } catch (error) {
        dispatch(GET_FACULTY_STAFF([]));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
// export const getFacultyStudents = (id) => async (dispatch, getState) => {
//     dispatch(LOADING());
//     try {

//         const response = await axiosInstance.get(`${currentAPI}faculty/:campusId/:unitId/students/${id}`);
//         if (response.status === 200) {
//             dispatch(GET_FACULTY_STUDENTS(response.data.data));
//         }
//     } catch (error) {
//                    handleApiError(error);
//     } finally {
//         dispatch(STOP_LOADING());
//     }
// };

export const getStudentsInFirstDegree = (structureId, firstDegreeId, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}students/:campusId/:unitId/get_all_students_first_degree/${structureId}/${firstDegreeId}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_FACULTY_STUDENTS(response.data.data));
        }
    } catch (error) {
        dispatch(GET_FACULTY_STUDENTS([]));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getFacultyAdmin = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}faculty/:campusId/:unitId/admin/${id}`);
        if (response.status === 200) {
            // dispatch(GET_FACULTY_ADMINS(response.data.data));
        }
    } catch (error) {
        // dispatch(GET_FACULTY_ADMINS([]));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createFaculty =
    (payload, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const response = await axiosInstance.post(`${currentAPI}faculty/:campusId/:unitId/create_faculty`, payload);
            if (response.status === 200) {
                dispatch(getFaculties());
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const deleteFaculty = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}faculty/:campusId/:unitId/delete_faculty/${id}`);
        if (response.status === 200) {
            const successMessage = 'Faculty deleted successfully';
            dispatch(getFaculties());
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editFaculty = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}faculty/:campusId/:unitId/edit_faculty/${id}`, payload);
        const successMessage = response?.data?.message || 'Faculty Updated Successfully';
        if (response.status === 200) {
            dispatch(getFaculties());
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createFacultySecondLevel =
    (payload, facultyId, structureType, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const response = await axiosInstance.post(
                `${currentAPI}structure/:campusId/:unitId/faculty/create_second_level/${facultyId}?type=${structureType}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getFacultySecondLevel =
    ({ showLoader = true, academicStructureId, firstDegreeId, structTwo }) =>
    // structTwo i.e 2nd degree e.g 'department', 'faculty', 'program' e.t.c
    // firstDegreeId: here it's same as faculty id
    async (dispatch, getState) => {
        showLoader && dispatch(LOADING());
        try {
            const response = await axiosInstance.get(
                `${currentAPI}structure/:campusId/:unitId/get_second_degree_faculty/${academicStructureId}/${firstDegreeId}`,
            );
            if (response.status === 200) {
                const { data } = response.data;
                const transformedData = data.map((item) => {
                    let newItem;

                    const structMapping = {
                        department: ['department', 'departmentCode', 'departmentNumberingCode'],
                        program: ['subprogram', 'subProgramCode', 'subProgramNumberingCode'],
                        school: ['schoolName', 'schoolCode', 'schoolNumberingCode'],
                        faculty: ['facultyName', 'facultyCode', 'facultyNumberingCode'],
                        college: ['collegeName', 'collegeCode', 'collegeNumberingCode'],
                    };

                    const fields = structMapping[structTwo?.toLowerCase()];

                    if (fields) {
                        const {
                            [fields[0]]: departmentName,
                            [fields[1]]: departmentCode,
                            [fields[2]]: departmentNumberingCode,
                            ...otherFields
                        } = item;

                        newItem = {
                            departmentName,
                            departmentCode,
                            departmentNumberingCode,
                            ...otherFields,
                        };
                    }

                    return newItem;
                });

                dispatch(GET_FACULTY_DEPARTMENTS(transformedData));
                return transformedData;
            }
        } catch (error) {
            dispatch(GET_FACULTY_DEPARTMENTS([]));
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const createFacultyThirdLevel =
    (payload, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const {
                department: {
                    currentDepartment: { _id: departmentId },
                },
                faculty: {
                    currentFaculty: { _id: facultyId, academicStructure },
                },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}structure/:campusId/:unitId/faculty/create_third_level/${facultyId}/${departmentId}?type=${academicStructure}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getFacultyThirdLevel = (academicStructureId, facultyId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}structure/:campusId/:unitId/get_third_degree_faculty/${academicStructureId}/${facultyId}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};

            const transformedData = transformThirdDegreeData(data);

            dispatch(GET_DEPARTMENT_PROGRAMMES(transformedData));
            return transformedData;
        }
    } catch (error) {
        dispatch(GET_DEPARTMENT_PROGRAMMES([]));
       handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
