import React from 'react';

import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';
import CentreModal from '../../modal/CentreModal';

const Result = ({ children, show, close, title, isLoading }) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[59.2rem]">
                <FlexCentredRow className="gap-[1.215rem]">
                    <Text as="h3" size="1.9rem" weight="600">
                        {title}
                    </Text>
                </FlexCentredRow>

                {children}
                <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                    <Button onClick={close} disabled={isLoading} border="0.5px solid #D1D5DB">
                        Close
                    </Button>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default Result;
