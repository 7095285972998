import React, { useEffect, useMemo, useState } from 'react';

// import { RadioInput } from '@belrald_hq/belrald-ui';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-grey.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete-red.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit-black.svg';
import { ReactComponent as Send } from '../../assets/icons/send-black.svg';
import { ReactComponent as SendGreen } from '../../assets/icons/send-green.svg';
import { ConfirmActionDialogue } from '../../components';
import CustomTable from '../../components/CustomTable';
import useDebounce from '../../hooks/useDebounce';
import usePersistedState from '../../hooks/usePersistedState';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getSavedAcademicStructure } from '../../redux/academic-structure/actions';
import { getActiveSession, getAdmissionSessions } from '../../redux/admissions/actions';
import {
    deleteFee,
    // getFeeDetails,
    publishFee,
    unPublishFee,
    getUngroupedAllFees,
} from '../../redux/bursary/actions';
import { capitalizeFirstLetter, getStatusBadge } from '../../utils';
import currencyFormatter from '../../utils/formatCurrency';
import { localStoreKeys } from '../../utils/localStore';
import { storeUnit } from '../../utils/originUnit';

const Bursary = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLoading } = useSelector((store) => store.bursary);
    const { sessions, activeSession } = useSelector((store) => store.admission);

    const [fees, setFees] = useState([]);
    const [session, setSession] = usePersistedState(activeSession || '', localStoreKeys.SelectedSession);
    const [openModal, setOpenModal] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const [confirmInputValue, setConfirmInputValue] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [refetch, setRefetch] = useState(false);

    const colDefs = [
        {
            field: 'feeName',
            cellRenderer: (p) => {
                return (
                    <Link
                        to={`/bursary/fees/fee-details/${p.data._id}`}
                        state={{ currentFee: p.data }}
                        style={{ color: '#2563EB' }}
                    >
                        {p.value}
                    </Link>
                );
            },
        },
        {
            field: 'feeType',
            valueFormatter: (p) => p?.value?.replace('-', ' '),
        },
        {
            headerName: 'Total No of Students',
            field: 'totalStudents',
        },
        {
            headerName: 'No of Students(Paid)',
            field: 'paidStudents',
        },
        {
            headerName: 'No of Students(Unpaid)',
            field: 'unpaidStudents',
            valueFormatter: ({ data }) => data.totalStudents - data.paidStudents,
        },
        {
            header: 'Amount',
            field: 'amountPayable',
            cellRenderer: (p) => {
                return <p>{currencyFormatter.format(p.value || 0)}</p>;
            },
        },
        {
            headerName: 'Amount Expected',
            field: 'totalAmount',
            cellRenderer: ({ data }) => {
                return <p>{currencyFormatter.format(data.amountPayable * data.totalStudents)}</p>;
            },
        },
        {
            headerName: 'Receiving Accounts',
            field: 'paymentConfig',
            valueFormatter: (p) => capitalizeFirstLetter(p?.value),
        },
        {
            headerName: 'Status',
            field: 'publishStatus',
            cellRenderer: (p) => (
                <p>
                    {getStatusBadge(
                        capitalizeFirstLetter(p?.value?.toLowerCase() === 'unpublished' ? 'Not Published' : p.value),
                    )}
                </p>
            ),
        },
        {
            field: 'createdAt',
            valueFormatter: (p) =>
                `${new Date(p?.value).toLocaleDateString()}, ${new Date(p?.value).toLocaleTimeString()}`,
        },
        {
            headerName: 'Published At',
            accessorKey: 'publishFeeDate',
            cellRenderer: (p) => {
                return p.value
                    ? `${new Date(p?.value).toLocaleDateString()}, ${new Date(p?.value).toLocaleTimeString()}`
                    : 'Not Available';
            },
        },
    ];

    const handleActionSuccess = () => {
        setConfirmInputValue('');
        setOpenModal(false);
        setActionDropdownOpen(false);
        setRefetch((prev) => !prev);
    };

    const handlePublish = async () => {
        const payload = { feeName: confirmInputValue };
        const res = await dispatch(publishFee(payload, selectedItem?._id));
        if (res) {
            handleActionSuccess();
        }
    };

    const handleDeleteFee = async () => {
        if (confirmInputValue?.toLowerCase() === 'delete') {
            const res = await dispatch(deleteFee(selectedItem?._id));
            if (res) {
                handleActionSuccess();
            }
        } else {
            toast.error('Input the correct fee name');
        }
    };

    // const handleSelectItem = (item) => {
    //     setSelectedItem(item);
    // };

    const handleUnpublish = async () => {
        const res = await dispatch(unPublishFee(selectedItem?._id));
        if (res) {
            handleActionSuccess();
        }
    };

    const handleCloseDialogue = () => {
        setConfirmInputValue('');
        setOpenModal(false);
    };

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.bursarySearch = debouncedSearchValue;
        }
        if (session) {
            queryParams.session = session;
        }

        return queryParams;
    }, [debouncedSearchValue, session]);

    useEffect(() => {
        const getFees = async () => {
            const data = await dispatch(getUngroupedAllFees(query));
            if (data) {
                setFees(data);
            } else {
                setFees([]);
            }
        };
        getFees();
    }, [dispatch, query, refetch]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getActiveSession());
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <>
            <PageLayout
                pageTitle="Fees"
                secondaryButtonText="Add Fee"
                onSecondaryButtonClick={() => navigate('/bursary/fees/add-fee')}
                onActionBtnClick={() => {
                    setActionDropdownOpen(!actionDropdownOpen);
                }}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                // data={fees || []}
                // fields={fields}
                // loading={isLoading}
                // isActionable
                // actionType="radio"
                // onSelectedItemsChange={(items) => handleSelectItem(items[0])}
                sessionItems={sessions?.map((session) => ({
                    name: session?.admissionYear,
                    click: () => {
                        setSession(session?.admissionYear);
                        setOpenModal(null);
                    },
                }))}
                sessionValue={session}
                openSessionFilter={openModal === 'session'}
                onSessionFilterClick={() => setOpenModal('session')}
                closeSessionFilter={() => setOpenModal(null)}
                actionOpen={actionDropdownOpen}
                actionClose={() => setActionDropdownOpen(false)}
                actionItems={[
                    {
                        icon: <Send />,
                        name: 'Publish Fee',
                        disabled: !selectedItem?._id || selectedItem?.publishStatus?.toLowerCase() === 'published',
                        click: () => setOpenModal('publish'),
                    },
                    {
                        icon: <CloseIcon />,
                        name: 'Unpublish Fee',
                        disabled: !selectedItem?._id || selectedItem?.publishStatus?.toLowerCase() !== 'published',
                        click: () => setOpenModal('unpublish'),
                    },
                    {
                        icon: <Edit />,
                        name: 'Edit Fee',
                        disabled: !selectedItem?._id || selectedItem?.publishStatus?.toLowerCase() === 'published',
                        click: () => navigate(`/bursary/fees/edit-fee/${selectedItem?._id}`),
                    },
                    {
                        icon: <Delete />,
                        name: 'Delete Fee',
                        disabled: !selectedItem?._id || selectedItem?.publishStatus?.toLowerCase() === 'published',
                        click: () => setOpenModal('delete'),
                    },
                ]}
                customTable={
                    <CustomTable
                        colDefs={colDefs}
                        data={fees || []}
                        rowSelection
                        singleRowSelection
                        setSelectedItem={setSelectedItem}
                    />
                }
            />

            <ConfirmActionDialogue
                input
                placeholder="Input publish"
                value={confirmInputValue}
                onChange={(e) => setConfirmInputValue(e.target.value)}
                show={openModal === 'publish'}
                svgIcon={<SendGreen />}
                title="Publish Payment"
                close={() => handleCloseDialogue()}
                subtitle="This Payment will be published on the student’s portal."
                bodyText="To confirm, input 'publish' in the text field below."
                btn2Text="Yes, Publish"
                bgColor={'#10B981'}
                confirmAction={handlePublish}
                isLoading={isLoading}
                btnDisabled={confirmInputValue?.toLowerCase() !== 'publish'}
            />
            <ConfirmActionDialogue
                input
                value={confirmInputValue}
                onChange={(e) => setConfirmInputValue(e.target.value)}
                show={openModal === 'unpublish'}
                svgIcon={<CloseIcon />}
                title="Unpublish Payment"
                placeholder="Input unpublish"
                close={() => handleCloseDialogue()}
                subtitle="This Payment will be unpublished on the student’s portal."
                bodyText="To confirm, input 'unpublish' in the text field below."
                btn2Text="Yes, Unpublish"
                bgColor={'red'}
                isLoading={isLoading}
                confirmAction={handleUnpublish}
                btnDisabled={confirmInputValue?.toLowerCase() !== 'unpublish'}
            />
            <ConfirmActionDialogue
                show={openModal === 'delete'}
                close={() => handleCloseDialogue()}
                title="Delete this fee?"
                placeholder="Input delete"
                subtitle="This fee alongside its contents and history will be deleted totally."
                borderLine
                bodyText="To confirm deletion, enter the 'delete' in the text field below."
                input
                noStyle
                value={confirmInputValue}
                onChange={(e) => setConfirmInputValue(e.target.value)}
                btn2Text="Yes, Delete"
                bgColor={'#EF4444'}
                confirmAction={handleDeleteFee}
                isLoading={isLoading}
                btnDisabled={confirmInputValue?.toLowerCase() !== 'delete'}
            />
        </>
    );
};

export default Bursary;
