import React, { useEffect, useState, useMemo } from 'react';

// import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as ArrowDown } from '../../../../../assets/icons/arrow-down-white.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/delete-red.svg';
import { ActionsPopup, ExportSheetDialogue } from '../../../../../components';
import CustomTable from '../../../../../components/CustomTable';
import { FlexCentredRow, RelativeContainer } from '../../../../../containers/ScreenContainers';
import { useTableContext } from '../../../../../context/tableContext';
import useDebounce from '../../../../../hooks/useDebounce';
import PageLayout from '../../../../../layout/page-layout/PageLayout';
import { attachStudentToFee, getUnpaidFull, removeStudentFromFee } from '../../../../../redux/bursary/actions';
import { getStudents } from '../../../../../redux/students/actions';
import currencyFormatter from '../../../../../utils/formatCurrency';
import AttachStudentToFee from '../attach-student';

const StyledButton = tw.button`
h-[40px]
px-[16px]
py-[8px]
bg-[#6366F1]
text-white
text-[14px]
font-medium
`;

const TableButton = styled(StyledButton)`
    outline: none !important;
`;

const NotPaidStudents = ({ unpaidStudents, loading, currentFee, setUnpaidStudents, id }) => {
    const dispatch = useDispatch();
    // const { unpaidStudents: responseData } = useSelector((store) => store.bursary);
    // const { totalPages, totalNoOfStudents } = responseData || {};
    const {
        firstDegreeOptionsStr,
        secondDegreeOptionsStr,
        //  firstDegreeOptionsArray = [],
    } = useSelector((state) => state.academicStructure);
    const { students } = useSelector((store) => store.student);
    const { id: feeId } = useParams();

    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    // eslint-disable-next-line
    const [currentPage, setCurrentPage] = useState('1');
    const [selectedStudents, setSelectedStudents] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [openModal, setOpenModal] = useState();
    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        dispatch(getStudents());
    }, [dispatch]);

    const colDefs = [
        {
            headerName: 'Names',
            field: 'name',
            cellRenderer: ({ data }) => (
                <p>
                    {data?.lastName} {data?.firstName} {data?.middleName || ''}
                </p>
            ),
        },
        {
            headerName: 'Matric No',
            field: 'matricNumber',
        },
        {
            headerName: firstDegreeOptionsStr,
            field: 'faculty',
            cellRenderer: ({ data }) => <p>{data?.college || data?.faculty || data?.school}</p>,
        },
        {
            headerName: secondDegreeOptionsStr,
            field: 'program',
            cellRenderer: ({ data }) => <p>{data?.program || data?.department}</p>,
        },
        {
            cellRenderer: (p) => <p>{currencyFormatter.format(p.value || 0)}</p>,
            headerName: 'Amount Payable',
            field: 'amountPayable',
        },
    ];

    const handleAttachStudents = async () => {
        const payload = { studentIds: selectedStudents?._id };
        const res = await dispatch(attachStudentToFee(payload, feeId));
        if (res) {
            setSelectedStudents(null);
            setRefetch(!refetch);
            setOpenModal(null);
        }
    };

    const handleRemoveStudent = async () => {
        const payload = { studentIds: selectedStudents?.map((student) => student?._id) };
        const res = await dispatch(removeStudentFromFee(payload, feeId));
        if (res) {
            setSelectedStudents(null);
            setRefetch(!refetch);
            setOpenModal(null);
        }
    };

    const acceptedStudents = useMemo(() => students?.filter((student) => student?.status === 'Accepted'), [students]);

    const filte = useMemo(
        () => acceptedStudents?.filter((item) => !unpaidStudents?.some((stud) => stud?._id === item?._id)),
        [acceptedStudents, unpaidStudents],
    );

    useEffect(() => {
        const queries = {
            search: debouncedSearchValue,
            page: currentPage,
            limit: numOfPages,
        };
        if (id) {
            const getUnpaidStudents = async () => {
                const res = await dispatch(getUnpaidFull(id, queries));
                setUnpaidStudents(res);
            };
            getUnpaidStudents();
        }
    }, [id, debouncedSearchValue, currentPage, refetch, dispatch, setUnpaidStudents, numOfPages]);

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                customTable={
                    <CustomTable
                        colDefs={colDefs}
                        data={unpaidStudents || []}
                        rowSelection
                        singleRowSelection
                        setSelectedItem={setSelectedStudents}
                    />
                }
                // data={unpaidStudents || []}
                // fields={fields}
                // loading={loading}
                // isActionable
                // actionType="radio"
                // onSelectedItemsChange={(items) => setSelectedStudents([items[0]])}
                // onRowClick={(item) => {
                //     const selectedItem = unpaidStudents?.find((stud) => stud?._id === item?._id);
                //     handleRowClick(selectedItem);
                // }}
                // isPaginated={true}
                // paginationComponent={
                //     <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                //         <PaginationStatus
                //             currentPage={currentPage}
                //             itemsPerPage={tableSettings?.numOfPages}
                //             totalItems={totalNoOfStudents}
                //         />
                //         <Pagination
                //             pageRangeDisplayed={3}
                //             totalPages={totalPages}
                //             onPageChange={(nextPage) => {
                //                 setCurrentPage(nextPage);
                //             }}
                //             currentPage={currentPage}
                //         />
                //     </FlexRowSpaceBetween>
                // }
                tableUtilsChildren={
                    currentFee?.feeType?.toLowerCase() === 'direct-student' && (
                        <RelativeContainer>
                            <FlexCentredRow className="gap-[0.05rem]">
                                <TableButton
                                    onClick={() => setOpenModal('open-paid-attach')}
                                    className="rounded-l-[8px]"
                                >
                                    Add Student
                                </TableButton>
                                <TableButton onClick={() => setOpenModal('open-dropdown')} className="rounded-r-[8px]">
                                    <ArrowDown />
                                </TableButton>
                            </FlexCentredRow>
                            <ActionsPopup
                                open={openModal === 'open-dropdown'}
                                close={() => setOpenModal(null)}
                                items={[
                                    {
                                        icon: <DeleteIcon />,
                                        name: 'Remove Student From Fee',
                                        click: () => setOpenModal('open-paid-remove'),
                                        disabled: !unpaidStudents?.length > 0,
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    )
                }
            />

            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                // chooseAction={} exportAction={}
            />
            <AttachStudentToFee
                title="Add Students to Fee"
                acceptedStudents={filte}
                subtitle2="To confirm, enter add student in the text field."
                value={inputValue}
                setInputValue={setInputValue}
                close={() => {
                    setSelectedStudents(null);
                    setOpenModal('');
                }}
                btnText="Add Student"
                placeholder="Enter add student"
                onConfirm={handleAttachStudents}
                setSelectedStudents={setSelectedStudents}
                disabled={inputValue?.toLowerCase() !== 'add student'}
                list={[selectedStudents]}
                show={openModal === 'open-paid-attach'}
                selectedStudents={[selectedStudents]}
                isLoading={loading}
            />

            <AttachStudentToFee
                show={openModal === 'open-paid-remove'}
                title="Remove Student"
                subtitle="The selected student will be removed permanently."
                subtitle2="To confirm, enter remove in the text field."
                value={inputValue}
                setInputValue={setInputValue}
                close={() => {
                    setSelectedStudents(null);
                    setOpenModal('');
                }}
                btnText="Yes, Remove"
                placeholder="Input remove"
                onConfirm={handleRemoveStudent}
                list={[selectedStudents]?.map((item) => {
                    return {
                        ...item,
                        name: `${item?.firstName || ''} ${item?.lastName || ''} ${item?.middleName || ''}`,
                    };
                })}
                // isLoading={studentsLoading}
                disabled={inputValue?.toLowerCase() !== 'remove'}
                isSelect={false}
                bgColor="#EF4444"
                isLoading={loading}
            />
        </>
    );
};

export default NotPaidStudents;
