import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel-red.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download-new.svg';
import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ReactComponent as LockIcon } from '../../../../assets/icons/lock.svg';
import { ReactComponent as SendIcon } from '../../../../assets/icons/send-black.svg';
import { ReactComponent as UnlockIcon } from '../../../../assets/icons/unlock.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../../components';
import { Button } from '../../../../components/buttons';
import { GoBack } from '../../../../components/go-back';
import NavTabs, { Tab } from '../../../../components/nav-tabs/NavTabs';
import ConfirmActionDialogueWithInput from '../../../../components/popups/confirm-action-with-input';
// import ConfirmIncompleteResult from '../../../../components/popups/confirm-incomplete-result';
import { PageTitle } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, RelativeContainer } from '../../../../containers/ScreenContainers';
import { getActiveSemester, getActiveSession, getAdmissionSessions } from '../../../../redux/admissions/actions';
import {
    lockEntriesInProgram,
    sendResultToDepartment,
    unlockEntriesInProgram,
} from '../../../../redux/exam-magt/actions';

import Broadsheet from './tabs/Broadsheet';
import IncompleteResult from './tabs/IncompleteResults';
import ProbationList from './tabs/Probation';
import SummarySheet from './tabs/SummarySheet';
import WithdrawalList from './tabs/Withdrawal';

const ACTION_ITEMS = {
    0: [
        {
            icon: <DownloadIcon />,
            name: 'Download',
            click: 'download',
        },
        {
            icon: <LockIcon />,
            name: 'Lock Entries',
            click: 'lock',
        },
        {
            icon: <UnlockIcon />,
            name: 'Unlock Entries',
            click: 'unlock',
        },
        {
            icon: <SendIcon />,
            name: 'Send Results to Faculty',
            click: 'send',
        },
        {
            icon: <CancelIcon />,
            name: 'Mark as Incomplete',
            click: 'incomplete',
        },
    ],
};

const BodyText = ({ session, semester }) => (
    <span className="border-b w-full flex gap-[50px]">
        <span>
            Session: <span className="font-bold">{session}</span>
        </span>
        <span>
            Semester: <span className="font-bold">{semester}</span>
        </span>
    </span>
);

const tabs = [
    { label: 'Broadsheet' },
    { label: 'Summary Sheet' },
    { label: 'Probation' },
    { label: 'Withdrawal' },
    { label: 'Incomplete Results' },
];

const ResultsInDepartment = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    // const { level } = useParams();

    const { semester = '', session = '' } = state || {};

    const { currentProgramme } = useSelector((state) => state.programmes);
    const { isLoading } = useSelector((state) => state.examMagt);
    const { _id: programId } = currentProgramme || {};

    const [activeTab, setActiveTab] = useState(0);
    const [openModal, setOpenModal] = useState(null);
    const [modalInputValue, setModalInputValue] = useState('');

    const handleTabClick = (tabName, tabIndex) => {
        setActiveTab(tabIndex);
    };

    const handleLock = async () => {
        if (modalInputValue !== 'LOCK') return toast.error('Enter LOCK in input field');
        const res = await dispatch(lockEntriesInProgram({ programId, session, semester: semester?.toLowerCase() }));
        if (res) {
            toast.success('Entries locked successfully!');
            setModalInputValue('');
            setOpenModal(null);
        }
    };

    const handleUnlock = async () => {
        if (modalInputValue !== 'UNLOCK') return toast.error('Enter UNLOCK in input field');
        const res = await dispatch(unlockEntriesInProgram({ programId, session, semester: semester?.toLowerCase() }));
        if (res) {
            toast.success('Entries unlocked successfully!');
            setModalInputValue('');
            setOpenModal(null);
        }
    };

    const handleSendResults = async () => {
        const res = await dispatch(sendResultToDepartment({ programId, session, semester: semester?.toLowerCase() }));
        if (res) {
            toast.success('These results have been sent successfully!');
            setOpenModal(null);
        }
    };

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getActiveSession());
        dispatch(getActiveSemester());
    }, [dispatch]);

    return (
        <div>
            <FlexRowSpaceBetween>
                <GoBack title="Programmes / Levels" subTitle="100 Level" />
                {activeTab === 0 && (
                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setOpenModal('actions')}>
                            Actions
                            <Dropdown />
                        </Button>
                        <ActionsPopup
                            open={openModal === 'actions'}
                            close={() => setOpenModal(null)}
                            items={ACTION_ITEMS[activeTab]?.map((item) => ({
                                ...item,
                                click: () => setOpenModal(item.click),
                            }))}
                        />
                    </RelativeContainer>
                )}
            </FlexRowSpaceBetween>

            <FlexRowSpaceBetween className="mt-[3.2rem] mb-[2.4rem]">
                <PageTitle align="start">{currentProgramme?.subprogram}</PageTitle>
            </FlexRowSpaceBetween>

            <NavTabs tabs={tabs} onClick={handleTabClick}>
                <Tab.Panel>
                    <Broadsheet />
                </Tab.Panel>
                <Tab.Panel>
                    <SummarySheet />
                </Tab.Panel>
                <Tab.Panel>
                    <ProbationList />
                </Tab.Panel>
                <Tab.Panel>
                    <WithdrawalList />
                </Tab.Panel>
                <Tab.Panel>
                    <IncompleteResult />
                </Tab.Panel>
            </NavTabs>

            <ConfirmActionDialogueWithInput
                show={openModal === 'lock'}
                title="Lock Results"
                subtitle="Are you sure you want to lock these results?"
                subtitle2="To confirm, enter 'LOCK' in the text field."
                close={() => {
                    setModalInputValue('');
                    setOpenModal(null);
                }}
                confirmAction={handleLock}
                borderLine={false}
                bodyText={<BodyText session={session} semester={semester} />}
                btn2Text="Lock Results"
                setInputValue={setModalInputValue}
                value={modalInputValue}
                withIcon={false}
                isLoading={isLoading}
                placeholder="Enter LOCK"
            />

            <ConfirmActionDialogueWithInput
                show={openModal === 'unlock'}
                title="Unlock Results"
                subtitle="Are you sure you want to unlock these results?"
                subtitle2="To confirm, enter 'UNLOCK' in the text field."
                close={() => {
                    setModalInputValue('');
                    setOpenModal(null);
                }}
                confirmAction={handleUnlock}
                borderLine={false}
                bodyText={<BodyText session={session} semester={semester} />}
                btn2Text="Unlock Results"
                setInputValue={setModalInputValue}
                value={modalInputValue}
                withIcon={false}
                isLoading={isLoading}
                placeholder="Enter UNLOCK"
            />

            <ConfirmActionDialogue
                show={openModal === 'send'}
                title="Send Results"
                bgColor="#6366F1"
                subtitle="Are you sure you want to send these results?"
                close={() => setOpenModal(null)}
                confirmAction={handleSendResults}
                bodyText={<BodyText session={session} semester={semester} />}
                btn2Text="Send Results"
                withIcon={false}
                isLoading={isLoading}
            />

            {/* <ConfirmIncompleteResult
                show={openModal === 'incomplete'}
                close={() => setOpenModal(null)}
                level={level}
                semester={semester}
                session={session}
                student={selectedStudent}
            /> */}
        </div>
    );
};

export default ResultsInDepartment;
