/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import Checkbox from '../../../../../components/inputs/checkbox';
import SearchInput from '../../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../../components/items-per-page';
import UploadFile from '../../../../../components/upload-file';
import { FlexCentredRow, FlexRowSpaceBetween, ItemsPerPageCont } from '../../../../../containers/ScreenContainers';
import { formatDate, sortData } from '../../../../../utils';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Subject',
        key: 'subject',
    },
    {
        label: 'Message',
        key: 'message',
    },
    {
        label: 'Time Stamp',
        key: 'time',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const LetterTo = ({ toLetter }) => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(toLetter?.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [sortedData, setSortedData] = useState([]);
    const { isLoading } = useSelector((store) => store.staffArena);

    useEffect(() => {
        const sortedData = sortData(toLetter);
        setSortedData(sortedData);
    }, [toLetter]);

    const Letter = sortedData?.map((letter) => {
        const formattedDate = formatDate(letter.createdAt);
        return {
            id: letter._id,
            subject: letter.title,
            message: letter.content.length > 50 ? letter.content.slice(0, 50) + '...' : letter.content,
            time: formattedDate,
        };
    });

    return (
        <>
            <div className="mt-[3.2rem] rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>
                {/* <Table
                    items={Letter || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    onRowClick={(item) => navigate(`letterto-preview/${item.id}`)}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),

                        subject: (item) => <td style={{ fontWeight: '600' }}>{item.subject}</td>,
                    }}
                /> */}
            </div>
            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </>
    );
};

export default LetterTo;
