import React from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as ExportIcon } from '../../../assets/icons/export-blue.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';
import CentreModal from '../../modal/CentreModal';

const ChooseColumnsToExport = ({ show, close, exportAction }) => {
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[16px] pt-[5rem] pr-[1.6rem] pb-[1.4rem] pl-[3.4rem] w-[50rem] ">
                <CloseIcon onClick={close} className="absolute top-[2.1rem] right-[3.73rem]" />
                <FlexCentredRow>
                    <ExportIcon className="mr-[8.15px]" />
                    <Text as="h3" size="1.9rem" weight="600">
                        Export Columns
                    </Text>
                </FlexCentredRow>

                <FlexRowEnd className="gap-x-[16px]">
                    <Button onClick={close} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <Button onClick={exportAction} bgColor="#6366F1" color="#fff">
                        Export
                    </Button>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default ChooseColumnsToExport;
