import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { Card } from '../../../../components';
import { GoBack } from '../../../../components/go-back';
import NavTabs, { Tab } from '../../../../components/nav-tabs/NavTabs';
import { Text } from '../../../../containers/MesssageContainers';
import { getDebtor } from '../../../../redux/bursary/actions';
import { capitalizeFirstLetter, getDegreeInfo, getDegreeStructs } from '../../../../utils';
import currencyFormatter from '../../../../utils/formatCurrency';
import { getSessionsOwed } from '../index';

import DebtHistory from './tabs/DebtHistory';

const tabs = [{ label: 'Debt History' }];

const DebtorDetails = () => {
    const dispatch = useDispatch();
    const { id: studentId } = useParams();
    const { state } = useLocation();

    const { debtor } = state || {};

    const { firstDegreeStruct, secondDegreeStruct, thirdDegreeStruct } = getDegreeStructs(debtor?.structureType);
    const { firstDegree, secondDegree, thirdDegree } = getDegreeInfo(debtor, 'structureType');

    const [details, setDetails] = useState(debtor || null);
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const groupedFees = useMemo(
        () =>
            details.debts.reduce((acc, fee) => {
                const { feeType, amountOwed } = fee;
                if (!acc[feeType]) {
                    acc[feeType] = 0;
                }
                acc[feeType] += amountOwed;
                return acc;
            }, {}),
        [details],
    );

    const uniqueFeeTypes = Object.keys(groupedFees).map((feeType) => ({
        feeType,
        totalAmountOwed: groupedFees[feeType],
    }));

    const totalAmountOwed = Object.values(groupedFees).reduce((acc, amount) => acc + amount, 0);

    const overviewItems = [
        { title: 'Name', value: debtor?.studentFullName },
        { title: 'Matric Number', value: debtor?.studentMatricNumber || '' },
        { title: 'Number of Sessions Owed', value: getSessionsOwed(debtor?.debts) },
        { title: 'Total Amount Owed', value: currencyFormatter.format(totalAmountOwed || 0) },
        { title: 'Date of Last Payment', value: '' },
        { title: 'Number of Fee Types', value: uniqueFeeTypes.length },
        { title: capitalizeFirstLetter(firstDegreeStruct), value: firstDegree },
        { title: capitalizeFirstLetter(secondDegreeStruct), value: secondDegree },
        { title: capitalizeFirstLetter(thirdDegreeStruct), value: thirdDegree },
    ];

    useEffect(() => {
        const getDetails = async () => {
            const res = await dispatch(getDebtor(studentId));
            if (res) {
                setDetails(res);
            }
        };

        getDetails();
    }, [dispatch, studentId, toggleRefetch]);

    return (
        <div>
            <GoBack title="Debt Management" subTitle={debtor?.studentFullName || ''} />

            <Card className="mt-8 mb-[32px] p-[24px]">
                <Text align="left" size="1.9rem" weight="600" bottom="28px">
                    Overview
                </Text>
                <div className="grid grid-cols-4 gap-y-[34px]">
                    {overviewItems.map((item) => (
                        <div key={item.title}>
                            <Text align="left" lineHeight="17px" color="#9CA3AF" size="1.2rem" weight="500">
                                {item.title || ''}
                            </Text>
                            <Text align="left" weight="500" color="#4B5563">
                                {item.value || ''}
                            </Text>
                        </div>
                    ))}
                </div>
            </Card>

            <NavTabs tabs={tabs}>
                <Tab.Panel>
                    <DebtHistory details={details} setToggleRefetch={setToggleRefetch} />
                </Tab.Panel>
            </NavTabs>
        </div>
    );
};

export default DebtorDetails;
