import React from 'react';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { LoadingButton } from '../../components/buttons';
import CentreModal from '../../components/modal/CentreModal';
import { Text } from '../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../containers/ScreenContainers';

const SummarySheetStats = ({ show, close, data }) => {
    const items = [
        { title: 'Total Number of Registered Students', value: data?.numberOfStudents },
        { title: 'Number of Students in Good Standing', value: data?.numberOfGoodAcademicStanding },
        { title: 'Number of Students not in Good Standing', value: data?.numberOfNotGoodAcademicStanding },
        { title: 'Number of Students on Probation List', value: data?.numberOfProbationList },
        { title: 'Number of Students on Withdrawal List ', value: data?.numberOfWithdrawList },
        { title: 'Number of Students with Incomplete Results ', value: data?.numberOfIncompleteList },
    ];
    return (
        <CentreModal isOpen={show} onClose={() => close?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[59.2rem]">
                <FlexRowSpaceBetween>
                    <Text as="h3" size="1.9rem" weight="600">
                        Summary Sheet Statistics
                    </Text>

                    <CloseIcon onClick={close} className="cursor-pointer" />
                </FlexRowSpaceBetween>

                <Text as="h4" size="1.6rem" align="left" weight="400" bottom="32px" top="8px" color="#374151">
                    This is the statistics for summary sheet
                </Text>
                <div className="grid gap-6 border-b pb-4 mb-[4rem]">
                    {items.map((item) => (
                        <div className="flex justify-between items-center">
                            <Text weight="400" color="#374151">
                                {item.title}
                            </Text>
                            <Text weight="500" color="#374151">
                                {item.value}
                            </Text>
                        </div>
                    ))}
                </div>

                <FlexRowEnd>
                    <LoadingButton onClick={close}>Close</LoadingButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default SummarySheetStats;
