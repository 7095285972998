import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../../../components/loader';
import NavTabs, { Tab } from '../../../../components/nav-tabs/NavTabs';
import { PageTitle } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import {
    getAllBulletinComms,
    getAllInternalMemoComms,
    getAllNoticeComms,
    getAllReceivedComms,
} from '../../../../redux/staff-arena/actions';
import { All, Bulletin, InternalMemo, Notice } from '../../../communication/components';

// import { StaffBulletin, StaffInternalMemo, StaffNotice } from './tabs';

const tabs = [{ label: 'All' }, { label: 'Bulletin' }, { label: 'Internal Memo' }, { label: 'Notice' }];

const StaffReceived = () => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((store) => store.staffArena);

    useEffect(() => {
        dispatch(getAllReceivedComms());
        dispatch(getAllBulletinComms());
        dispatch(getAllNoticeComms());
        dispatch(getAllInternalMemoComms());
    }, [dispatch]);

    if (isLoading) return <Loader />;

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Received</PageTitle>
            </FlexRowSpaceBetween>

            <NavTabs tabs={tabs}>
                <Tab.Panel>
                    <All />
                </Tab.Panel>
                <Tab.Panel>
                    <Bulletin />
                </Tab.Panel>
                <Tab.Panel>
                    <InternalMemo />
                </Tab.Panel>
                <Tab.Panel>
                    <Notice />
                </Tab.Panel>
            </NavTabs>
        </div>
    );
};

export default StaffReceived;
