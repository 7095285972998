import React, { useMemo } from 'react';

import { AllCommunityModule, ModuleRegistry, themeQuartz } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { useTableContext } from '../../context/tableContext';
import { Loader } from '../loader';

ModuleRegistry.registerModules([AllCommunityModule]);

const CustomTable = ({
    colDefs,
    data,
    rowSelection,
    singleRowSelection,
    setSelectedItem,
    onRowClicked,
    isLoading,
    tableHeight,
    pagination,
    noRowsOverlayComponent,
    multiRowSelection,
}) => {
    const { tableSettings } = useTableContext();
    const defaultColDef = useMemo(() => {
        return {
            filter: true,
        };
    }, []);

    const myTheme = themeQuartz.withParams({
        oddRowBackgroundColor: tableSettings?.stripedRow ? '#8881' : '',
    });
    return (
        <div className="" style={{ height: tableHeight || '520px' }}>
            <AgGridReact
                columnDefs={colDefs?.map((item, index) => {
                    return {
                        ...item,
                        unSortIcon: true,
                        pinned: index === tableSettings?.stickyColumns && 'left',
                        lockPinned: index === tableSettings?.stickyColumns,
                    };
                })}
                rowData={data || []}
                pagination={pagination ? pagination : true}
                paginationPageSize={tableSettings?.numOfPages || 10}
                defaultColDef={defaultColDef}
                rowSelection={
                    rowSelection && {
                        mode:
                            rowSelection && singleRowSelection
                                ? 'singleRow'
                                : rowSelection && multiRowSelection
                                ? 'multiRow'
                                : 'multiRow',
                    }
                }
                onRowSelected={(row) => {
                    setSelectedItem(row?.data);
                }}
                theme={myTheme}
                onRowClicked={onRowClicked}
                loading={isLoading}
                loadingOverlayComponent={() => <Loader size="small" />}
                noRowsOverlayComponent={noRowsOverlayComponent}
            />
        </div>
    );
};

export default CustomTable;
