import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';
import handleApiError from '../handleApiError';

import { GET_DESIGNATIONS, GET_UNIT_DESIGNATIONS, LOADING, STOP_LOADING } from './slice';

export const getDesignations = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}designation/:campusId/:unitId/get_all_designation?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_DESIGNATIONS(response.data.data));
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getDesignationsInUnit = (unitId) => async (dispatch, getState) => {
    dispatch(LOADING());
    dispatch(GET_UNIT_DESIGNATIONS([]));
    const altUnitId = sessionStorage.getItem('unitId');
    try {
        const response = await axiosInstance.get(
            `${currentAPI}designation/:campusId/:unitId/get_all_designation_by_unit/${unitId || altUnitId}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            dispatch(GET_UNIT_DESIGNATIONS(data));
        }
    } catch (error) {
        dispatch(GET_UNIT_DESIGNATIONS([]));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createDesignation = (payload) => async (dispatch, getState) => {
    // dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}designation/:campusId/:unitId/create_designation`,
            payload,
        );
        if (response.status === 200) {
            dispatch(getDesignations());
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        // dispatch(STOP_LOADING());
    }
};

export const editDesignation = (unitId, payload, id) => async (dispatch, getState) => {
    // dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}designation/:campusId/:unitId/edit_designation/${unitId}/${id}`,
            payload,
        );
        if (response.status === 200) {
            dispatch(getDesignations());
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        // dispatch(STOP_LOADING());
    }
};

export const getDesignationDetails = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}designation/:campusId/:unitId/get_designation_by_id/${id}`,
        );
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteDesignation = (id) => async (dispatch, getState) => {
    // dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(
            `${currentAPI}designation/:campusId/:unitId/delete_designation/${id}`,
        );
        if (response.status === 200) {
            dispatch(getDesignations());
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        // dispatch(STOP_LOADING());
    }
};

export const emptyDesignation = (id) => async (dispatch, getState) => {
    // dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}designation/:campusId/:unitId/empty_designation/${id}`);
        if (response.status === 200) {
            dispatch(getDesignations());
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        // dispatch(STOP_LOADING());
    }
};
