import React from 'react';

import Avatar from 'react-avatar';

import { ReactComponent as Dropdown } from '../../assets/icons/dropdown.svg';
import { ReactComponent as LoaderIcon } from '../../assets/icons/loader.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ActionsPopup } from '../../components';
import { Button, SecondaryButton } from '../../components/buttons';
import { GoBack } from '../../components/go-back';
import TableWithUtils from '../../components/table-with-utils';
import { PageTitle } from '../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween, RelativeContainer, FlexColumn } from '../../containers/ScreenContainers';

const PageLayout = ({
    backTitle = '',
    backSubtitle = '',
    backLink = '',
    pageTitle = '',
    actionItems,
    onSecondaryButtonClick,
    secondaryButtonText,
    loading,
    loader,
    useNoItemView,
    noItemView,
    fields,
    data,
    onRowClick,
    onActionBtnClick,
    actionOpen,
    actionClose,
    showTableUtils = true,
    onSearchChange,
    searchValue,
    onSearchClose,
    showClear,
    filterItems,
    handleFilterReset,
    filterPopItems,
    searchable = true,
    showFilter,
    noMargin,
    children,
    openFilter,
    closeFilter,
    openFilterPop,
    closeFilterPop,
    tableUtilsChildren,
    titleAddendum,
    isFilterPopLoading,
    filterCategories,
    actionProp,
    isActionable,
    isCollapsible,
    actionType,
    openCollapseId,
    handleAction,
    eventData,
    onSessionFilterClick,
    openSessionFilter,
    closeSessionFilter,
    sessionItems,
    sessionPlaceHolder,
    onCopyClick,
    secondaryButtonIcon,
    disableSecondaryBtn,
    actionBgColor,
    actionTextColor,
    actionIcon,
    actionText,
    sessionValue,
    isPaginated,
    paginationComponent,
    collapseTitle,
    logoSrc,
    imageName,
    logo,
    tableTitle,
    dataIdField,
    onSelectedItemsChange,
    onPageChange,
    showActionTypeOnHeader,
    actionPosition,
    applySettings = true,
    tabComponent,
    showButton,
    onBtnClick,
    btnText,
    customTable,
}) => {
    return (
        <div>
            {backTitle && (
                <FlexColumn className="mb-0 pt-0">
                    <GoBack title={backTitle} backLink={backLink} subTitle={backSubtitle} />
                </FlexColumn>
            )}
            <FlexRowSpaceBetween className="mt-0">
                <FlexCentredRow className="flex gap-[1rem]">
                    {logo &&
                        (<img alt="profile" src={logoSrc} className="rounded-full w-[4rem] h-[4rem]" /> || (
                            <Avatar name={imageName} size="60" textSizeRatio={1.75} className="rounded-full" />
                        ))}
                    <PageTitle align="left">{pageTitle}</PageTitle>
                    {titleAddendum}
                </FlexCentredRow>

                <FlexCentredRow className="gap-x-4">
                    {secondaryButtonText && (
                        <SecondaryButton onClick={onSecondaryButtonClick} disabled={disableSecondaryBtn}>
                            {secondaryButtonIcon ? secondaryButtonIcon : <Plus />}
                            {secondaryButtonText}
                        </SecondaryButton>
                    )}
                    {actionItems && (
                        <RelativeContainer>
                            <Button
                                bgColor={actionBgColor || '#6366F1'}
                                color={actionTextColor || '#fff'}
                                onClick={onActionBtnClick}
                            >
                                {actionText || 'Actions'}
                                {actionIcon || <Dropdown />}
                            </Button>
                            <ActionsPopup open={actionOpen} close={actionClose} items={actionItems} />
                        </RelativeContainer>
                    )}
                </FlexCentredRow>
            </FlexRowSpaceBetween>
            {tabComponent}
            {(fields || customTable) && (
                <div className="mt-[2rem]">
                    <TableWithUtils
                        children={children}
                        fields={fields}
                        data={data}
                        noMargin={noMargin}
                        showTableUtils={showTableUtils}
                        searchValue={searchValue}
                        onSearchChange={onSearchChange}
                        onSearchClose={onSearchClose}
                        searchable={searchable}
                        showFilter={showFilter}
                        showClear={showClear}
                        filterItems={filterItems}
                        openFilterPop={openFilterPop}
                        closeFilterPop={closeFilterPop}
                        filterPopItems={filterPopItems}
                        handleFilterReset={handleFilterReset}
                        openFilter={openFilter}
                        closeFilter={closeFilter}
                        tableUtilsChildren={tableUtilsChildren}
                        isFilterPopLoading={isFilterPopLoading}
                        onSessionFilterClick={onSessionFilterClick}
                        openSessionFilter={openSessionFilter}
                        closeSessionFilter={closeSessionFilter}
                        sessionItems={sessionItems}
                        sessionPlaceHolder={sessionPlaceHolder}
                        onRowClick={onRowClick}
                        loading={loading}
                        loader={<LoaderIcon className="w-[3rem] h-[3rem] animate-spin" />}
                        useNoItemView={useNoItemView}
                        noItemView={noItemView}
                        isActionable={isActionable}
                        isCollapsible={isCollapsible}
                        actionProp={actionProp}
                        actionType={actionType}
                        openCollapseId={openCollapseId}
                        handleAction={handleAction}
                        eventData={eventData}
                        onCopyClick={onCopyClick}
                        filterCategories={filterCategories}
                        sessionValue={sessionValue}
                        isPaginated={isPaginated}
                        paginationComponent={paginationComponent}
                        collapseTitle={collapseTitle}
                        tableTitle={tableTitle}
                        dataIdField={dataIdField}
                        onSelectedItemsChange={onSelectedItemsChange}
                        onPageChange={onPageChange}
                        showActionTypeOnHeader={showActionTypeOnHeader}
                        actionPosition={actionPosition}
                        applySettings={applySettings}
                        showButton={showButton}
                        onBtnClick={onBtnClick}
                        btnText={btnText}
                        customTable={customTable}
                    />
                </div>
            )}
        </div>
    );
};

export default PageLayout;
