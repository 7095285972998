import React, { useEffect, useState } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import CustomTable from '../../../components/CustomTable';
import ConfirmActionDialogueWithInput from '../../../components/popups/confirm-action-with-input';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { useTableContext } from '../../../context/tableContext';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { deletePaymentGroup, getPaymentGroups } from '../../../redux/bursary/actions';
import { formatToDateTime } from '../../../utils/formatDate';

const PaymentGroup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, paymentGroups } = useSelector((state) => state.bursary);

    const [searchValue, setSearchValue] = useState('');

    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [openModal, setOpenModal] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const { data: allpaymentGroups, pagination } = paymentGroups || {};
    const { total, totalPages } = pagination || {};

    const [currentPage, setCurrentPage] = useState('1');
    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;

    const fields = [
        {
            headerName: 'Group Name',
            field: 'groupName',
            cellRenderer: (props) => {
                const { _id: id, groupType, groupName, staffType, description, createdAt, createdBy } = props.data;
                return (
                    <Link
                        to={
                            groupType === 'Group Only'
                                ? `/bursary/payroll/payment-group/${id}/group-only`
                                : `/bursary/payroll/payment-group/${id}/group-subgroups`
                        }
                        state={{
                            groupDetails: props.data,
                            groupName,
                            groupType,
                            staffType,
                            description,
                            createdAt,
                            createdBy,
                            id,
                        }}
                        style={{ color: '#2563EB', cursor: 'pointer' }}
                    >
                        {props?.value}
                    </Link>
                );
            },
        },

        {
            headerName: 'Staff Type',
            field: 'staffType',
        },
        {
            headerName: 'Group Type',
            field: 'groupType',
        },
        {
            headerName: 'Created By',
            field: 'createdBy',
        },
        {
            headerName: 'Created At',
            field: 'createdAt',
            cellRenderer: ({ data }) => {
                return <p>{formatToDateTime(data?.createdAt)}</p>;
            },
        },
    ];

    const handleDelete = async () => {
        if (inputValue.toLowerCase() !== 'delete') {
            toast.error('Input delete');
        } else {
            const res = await dispatch(deletePaymentGroup(selectedItem?._id));
            if (res) {
                setToggleRefetch((prevState) => !prevState);
                setOpenModal(false);
            }
        }
    };
    useEffect(() => {
        const query = {
            search: debouncedSearchValue,
            page: currentPage,
            limit: numOfPages,
        };
        dispatch(getPaymentGroups(query));
    }, [dispatch, toggleRefetch, debouncedSearchValue, currentPage, numOfPages]);

    return (
        <>
            <PageLayout
                pageTitle="Payment Group"
                secondaryButtonText="Add Group"
                customTable={
                    <CustomTable
                        colDefs={fields}
                        data={allpaymentGroups || []}
                        rowSelection
                        singleRowSelection
                        setSelectedItem={setSelectedItem}
                    />
                }
                // data={allpaymentGroups || []}
                // fields={fields}
                // loading={isLoading}
                // isActionable
                // actionType="radio"
                // onSelectedItemsChange={(items) => {
                //     setSelectedItem(items[0]);
                // }}
                onSecondaryButtonClick={() => navigate('/bursary/payroll/payment-group/add-group')}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <EditIcon />,
                        name: 'Edit Group',
                        disabled: !selectedItem,
                        click: () => navigate(`/bursary/payroll/payment-group/edit-group/${selectedItem?._id}`),
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Group',
                        disabled: !selectedItem,
                        click: () => setOpenModal('delete'),
                    },
                ]}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                searchValue={searchValue}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                showFilter
                width={'100%'}
                paginationComponent={
                    <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                        <PaginationStatus
                            currentPage={currentPage}
                            itemsPerPage={tableSettings?.numOfPages}
                            totalItems={total}
                        />
                        <Pagination
                            pageRangeDisplayed={3}
                            totalPages={totalPages}
                            onPageChange={(nextPage) => {
                                setCurrentPage(nextPage);
                            }}
                            currentPage={currentPage}
                        />
                    </FlexRowSpaceBetween>
                }
                // noItemView={
                //     <CenteredContainer className="mt-[5rem]">
                //         <ListEmptyContent isInTable title="No Debtors Found" subtitle="Add a Debtor">
                //             <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                //                 <Button
                //                     onClick={() => navigate('/bursary/payroll/payment-group/add-group')}
                //                     bgColor="#10B981"
                //                     color="#fff"
                //                 >
                //                     Add Group
                //                 </Button>
                //             </CenteredContainer>
                //         </ListEmptyContent>
                //     </CenteredContainer>
                // }
                openFilterPop={openModal === 'options-popup-main'}
                closeFilterPop={() => setOpenModal(null)}
            />
            <ConfirmActionDialogueWithInput
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete payment group?"
                subtitle={`"${selectedItem?.groupName}" will be removed permanently..`}
                subtitle2="To confirm, enter delete in the text field.."
                confirmAction={() => handleDelete()}
                btn2Text="Yes, Delete"
                setInputValue={setInputValue}
                value={inputValue}
                isLoading={isLoading}
                btnDisabled={inputValue !== 'delete'}
                placeholder="Input delete"
            />
        </>
    );
};

export default PaymentGroup;
