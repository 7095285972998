import * as Yup from 'yup';

const courseCodeRegex = /^\d{3}(\/\d{3})?[A-Z]?$/;

export const ValidationSchema = Yup.object().shape({
    courseTitle: Yup.string().nullable().required('Required'),
    courseCode: Yup.string()
        .nullable()
        .required('Required')
        .matches(courseCodeRegex, 'Invalid format. Format should match any of: 101, 101/102, 104A, 104/105B'),
    courseUnit: Yup.string().nullable().required('Required'),
    courseLevel: Yup.string().nullable().required('Required'),
    courseSemester: Yup.string().nullable().required('Required'),
    minimumPassScore: Yup.string().nullable().required('Required'),
    departmentCode: Yup.string().nullable().required('Required'),
});
