import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { getCourseAllocationHistory } from '../../../redux/courses/actions';
import { SubmitButton } from '../../forms/sharedStyles';
import CentreModal from '../../modal/CentreModal';

const CourseAllocationHistory = ({ show, setShow, selectedCourse }) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.courses);

    const [historyData, setHistoryData] = useState([]);

    const onClose = () => setShow('');

    useEffect(() => {
        if (!selectedCourse) return;

        const getData = async () => {
            const data = await dispatch(getCourseAllocationHistory(selectedCourse?._id));
            setHistoryData(data || []);
        };

        getData();
    }, [dispatch, selectedCourse]);

    return (
        <CentreModal isOpen={show} onClose={() => onClose?.()}>
            <div className="bg-white rounded-[8px] p-[2.4rem] w-[58.6rem] max-h-[60rem] mx-auto">
                <FlexRowSpaceBetween>
                    <Text as="h3" size="1.9rem" weight="600">
                        {selectedCourse?.courseTitle || ''},{' '}
                        <span className="font-semibold text-[#6B7280] text-[1.9rem]">
                            {selectedCourse?.courseCode || ''}
                        </span>
                    </Text>
                    <CloseIcon className="hover:cursor-pointer" onClick={onClose} />
                </FlexRowSpaceBetween>
                <Text align="start" size="1.6rem" color="#374151" top=".8rem" lineHeight="2.4rem" bottom="1rem">
                    This is the allocation history of the course
                </Text>

                {isLoading ? (
                    <Text weight="600" lineHeight="2.4rem">
                        Loading...
                    </Text>
                ) : (
                    <ul className=" overflow-y-auto border-b pb-2 mt-5 gap-6">
                        <div className="flex justify-between items-center">
                            <Text weight="600" lineHeight="2.4rem">
                                Lecturer(s)
                            </Text>
                            <Text weight="600" lineHeight="2.4rem">
                                Session
                            </Text>
                        </div>

                        {!historyData?.length > 0 ? (
                            <Text weight="600" lineHeight="2.4rem">
                                No allocation history yet
                            </Text>
                        ) : (
                            historyData?.map((item, index) => (
                                <div key={index} className="flex justify-between">
                                    <Text align="left">{item?.staffNames?.join(', ') || ''}</Text>
                                    <Text align="right">{item?.session || ''}</Text>
                                </div>
                            ))
                        )}
                    </ul>
                )}

                <FlexRowEnd className="mt-8">
                    <SubmitButton onClick={onClose}>Close</SubmitButton>
                </FlexRowEnd>
            </div>
        </CentreModal>
    );
};

export default CourseAllocationHistory;
