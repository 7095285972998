import React from 'react';

import { useNavigate } from 'react-router-dom';

import CustomTable from '../../../../../components/CustomTable';
import PageLayout from '../../../../../layout/page-layout/PageLayout';

const Manual = ({ manualStaffs, setSelectedItem, setSearchValue, isLoading, searchValue, selectedItem }) => {
    const navigate = useNavigate();

    const fields = [
        {
            field: 'staffName',
            cellRenderer: (props) => (
                <p
                    style={{ color: '#2563EB' }}
                    className="cursor-pointer"
                    onClick={() => navigate(`${props?.data?._id}?staff=manual`)}
                >
                    {props.value}
                </p>
            ),
        },
        {
            headerName: 'Email',
            field: 'staffEmail',
        },
        {
            field: 'accountNumber',
        },
        {
            field: 'bankName',
        },
        {
            field: 'accountType',
        },
        {
            field: 'paymentGroup',
        },
        {
            field: 'monthlyGross',
            cellRenderer: (props) => <p>{props.data?.salary?.amount}</p>,
        },
        {
            field: 'monthlyNet',
            cellRenderer: (props) => <p>{props.data?.salary?.amount}</p>,
        },
        {
            field: 'currency',
            cellRenderer: (props) => <p>{props.data?.salary?.currency}</p>,
        },
        {
            headerName: 'Date Created',
            field: 'createdAt',
            cellRenderer: (props) => (
                <p>
                    {props.value
                        ? `${new Date(props.value).toLocaleDateString()}, ${new Date(props.value).toLocaleTimeString()}`
                        : ''}
                </p>
            ),
        },
    ];
    return (
        <div className="mt-3">
            <PageLayout
                noMargin
                customTable={
                    <CustomTable
                        colDefs={fields}
                        data={manualStaffs || []}
                        rowSelection
                        singleRowSelection
                        setSelectedItem={setSelectedItem}
                    />
                }
                // fields={fields}
                // data={manualStaffs || []}
                // onSelectedItemsChange={(items) => setSelectedItems(items[0])}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                // isActionable
                // actionType="radio"
                // loading={isLoading}
            />
        </div>
    );
};

export default Manual;
