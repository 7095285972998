import React, { useState, useEffect } from 'react';

import { Pagination, PaginationStatus, Table } from '@belrald_hq/belrald-ui';

import { ReactComponent as LoaderIcon } from '../../assets/icons/loader.svg';
import TableUtils from '../../components/table-utils';
import { Text } from '../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../containers/ScreenContainers';
import { useTableContext } from '../../context/tableContext';

const TableWithUtils = ({
    children,
    fields,
    data,
    noMargin,
    showTableUtils,
    searchValue,
    onSearchChange,
    onSearchClose,
    searchable,
    showFilter,
    showClear,
    filterItems,
    openFilterPop,
    closeFilterPop,
    filterPopItems,
    handleFilterReset,
    openFilter,
    closeFilter,
    tableUtilsChildren,
    isFilterPopLoading,
    onSessionFilterClick,
    openSessionFilter,
    closeSessionFilter,
    sessionItems,
    sessionPlaceHolder,
    onRowClick,
    loading,
    useNoItemView,
    noItemView,
    isActionable,
    isCollapsible,
    actionProp,
    actionType,
    openCollapseId,
    handleAction,
    eventData,
    onCopyClick,
    filterCategories,
    sessionValue,
    isPaginated,
    paginationComponent,
    collapseTitle,
    onPageChange,
    tableTitle,
    onSelectedItemsChange,
    dataIdField,
    showActionTypeOnHeader,
    actionPosition,
    applySettings,
    showButton,
    onBtnClick,
    btnText,
    customTable,
}) => {
    const [currentPage, setCurrentPage] = useState('1');
    const { saveTableSettings, tableSettings } = useTableContext();

    const numOfPages = tableSettings?.numOfPages;
    const totalPages = Math.ceil(data?.length / numOfPages);

    const onApplySettings = (value) => {
        const { numOfPages, stripedRow, isWrapText, stickyColumns } = value || {};
        const settingsToSave = { numOfPages, stripedRow, isWrapText, stickyColumns };
        saveTableSettings(settingsToSave);
        setCurrentPage('1');
    };

    useEffect(() => {
        // TODO: fix
        // commented out because table always navigates to page 1 on changes on other pages
        // setCurrentPage('1');
        //eslint-disable-next-line
    }, [data, searchValue, onSearchChange, onSearchClose]);
    return (
        <>
            <div className={`${noMargin ? '' : 'mt-[3.2rem]'} rounded-t-lg bg-[white] pb-[1.8rem]`}>
                {tableTitle && (
                    <Text align="left" weight="600" size="1.4rem" color="#000" left="1.6rem">
                        {tableTitle}
                    </Text>
                )}
                {showTableUtils && (
                    <TableUtils
                        onApplySettings={onApplySettings}
                        searchValue={searchValue}
                        onSearchChange={onSearchChange}
                        onSearchClose={onSearchClose}
                        searchable={searchable}
                        showFilter={showFilter}
                        showClear={showClear}
                        filterItems={filterItems}
                        openFilterPop={openFilterPop}
                        closeFilterPop={closeFilterPop}
                        filterPopItems={filterPopItems}
                        handleReset={handleFilterReset}
                        openFilter={openFilter}
                        closeFilter={closeFilter}
                        tableUtilsChildren={tableUtilsChildren}
                        isFilterPopLoading={isFilterPopLoading}
                        onSessionFilterClick={onSessionFilterClick}
                        openSessionFilter={openSessionFilter}
                        closeSessionFilter={closeSessionFilter}
                        sessionItems={sessionItems}
                        sessionPlaceHolder={sessionPlaceHolder}
                        sessionValue={sessionValue}
                        applySettings={applySettings}
                        showButton={showButton}
                        onBtnClick={onBtnClick}
                        btnText={btnText}
                    />
                )}
                {filterCategories}
                {fields?.length > 0 ? (
                    <>
                        <Table
                            columns={fields}
                            data={data || []}
                            width={'100%'}
                            onRowClick={onRowClick}
                            loading={loading}
                            loader={<LoaderIcon className="w-[3rem] h-[3rem] animate-spin" />}
                            useNoItemView={useNoItemView}
                            noItemView={noItemView}
                            striped={tableSettings?.stripedRow}
                            stickyTable={
                                tableSettings?.stickyColumns === 0 || tableSettings?.stickyColumns === 1 ? true : false
                            }
                            tableNumber={tableSettings?.stickyColumns}
                            currentPage={currentPage - 1} // table is zero indexed. first page starts from zero
                            pageSize={numOfPages}
                            isActionable={isActionable}
                            isCollapsible={isCollapsible}
                            actionProp={actionProp}
                            actionType={actionType}
                            openCollapseId={openCollapseId}
                            handleAction={handleAction}
                            eventData={eventData}
                            onCopyClick={onCopyClick}
                            collapseTitle={collapseTitle}
                            onSelectedItemsChange={onSelectedItemsChange}
                            dataIdField={dataIdField}
                            isWrapText={tableSettings?.isWrapText}
                            showActionTypeOnHeader={showActionTypeOnHeader}
                            actionPosition={actionPosition}
                        />

                        {data?.length > 0 && !isPaginated ? (
                            <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                                <PaginationStatus
                                    currentPage={currentPage}
                                    itemsPerPage={numOfPages}
                                    totalItems={data?.length}
                                />
                                <Pagination
                                    pageRangeDisplayed={3}
                                    totalPages={totalPages}
                                    onPageChange={(nextPage) => {
                                        onPageChange?.(nextPage);
                                        setCurrentPage(nextPage);
                                    }}
                                    currentPage={currentPage}
                                />
                            </FlexRowSpaceBetween>
                        ) : data?.length > 0 && isPaginated ? (
                            paginationComponent
                        ) : null}
                    </>
                ) : (
                    customTable
                )}
                {children}
            </div>
        </>
    );
};

export default TableWithUtils;
