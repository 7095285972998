import { Suspense, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Navigate, Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import { ConfirmActionDialogue } from './components';
import { Loader } from './components/loader';
import { setUnauthorizedHandler } from './config/axios';
import Layout from './layout';
// import { logoutUser } from './redux/user/actions';
import { routes } from './routes';
import { parseJSON } from './utils/useJSON';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
    const navigate = useNavigate();
    const location = useLocation();

    const { pathname } = location || {};
    const token = parseJSON('token', '');

    const { adminData, staffData } = useSelector((state) => state.user);

    const isAuthenticated = token ? true : false;

    const userType = staffData?.userType || adminData?.userType;
    const savedCampusId = sessionStorage.getItem('campusId');
    const newCampusId = staffData?.campusId?.[0];
    const [showTimeoutModal, setshowTimeoutModal] = useState(false);

    const handleSessionTimeout = () => {
        sessionStorage.clear();
        window.location.replace('/sign-in-staff');
    };

    useEffect(() => {
        // TODO is this useffect still needed with token in session storage?
        //
        if (savedCampusId || !token) return;

        if (userType === 'staff' && newCampusId) {
            sessionStorage.setItem('campusId', newCampusId);
        } else if (!pathname?.includes('campus-management')) {
            navigate('/campus-management/campuses');
        }
    }, [navigate, newCampusId, savedCampusId, token, userType, pathname]);

    useEffect(() => {
        setUnauthorizedHandler(() => setshowTimeoutModal(true));
    }, []);

    return (
        <>
            <Suspense fallback={<Loader />}>
                <Routes>
                    {routes.map((route) => (
                        <Route
                            key={route.path}
                            path={isAuthenticated ? '/' : route.path}
                            element={isAuthenticated ? <Navigate to="/dashboard" replace /> : route.element}
                        />
                    ))}

                    <Route path="*" element={<Layout />} />
                </Routes>
            </Suspense>

            <ConfirmActionDialogue
                show={showTimeoutModal}
                withIcon={false}
                withClose={false}
                closeOnBackdrop={false}
                withCancelBtn={false}
                bgColor="#6366F1"
                title="You have been signed out!"
                subtitle="You have been signed out of the platform. Sessions in all tabs have been signed out."
                confirmAction={() => handleSessionTimeout()}
                btn2Text="Sign in again"
            />
        </>
    );
}

export default App;
